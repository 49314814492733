import React, { createContext, useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import { setLoader } from '../../modules/languageChange/actions'
import {
  frontDefaultLanguage,
  language,
  languages,
  loadLanguage,
  rtl,
  setAvailableLanguages,
  strings,
} from './strings'

export const LanguageContext = createContext()

const LanguageContextProvider = ( { children } ) => {
  const languageState = useSelector( state => state.configReducer.languageState )
  const [lastUpdate, setLastUpdate] = useState( new Date().toISOString() )
  const dispatch = useDispatch()

  const location = useLocation()

  const updateLanguage = useCallback( async ( langs ) => {
    dispatch( setLoader( true ) )
    let error
    try {
      const lang = await loadLanguage( langs )
      localStorage.setItem( 'language', lang )
      setLastUpdate( new Date().toISOString() )
    } catch ( err ) {
      error = err
    }
    dispatch( setLoader( false ) )
    if ( error ) throw error
  }, [dispatch] )

  useEffect( () => {
    ( async () => {
      if ( !languageState ) return

      const oldLanguages = languages.toString()
      const languageData = languageState
      if ( oldLanguages !== languageData ) {
        await setAvailableLanguages( languageData )

        const search = location.search
        const query = new URLSearchParams( search )
        const queryLanguage = query.get( 'lang' )
        const localStorageLanguage = localStorage.getItem( 'language' )
        // TODO: MISSING USER PROFILE LANGUAGE
        try {
          await updateLanguage( { queryLanguage, localStorageLanguage } )
        } catch ( err ) {
          console.error( err )
        }
      }
    } )()
  }, [languageState, location.search, updateLanguage] )

  const contextValues = {
    frontDefaultLanguage,
    language,
    languages,
    lastUpdate,
    rtl,
    strings,
    updateLanguage,
  }

  return (
    <LanguageContext.Provider value={contextValues}>
      {children}
    </LanguageContext.Provider>
  )
}

export default LanguageContextProvider
