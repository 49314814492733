export const actions = {
    SET_LOADER: 'SET_LOADER',
}

export const setLoader = val => ( {
    type: actions.SET_LOADER,
    payload: val,
} )


