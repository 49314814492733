import React, {Component,Fragment} from 'react'
import AppData from '../common/AppData'
import PropTypes from 'prop-types'
import '../style/Banner.scss'
import { strings } from '../context/LanguageContext/strings'

class Banner extends Component {
  
  lastReceivedNotification = () => {
    this.shownTimer()
    return AppData.getAllNotifications()    
  }

  bannerClosed =  () => {
    this.props.bannerTransformed ( false )
    }

  bannerClicked = () => {
    this.props.showBannerGuideline()
    this.props.bannerTransformed( false )
  }

  titleState = () => {
    return this.props.title === 'Mobile Checkout' ? true : this.props.title === 'Live View' ? true : this.props.title === 'History' ? true : false
  }

  render(){
      return (
        <Fragment>
              <div className={ ( this.props.transformBanner && this.titleState() )  ? 'push-banner-container' : 'banner-container'}>
                <div className="banner-warning-icon" onClick={this.bannerClicked}>
                    <div className="banner-warning-icon-image"></div>
                </div>
                <div className="banner-text" onClick={this.bannerClicked}>
                    { this.props.bannerMessage }<u className="banner-text-link">{strings.moreInfoText}</u>
                </div>
                <div className="banner-close" onClick={this.bannerClosed}>
                    <div className="banner-close-image"></div>
                </div>
              </div> 
      </Fragment>
      )
  }
}

Banner.propTypes = {
  transformBanner: PropTypes.bool,
  bannerTransformed: PropTypes.func,
  bannerMessage: PropTypes.string,
  showBannerGuideline: PropTypes.func,
  title: PropTypes.string
}
export default Banner
