import { strings } from '../context/LanguageContext/strings'

export const errors = () => ( {
    NOT_ABLE_TO_CONNECT : strings.errorNotAbleToConnect,
    CONFIG_ERROR : {
        title: strings.errorConfigError,
        description: {
            text: strings.errorDescriptionText,
            phone: strings.errorDescriptionPhone
        }
    },
    NO_INTERNET:{
        title: strings.errorNoInternet,
        description: {
            text: strings.errorDescriptionText,
            phone: strings.errorDescriptionPhone
        }    
    },
    NO_INTERNET_ACCESS:{
        title: strings.errorNoInternetAccess,
        description: {
            text: strings.errorDescriptionText,
            phone: strings.errorDescriptionPhone
        }    
    },
    GET_JWT_FAILED: {
        title: strings.errorGetJWTFailed,
        description: {
            text: strings.errorDescriptionText,
            phone: strings.errorDescriptionPhone
        }    
    }
} )