import { createStore , compose, applyMiddleware } from 'redux'
import thunk  from 'redux-thunk'

import rootReducer from '../reducers'

const initialState = {}
const middleware = [thunk]
const composeEnhancers = ( process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null ) || compose

const store = createStore( 
    rootReducer, 
    initialState, 
    composeEnhancers( applyMiddleware( ...middleware ) )
)

export default store