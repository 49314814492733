import  React, { Fragment, useEffect } from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'

import history from '../history'
import Faq from './Faq'
import GuideContainer from './GuideContainer'
import HelpTabs from './HelpTabs'
import Feedback from './Feedback'
import get from 'lodash/get'

import '../style/Help.scss'

export default function Help( props ) {
    const {changeFeedbackState} = props

    useEffect( ( )=>{
        changeFeedbackState( true )      
        return () => {
            changeFeedbackState( false )
        }
    // eslint-disable-next-line
    },[] )

    const pathName = get( props, 'routeProps.history.location.pathname', '' )
    const helpData = [
            {
                title: 'faqAndVideosText',
                description:'faqAndVideosTextDescription',
                id: 1
            },
            {
                title:'troubleshootingGuidesText',
                description:'troubleshootingGuidesDescription',
                id: 2
            },
            {
                title:'feedbackText',
                description:'feedbackDescription',
                id: 3
            }
    ]

    const onTabsClick = ( title ) => {
        switch( title ){
            case 'faqAndVideosText':
                history.push( '/app/help/faq' )
                break
            case 'troubleshootingGuidesText':
                history.push( '/app/help/guides' )
                break
            case 'feedbackText':
                history.push( '/app/help/feedback' )
                break
            default:
                break
        }
    }

    const renderFaqView = ( ) => {
        return(
            <Fragment>
                <Route path="/app/help/faq" render={ () => <Faq {...props}/> }/>
                <Route path="/app/help/guides" render={ () => <GuideContainer singleGuideline={props.singleGuideline} incomingNotification={props.incomingNotification} 
                screenOrientation={props.screenOrientation} 
                guidelines={props.guidelines} 
                filteredTitle={props.filteredTitle}
                transformBanner={props.transformBanner}
                bannerClicked={props.bannerClicked}
                bannerPressed={props.bannerPressed}
                guideSource={props.guideSource}
                guidelineSourceCheck={props.guidelineSourceCheck}/> }/>
                <Route path="/app/help/feedback" render={ () =>  <Feedback screenOrientation={props.screenOrientation} pathName={pathName} 
                    feedbackOpened={props.feedbackOpened} /> } />
            </Fragment>
            
        )
    }

    return (
        <Fragment>
            <div className="help-container" >
                {helpData.map( ( help ) => {
                    return ( 
                        <HelpTabs key={help.id} 
                                title={help.title} 
                                description={help.description}  
                                onTabsClick={onTabsClick} 
                                {...props} 
                                screenOrientation={props.screenOrientation} 
                                />
                        )
                } ) }
            </div>
        { renderFaqView() }
        </Fragment>
    )
}

Help.propTypes = {
    screenOrientation: PropTypes.string,
    faqMenuToggle: PropTypes.bool,
    troubleshootingMenuToggle: PropTypes.bool,
    feedbackOpened: PropTypes.bool,
    changeFeedbackState: PropTypes.func,
    guidelines: PropTypes.array,
    incomingNotification: PropTypes.bool,
    singleGuideline: PropTypes.array,
    filteredTitle: PropTypes.string,
    transformBanner: PropTypes.func,
    bannerClicked: PropTypes.bool,
    bannerPressed: PropTypes.func,
    guideSource: PropTypes.string,
    guidelineSourceCheck: PropTypes.func
}
