import network from '../common/network'
import config from '../common/config'
import { checkLandscape } from '../common/Util'
import Mixpanel from '../common/Mixpanel'
import AppData from '../common/AppData'

export const getStoreInfo = () => () => {
    network.getLocationAndFeatureList(
        ( response ) => {
            let storeItem = response.store_details           
            let store = {}
            store.storeCity = storeItem ? storeItem.city : ''
            store.storeState = storeItem ? storeItem.state : ''           
            store.storeCountry = storeItem ? storeItem.country : ''
            store.storeZoneId = storeItem ? storeItem.zone_id : ''
            store.storeMarketId = storeItem ? storeItem.market_id : ''
            let strStoreObj = store && JSON.stringify( store )
            localStorage.setItem( 'store_object', strStoreObj )
            config.loadLocalData()
            Mixpanel.init()
            setTimeout( () => { 
                Mixpanel.open( checkLandscape() )
            } , AppData.locationFeatureDelay )
            
        },
        error => console.log( error )
    )
  }

export const fetchTabs = ( fetchTabsFromFeaturelist ) => () => {
    network.getLocationAndFeatureList(
        ( response ) => {
            fetchTabsFromFeaturelist( response )
        },
        error => console.log( error )
    )
}