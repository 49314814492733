//Importing Libraries
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'underscore'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'

//Importing Common util files
import AppData from '../common/AppData'
import AppAnalytics from '../common/AppAnalytics'
import network from '../common/network'
import logger from '../common/logger'
import config from '../common/config'
import { checkLandscape, delay,navbarConfirmedObject,
getCartWithDeals, topicConnection, cartModal } from '../common/Util'
import amplify from '../common/Amplify'
import history from '../history'
import Mixpanel from '../common/Mixpanel'

//Internal Imports
import store from '../store/store'

// Actions
import { fetchConfig } from '../actions/configActions'
import { userLogin, isSessionUpdated, trackLoginModal, videoEnabled, isLoggedIn } from '../actions/authActions'
import { fetchStationIds } from '../actions/notificationActions'
import { getStoreInfo,fetchTabs } from '../actions/storeLocaterActions'
import { setLoader } from '../modules/languageChange/actions'

//Importing Componets
import CartContainer from './CartContainer'
import Navbar from './Navbar'
import TransactionHistory from './TransactionHistory'
import HomeScreen from './HomeScreen'
import ErrorHandler from './ErrorHandler'
import Modal from './Modal'
import Help from './Help'
import Tabs from './Tabs'
import ReportContainer from './ReportContainer'
import OnboardingVideo from './OnboardingVideo'
import WhatsNew from './WhatsNew'
import Feedback from './Feedback'
import Banner from './Banner'

import '../style/App.scss'


logger.setLogCallback( ( timestamp, level, system, environment, principal, scenario, operation, correlation, trace, message, extra ) => {
  AppAnalytics.trackAction( operation, {
    timestamp: timestamp,
    level: level.name,
    category: `${level.size}`,
    system: system,
    principal: principal,
    scenario: scenario,
    operation: operation,
    correlation: correlation,
    trace: trace,
    message: message,
    extra: JSON.stringify( extra )
  } )
} )

let _pubSubAppHandler = () => {}
let _lastBuiltCartModels = []
let _lastBuiltOrderModels = []
let _pubsubMessageHandler = () => {}


class App extends Component {
    constructor() {
      super()
      let extra = {
        width: window.innerWidth,
        height: window.innerHeight 
      }
      logger.info( AppData.systemIdentifier, 'App Version', 'app_version', 'NA', 'App Version', AppData.appVersion )
      logger.info( AppData.systemIdentifier, 'LVT Screen Resolution', 'screen_resolution', 'NA', 'Screen resolution', extra  )

      window.appAnalytics = AppAnalytics
      AppData.clearPubSubListeners()

      config.removeOlderStorageCarts()
      config.removeOlderStoragePaid()
      config.removeOlderStorageOrders()
      config.removeOlderNotificationCarts()
      this.currentApi = {}
      this.state = {
        gridStyle: 'grid-container-2',
        cardStyle:'tile-container',
        isDetailPageOpen:false,
        scrollTop : 0,
        activityStatus : {},
        layoutStatus: {},
        allTips:[],
        dataFromCartContainer:{},
        navbarClicked: false,
        title:'Live View Tracker',
        screenOrientation: checkLandscape(),
        isError: false,
        showAndGoStatus: [],
        faqData:[],
        historyOrderCount: 0,
        allCartsAndOrders:[],
        isDataAvailable:false,
        isOpen:false,
        details:{},
        count:0,
        isVideoClicked: false,
        features:[],
        helpMenuToggle: true,
        faqMenuToggle: true,
        troubleshootingMenuToggle: true,
        feedbackOpened: false,
        deviceStatus:[],
        guidelines: [],
        incomingNotification: false,
        singleGuideline: [],
        filteredTitle:'',
        transformBanner: false,
        bannerClicked: false,
        bannerMessage:'',
        bannerId: '',
        pushNotification: false,
        guideSource: '',
        detailedCartCleared: false,
        isLoadingPaidOrders: false,
        isOrderPaidOrConfirmed: false
      }
      this.allEventsArray = []
      this.confirmedEventsArray = []
      this.lastLoadCardApi = null

     this.refresh()
      AppData.modeStartTime = Date.now()
       //Set Device Profile
      config.setDeviceProfile()    
      window.isTabActive = ( isValidSession ) => {
        logger.info( 'NA', 'check IOTConnection', 'check_iot_connection', 'NA', 'check IOTConnection', {} )
        AppData.isForeground =  true
        this.handleIoTConnection()
        this.fetchBannerInformation( 'tabActiveWindow' )
        this.props.isSessionUpdated( isValidSession, this.state.isVideoClicked )
        setTimeout( () => {
          Mixpanel.open( this.state.screenOrientation )
        }, AppData.mixpanelEventDelay )
      }
      window.isTabInactive = () => {
        Mixpanel.exitLiveView( this.state.screenOrientation )
        Mixpanel.closed( this.state.screenOrientation )
        AppData.isForeground =  false
        // clearing Polling setInterval with tab inactive
        clearInterval( this.timer )
      }
      window.userSessionUpdate = ( isValidSession ) => {
        this.props.isSessionUpdated( isValidSession, this.state.isVideoClicked )
        this.props.trackLoginModal( isValidSession )
      }
      // track iot events
      window.iotDidReceiveMessageForTopic = ( data ) => {
        if( this.props.amplifyState === false ){
          console.log( 'iotDidReceiveMessageForTopic triggered with data: ', data )
          AppAnalytics.trackTopicConnection( data )
          _.forEach( AppData.getAllPubSubListeners(), ( pubSubListener ) => {
            pubSubListener( data.message )
          } )
        } 
      }
      // IOT connect / disconnect / reconnect
      window.iotDidChangeLifecycleEvent = ( data ) => {
        console.log( 'iotDidChangeLifecycleEvent triggered with data: ', data )
        AppAnalytics.trackTopicConnection( data )
      }
      // IOT Exception function
      window.iotDidFailWithException = ( data ) => {
        console.log( 'iotDidFailWithException triggered with data: ', data )
        topicConnection( data )
        AppAnalytics.trackTopicConnection( data )
      }
      // IOT Subscription failure
      window.iotDidFailToSubscribeWithException = ( data ) => {
        console.log( 'iotDidFailToSubscribeWithException triggered with data: ', data )
        topicConnection( data )
        AppAnalytics.trackTopicConnection( data )
      }
      // IOT decode message failure
      window.iotDidFailToDecodeMessageForTopic = ( data ) => {
        console.log( 'iotDidFailToDecodeMessageForTopic triggered with data: ', data )
        topicConnection( data )
        AppAnalytics.trackTopicConnection( data )
      }
      window.addEventListener( 'online', this.handleConnectionChange ) 
      window.addEventListener( 'offline', this.handleConnectionChange )
      //fetch navigation cart with app foreground
      window.notificationRecieved = ( data ) => {
        // Notification check for shoppingCart
        if( ( data && data.type === 'shoppingAlert' ) || ( data && data.loyalty_id ) ){
          this.showPushNotificationCartModel( data.loyalty_id )
        }

        //fetch hardwareAlert guideline on notification click
        this.hardwareNotificationPathCheck()
      }
      window.bannerNotification = () => {
        this.pushNotificationEvent( true )
        setTimeout( () => {
          this.fetchBannerInformation( 'nativeOnResume' )
          this.pushNotificationEvent( false )
        } , 2000 )
      }
      amplify.configure()
    }

    refresh=()=>{
      this.refreshInterval  = setInterval( () => {
        window.location.reload()
      }, AppData.appRefreshInterval * 1000 )
    }


    handleConnectionChange = () => {
      if ( navigator.onLine ) {
        AppData.isError = false
        AppData.errorCount = 0
        AppData.errorMessage = ''
        this.setState( {
          isDetailPageOpen:false,
          isError: AppData.isError
        } )
        // history.push( '/app' )
      } else{
        AppData.isError = true
        AppData.errorMessage = 'NO_INTERNET'
        this.setState( {
          isError: AppData.isError
        } )
        // history.push( '/app' )
      }
        let webIOTStatus = amplify.isDisconnected === false ? 'Connected' : 'Disconnected'
        console.log( webIOTStatus )
        let nativeIOTStatus = window.Android && window.Android.getIOTStatus ? JSON.parse( window.Android.getIOTStatus() ).status : ''
        network.handleConnectionChange( webIOTStatus,nativeIOTStatus )   
    }  

    //Handle IoT connected or not
    // TODO: Remove commented code after testing
    handleIoTConnection = () => {
      // if( true ){
        amplify.reload()
      // }else{
      //   logger.info( 'NA', 'amplify isConnected', 'amplify_isConnected', 'NA', 'Amplify Connected', {status:200} )
      // }
    } 



    // start timer after 5 mins to check IoT is connected 
    startTimer() {
      setInterval( ()=>{
        this.handleIoTConnection()
      }, AppData.reconnectionIOTPeriod * 100 )
    }

    cartContainerChild = ( a , b ) => {
      this.setState( {
        isDetailPageOpen: a,
        dataFromCartContainer: b,
        incomingNotification: a,
        title : this.props.location.pathname === '/app' ? 'Live View Tracker' : null
      } )
    }

    lvtOrientationOnChange( orientationMode ){
      let startTime = Date.now()
      AppAnalytics.orientationOnChange( startTime, orientationMode )
      Mixpanel.orientationChanged( orientationMode  )
    }

    setScreenOrientation = () => {
      let screenOrientation = checkLandscape()
        this.setState( {
          screenOrientation: screenOrientation
        } )
        this.lvtOrientationOnChange( this.state.screenOrientation )
      }

    async componentDidMount(){
      AppAnalytics.orientationOnLoad( this.state.screenOrientation )
      setTimeout( () => {
        this.props.getStoreInfo()
      } , AppData.locationFeatureDelay )
      
      // fetching banner on app launch
      this.fetchBannerInformation( 'onAppLaunch' )

      // Fetch station ids , guidelines and filter guidelines as per station ids.
      // this.fetchStationIds()
      this.props.fetchStationIds( this.hardwareNotificationPathCheck )

      this.props.userLogin()
      window.addEventListener( 'orientationchange', this.setScreenOrientation ) 
      _pubSubAppHandler = ( message ) => {
        logger.info( 'NA', 'pubsub listerner', 'pubsub_topic', 'NA', 'received message from topic', message )
      } 

      const debouncedSocketAPICall = ( fun, key )=>{
        if( this.currentApi[key] ){
          clearTimeout( this.currentApi[key] )
        }
        this.currentApi[key] = setTimeout( () => {
          fun()
        }, 3000 )
      }

      const invokeAPIFromQueue = ( key,cartEvent )=>{
        if(cartEvent === 'orderConfirmed' || cartEvent === 'orderSelfcheckedout'){
          this.setState({
            isOrderPaidOrConfirmed: true
          })
        }
        else{
          this.setState({
            isOrderPaidOrConfirmed: false
          })
        }
        
        const bindAPICall = this.loadOrders.bind( this,   ()=>{
          this.loadOrders( () => { this.loadCarts( () => {}, false )}, false )
          this.getHistoryCount() 
        } )
        debouncedSocketAPICall( bindAPICall, key )   
      }

      _pubsubMessageHandler = ( message ) => {
        let bindAPICall
        if ( message && message.event_type === 'cartChanged' ) {
            bindAPICall = this.loadOrders.bind( this, () => { this.loadCarts( () => {}, false )}, true )
            debouncedSocketAPICall( bindAPICall, 'cartChanged' )            
        } else if ( message && message.event_type === 'orderSelfcheckedout' ) {       
            const arrLength = this.allEventsArray.length
            const messageTime = new Date( this.allEventsArray[arrLength-1]?.customer_id ).getTime()
            const currentTime = new Date( message?.customer_id ).getTime()
            if( arrLength &&  messageTime <= currentTime ) {
              this.allEventsArray = []
              this.allEventsArray.push( message )
              invokeAPIFromQueue( message.updated_time, 'orderSelfcheckedout' )
            }else if( !arrLength ){
              this.allEventsArray.push( message )
              invokeAPIFromQueue( message.updated_time,'orderSelfcheckedout')
            }
        }else if ( message &&  message.event_type === 'orderConfirmed'  ) {       
          const arrLength = this.confirmedEventsArray.length
          const messageTime = new Date( this.confirmedEventsArray[arrLength-1]?.customer_id ).getTime()
          const currentTime = new Date( message?.customer_id ).getTime()
          if( arrLength &&  messageTime <= currentTime ) {
            this.confirmedEventsArray = []
          }
          this.confirmedEventsArray.push( message )
          invokeAPIFromQueue( message.updated_time,'orderConfirmed')
        }else if ( message && message.event_type === 'cartCleared' ){
            bindAPICall = this.filterClearedCarts.bind( this, message.customer_id )
            debouncedSocketAPICall( bindAPICall, 'cartCleared' ) 
        } else if ( message && message.event_type === 'bannerUpdated' ){
          this.transformBanner( false )
        } else if ( message && message.event_type === 'bannerCreated' ){
          if( this.state.pushNotification === false ){
            this.fetchBannerInformation( 'iotTriggeredBanner' )
          }
        }
    } 
      AppData.addPubSubListerner( _pubsubMessageHandler )
      AppData.addPubSubListerner( _pubSubAppHandler )

      // Clear Carts  with or without ( Tab Change )
      AppData.clearCartsListener = () => {
        let newCarts = _.filter( this.state.allCartsAndOrders,  ( cartModel ) => {
            let delta = Date.now() - new Date( cartModel.updated_time ).getTime() + AppData.cartFilterTimeBuffer
            if (  cartModel.itemType && cartModel.itemType === 'cart' && ( delta > AppData.ordersFilterTime ) )   {
                return false
            }
            return true
        } ) 
        this.setState( {
            allCartsAndOrders: newCarts,
            isDataAvailable:true
        } )
    }

    AppData.clearPaidOrderListener = () => {
        let newCarts = _.filter( this.state.allCartsAndOrders, ( cartModel ) => {
            if ( ( cartModel.itemType && cartModel.itemType === 'order' &&  Date.now() - new Date( cartModel.updated_time ).getTime() > ( AppData.erasePaidInterval * 1000 ) ) ) {
                return false
            }
            return true
        } ) 
        this.setState( {
            allCartsAndOrders: newCarts,
            isDataAvailable:true
        } )
    }

    AppData.clearConfirmedOrderListener = () => {
        let newCarts = _.filter( this.state.allCartsAndOrders, ( cartModel ) => {
            if ( ( cartModel.itemType && cartModel.itemType === 'order' && cartModel.isOrderConfirmed && config.getShownOrders().hasOwnProperty( cartModel.order_id ) ) ) {
                if ( Date.now() > config.getShownOrders()[cartModel.order_id] + ( AppData.eraseOrderInterval  ) ) {
                    return false
                }
            }
            return true
        } )   
        this.setState( {
            allCartsAndOrders: newCarts,
            isDataAvailable:true
        } )
    }
    if( config.storeId() === null ){
      // history.push( '/' )
    }
    else{
      // TODO: Remove commented code after testing
      amplify.pubsubSubscribe() 
      // if( AppData.startIoTTimer ){
        this.startTimer()
      // }
    }


    // Calling Config Action   
    this.props.fetchConfig()

    this.loadData()
    //Montioring functionalities
    setTimeout( () => {
      let webIOTStatus = amplify.isDisconnected === false ? 'Connected' : 'Disconnected'
        let nativeIOTStatus = window.Android && window.Android.getIOTStatus ? JSON.parse( window.Android.getIOTStatus() ).status : ''
        network.startHeartbeat( webIOTStatus,nativeIOTStatus )   
      // this.fetchTabsFromFeaturelist()
      this.props.fetchTabs( this.fetchTabsFromFeaturelist )

      if( this.fetchNotificationCartId().type === 'shoppingAlert' ){
        this.showPushNotificationCartModel( this.fetchNotificationCartId().loyalty_id )
      }
      let state = navigator.serviceWorker && navigator.serviceWorker.controller && navigator.serviceWorker.controller.state ? navigator.serviceWorker.controller.state : 'unknown'
      AppAnalytics.trackCachedLogs( state )
      AppAnalytics.wifiSpeedCheck()
      this.getHistoryCount()
    }, AppData.eventDelay )

    this.initializePolling()
  }

   // tabs check to show through featurelist api call
   fetchTabsFromFeaturelist = ( res ) => {
      this.setState( { features: res.features} )
      let featureNames = [] 
      this.state.features.forEach( ( data ) => featureNames.push( data.feature_name ) )
      if( !featureNames.includes( 'help_menu' ) ){
        this.setState( { helpMenuToggle: false} )
      }
      if( !featureNames.includes( 'faq_menu' ) ){
        this.setState( { faqMenuToggle: false} )
      }
      if( !featureNames.includes( 'troubleshooting_guide_menu' ) ){
        this.setState( { troubleshootingMenuToggle: false} )
      }      
  }

  componentDidUpdate( prevProps, prevState ){
    if( AppData.isError !== this.state.isError ){
        this.setState( {
          isError: AppData.isError
        } )   
    }
    if( prevProps.activityStatus !== this.props.activityStatus ){
        this.loadData()
    }
    if( prevState.navbarClicked !== this.state.navbarClicked ){
      this.setState( { details: this.modalUpdate() } )
    }
    // For dismissing onboarding video after clicking dismiss on login modal
    if(  prevProps.auth.videoEnabled !== this.props.auth.videoEnabled ){
      let trailer = document.querySelector( '.video-trailer' )
      let video = document.querySelector( 'video' )
      trailer.classList.remove( 'active' ) 
      trailer.classList.add( 'deactive' )
      video.pause()
      video.currentTime = 0
    }

    //On language parameter change, API data will update as per given language in URL string
    if ( this.props.location.search !== prevProps.location.search ) {
      // Calling Config Action   
      this.props.fetchConfig()
    }
  }
    
  componentWillUnmount() {
    window.removeEventListener( 'online', network.handleConnectionChange )
    window.removeEventListener( 'offline', network.handleConnectionChange )
    AppData.removePubSubListener( _pubSubAppHandler )
    AppData.removePubSubListener( _pubsubMessageHandler )
    AppData.clearCartsListener = () => {}
    AppData.clearPaidOrderListener = () => {}
    AppData.clearConfirmedOrderListener = () => {}
    clearInterval( this.timer )
    clearInterval( this.refreshInterval)
  }

  // Fetch Banner information
  fetchBannerInformation = ( source ) => {
    network.getNotificationBanner(
      ( response ) => {
          if( response.length !== 0 ){
            let bannerMessage = response.message
            this.setState( { bannerMessage : bannerMessage } )
            this.setState( { transformBanner : true, bannerClicked : true } )
            this.setState( { bannerId : response._id } )
            AppAnalytics.fetchBanner( source )
          }
      },
      error => console.log( error )
    )
   }

   bannerPressed = ( bool ) => {
     this.setState( { bannerClicked: bool } )
   } 

   //change pushNotification state
   pushNotificationEvent = ( bool ) => {
     this.setState( { pushCartNotification: bool } )
   }

  // fetch stationIDs and call guidelines
  fetchStationIds = () => {

    // getting station version Ids from localStorage
    let stationIds = JSON.parse( localStorage.getItem( 'lvt.stations' ) )

    // check if stationId doesnt exist in localStorage, make fetchStation api call
    if( !stationIds ){
      network.getDeviceStatus(
        ( response ) => {
          let deviceStatusIds = []
          response.data.device.forEach( ( deviceId ) => {
            if( deviceId.stationVersion ){
              deviceStatusIds.push( deviceId.stationVersion )
            }
          } )
          this.setState( { deviceStatus : deviceStatusIds } )
          localStorage.setItem( 'lvt.stations' , JSON.stringify( this.state.deviceStatus ) )  
          this.fetchGuidelines()
        },
        ( error ) => {
          this.setState( { deviceStatus : ['SNP02'] } )
          localStorage.setItem( 'lvt.stations' , JSON.stringify( this.state.deviceStatus ) )  
          this.fetchGuidelines()
          console.log( error )
        }
      )
    }

    //If stationId exist in localStorage, only make Guideline api call
    if( stationIds ){
      this.setState( { deviceStatus : stationIds } )
      this.fetchGuidelines()
    }
  } 

    // filter guidelines as per banner
    showBannerGuideline = async () => {
          let readStatus = {
            'status' : 'read'
          }
          let singleFilteredResponse = []
          let deviceType = this.state.bannerMessage
          let device = deviceType.includes( 'Confirmation' ) ? 'Station' : 'Speaker'
          // track confirmation station down
          if( device === 'Station' ){
            for( let guideline of this.state.guidelines ){
              if( guideline.name.includes( device ) ){
                singleFilteredResponse.push( guideline )
              }
            }
           this.setState( { incomingNotification : true } )
           await delay ( AppData.bannerDetailShownDelay )
           this.bannerPushed( singleFilteredResponse,readStatus )
          }
          // track speaker down
          if( device === 'Speaker' ){
            for( let guideline of this.state.guidelines ){
              if( guideline.name.includes( device ) ){
                singleFilteredResponse.push( guideline )
              }
            }
            this.setState( { incomingNotification : true } )
            await delay ( AppData.bannerDetailShownDelay )
            this.bannerPushed( singleFilteredResponse,readStatus ) 
          }
          Mixpanel.hardwareAlertTap( 'Hardware' , device, this.state.title,'NA','Banner' )
    }
 
    // Banner push to show guideline through history.push
    bannerPushed = ( singleResponse, bannerStatus ) => {
      this.setState( { guideSource: 'Banner' } )
      this.setState( { singleGuideline: singleResponse } )
      this.setState( { filteredTitle : _.first( this.state.singleGuideline ).title } )
      let guidelineName = _.first( this.state.singleGuideline ).name
      history.push( `/app/help/guides/steps/:${guidelineName}` )
      this.putBannerRequest( bannerStatus )
    }
    // Banner PUT request
    putBannerRequest = ( status ) => {
      network.putBanner( status, response => {
        return response
    }, error => { console.log( error ) }, this.state.bannerId ) }

    // fetch guidelines
    fetchGuidelines = () => {
      network.getGuidelines(
      ( response ) => {
        if( this.state.deviceStatus.length === 0 ){
          this.setState( { deviceStatus : ['SNP02'] } )
        }
          let filteredResponse = []
          response.forEach( ( item ) => {
              if( item.tags.includes( this.state.deviceStatus[0] ) ){
                  filteredResponse.push( item )
              }
          } )
          this.setState( {  guidelines: filteredResponse } )

          // Hardware notification check
          this.hardwareNotificationPathCheck()         
      },
      ( error )=>{
          console.log( error )
      }
    )}

// Hardware notification check
hardwareNotificationPathCheck = () => {
  let singleFilteredResponse = []
          if( this.fetchNotificationCartId() && this.fetchNotificationCartId().path === '/app/help/guides/steps/Check_Power_to_Speaker' && ( ( Date.now() - this.fetchNotificationCartId().created ) < AppData.guidelineDelay ) ){
            console.log( Date.now() - this.fetchNotificationCartId().created )
            let newGuidelineName = this.fetchNotificationCartId().path.split( '/' ).pop().split( '_' ).join( ' ' )
            for( let guideline of this.state.guidelines ){
              if( guideline.name === newGuidelineName ){
                singleFilteredResponse.push( guideline )
              }
            }
           this.setState( { guideSource: 'Push' } )
           this.setState( { singleGuideline: singleFilteredResponse } )
           this.setState( { filteredTitle : _.first( this.state.singleGuideline ).title } )
           this.setState( { incomingNotification : true } )
           let guidelineName = _.first( this.state.singleGuideline ).name
           history.push( `/app/help/guides/steps/:${guidelineName}` ) 
           // track mixpanel event
           setTimeout( () => {
            Mixpanel.hardwareAlertTap(  'Hardware' , 'Speaker', this.state.title,'NA','Push' )
           }, AppData.mixpanelEventDelay )
          
          }

          if( this.fetchNotificationCartId() && this.fetchNotificationCartId().path === '/app/help/guides/steps/Restart_Confirmation_Station' && ( ( Date.now() - this.fetchNotificationCartId().created ) < AppData.guidelineDelay ) ){
            console.log( Date.now() - this.fetchNotificationCartId().created )
            let newGuidelineName = this.fetchNotificationCartId().path.split( '/' ).pop().split( '_' ).join( ' ' )
            for( let guideline of this.state.guidelines ){
              if( guideline.name === newGuidelineName ){
                singleFilteredResponse.push( guideline )
              }
            }
           this.setState( { guideSource: 'Push' } )
           this.setState( { singleGuideline: singleFilteredResponse } )
           this.setState( { filteredTitle : _.first( this.state.singleGuideline ).title } )
           this.setState( { incomingNotification : true } )
           let guidelineName = _.first( this.state.singleGuideline ).name
           history.push( `/app/help/guides/steps/:${guidelineName}` ) 
           // track mixpanel event
           setTimeout( () => {
            Mixpanel.hardwareAlertTap( 'Hardware' , 'Station', this.state.title,'NA','Push' )
           }, AppData.mixpanelEventDelay )
          
          }
}

// guideline source check
guidelineSourceCheck = ( source ) => {
  this.setState( { guideSource: source } )
}

// polling functionality

  //Data refresh 
  dataRefresh = () => {
    if( !AppData.isForeground ){
      return
    }
    this.loadOrders( () => { this.loadCarts( () => {}, false )}, false )
  }

  onPolling = () => {
    this.timer = setInterval( () => {
        this.dataRefresh()
    }, this.props.pollingInterval )
  }


  // initialize polling
  initializePolling = () => {
    setTimeout( () => { 
      if(  AppData.pollingEnabled ){
        this.onPolling()
      }
    }, AppData.pollingInitialDelay )

  }

  // disable polling
  disablePolling = () => {
    clearInterval( this.timer )
  }

    onNavigationHandler = ( view ) => {
      this.setState( {
        title: view
      } )
    }

    modalUpdate = () => {
      let data = _.last( AppData.getAllNotifications() )
      return navbarConfirmedObject( data ) 
    }

    // toggle navbarClicked state when notification is clicked
    navbarStateChange = () => {
      this.setState( { navbarClicked: !this.state.navbarClicked } )
    }

    // Function to pass in cartContainer to initiat Live View Viewed
    onLiveView = () => {
      !store.getState().languageChange.isLoading && Mixpanel.onLiveView( checkLandscape() )
    }

    // function to pass in history content container to initiate History View
    onHistoryView = () => {
      !store.getState().languageChange.isLoading && Mixpanel.onHistoryView( checkLandscape() )
    }

    // function to pass in Help container to initiate Help View
    onFaqView = () => {
      Mixpanel.onFaqView( checkLandscape() )
    }

    //get History Cart Count to show in App.js menu bars.
    getHistoryCount = () => {
      let currentDate = new Date()
      let yesterdayDate = new Date( new Date().setDate( new Date().getDate() - AppData.previousOrderPerDays ) ) 
      let query = {
          'created' : { $lt: currentDate , $gte: yesterdayDate}
      }
      network.getOrdersConfirmed(
          ( response )=>{
            this.props.setLoader( false )
            let ordersData = response && response.data ? response.data : []   
            // eslint-disable-next-line no-unused-vars
            let ordersDataCount = ordersData && ordersData.orders ? ordersData.orders : []
             ordersDataCount = ordersDataCount.filter( ( order )=>{
                if( this.getUnwantedName( order.user_profile.first_name ) ){
                return false
                  }
                  if( order.order_status === 'selfcheckedout' ){
                      return true
                  } 
                  return null
                } ).length
            this.setState ( { historyOrderCount : ordersDataCount } )       
          },
          ( error )=>{
              console.log( error )
          },
          query
      )
    }

     //showAndGo store check
     isShowAndGo = () => {
      return AppData.isStoreShowAndGo()
  }

  // Getting unnwantedName from props
  getUnwantedName = ( customerName ) => {
    const { unwantedNames } = this.props

    let _unwanted = ( unwantedNames !== undefined ) ?  _.first( unwantedNames ).default_value  : '' 

    return _unwanted.includes( customerName )
  }
  
  //loading data
  loadData = () => {
      this.loadOrders( () => { 
          this.loadCarts (  () => { 
              AppData.didLoadData() }  , false ) 
          } , false ) 
  }

  // loading scan carts in view Model 
  loadCarts = ( completionHandler, useCache = false ) => {
    this.setState({
      isLoadingPaidOrders: true,
    })
      this.getCarts( ( isSuccess, carts ) => {
          if ( isSuccess === false ) {
              // TODO: handle failed carts, log and ignore
              this.setState({
                isLoadingPaidOrders: false,
              })
              completionHandler()
              return
          }
          this.setState({
            isLoadingPaidOrders: false,
          })
          let lastFetchedCartsAndOrders = AppData.getAllTransactions()

          AppData.storeTransactions( carts, lastFetchedCartsAndOrders.orders )
          let cartViewModels = this.cartViewModelsFromModels( carts )
          _lastBuiltCartModels = cartViewModels

          this.setState( {
              allCartsAndOrders: [ ..._lastBuiltOrderModels, ...cartViewModels],
              isDataAvailable:true
          } )
          if( completionHandler ){
              completionHandler()
          }
      }, useCache )
  }

  // loading paid orders in view Model
  loadOrders = ( completionHandler, useCache ) => {
    this.setState({
      isLoadingPaidOrders: true,
    })
      this.getOrderConfirmed( ( isSuccess, filteredCarts, orders ) => {
          if ( !isSuccess ) {
              this.setState( {
                  allCartsAndOrders: [ ..._lastBuiltOrderModels, ..._lastBuiltCartModels],
                  isDataAvailable:true,
                  isLoadingPaidOrders:false
              } )
              completionHandler()
              return
          }
          this.setState({
            isLoadingPaidOrders: false,
          })
            let ordersViewModels = this.orderViewModelsFromModels(orders);
            let cartViewModels = this.cartViewModelsFromModels(filteredCarts);
            AppData.storeTransactions(filteredCarts, orders);
            _lastBuiltOrderModels = ordersViewModels;
            _lastBuiltCartModels = cartViewModels;
            this.setState({
              allCartsAndOrders: [...ordersViewModels, ..._lastBuiltCartModels],
            });
          completionHandler()
      }, useCache )
  }

    // filtering cleared carts
    filterClearedCarts = ( id ) => {
      let carts = AppData.getAllTransactions().carts.filter( ( cart ) => {
          return cart.customer_id !== id 
      } )
      let orders = AppData.getAllTransactions().orders
      let ordersViewModels = this.orderViewModelsFromModels( orders )
      let cartViewModels = this.cartViewModelsFromModels( carts )
      AppData.storeTransactions( carts, orders )
      _lastBuiltOrderModels = ordersViewModels
      _lastBuiltCartModels = cartViewModels 
        this.setState( {
                allCartsAndOrders: [ ...ordersViewModels, ..._lastBuiltCartModels]
            } )
    }

  /**
     * fetches the carts models from cloud. Filters and sorts unwanted carts.
     * {completionHandler} return true and array of carts when success, false and null when fail .
     **/
    getCarts =  async (  completionHandler, useCache = false ) => {
      const { activityStatus } = this.props 
      let cartDetails = []
      // Fetch only if scanning is enabled
      if( activityStatus !== undefined && activityStatus.hasOwnProperty( 'scan' ) &&  activityStatus[ 'scan' ] === 'scan' ){
          let networkCompletionHandler = ( cartDetails )=>{
            let shownCarts = config.getShownCarts()
            if ( shownCarts === null ) {
                shownCarts = {}
            }
              // Map products to items
              let carts = getCartWithDeals( cartDetails , null )
              // Filter unwanted carts
              carts = _.filter( carts , ( cart ) => {
                  let now = Date.now()
                  let cartDate = new Date( cart.updated_time )
                  let cartDateToMilliseconds = cartDate.getTime()
                  
                          
                  if( now - cartDateToMilliseconds < AppData.ordersFilterTime && !this.getUnwantedName( cart.user_profile.first_name ) ){
                    
                    if( AppData.isForeground ){
                      if( shownCarts && shownCarts[cart._id] === undefined ){
                        shownCarts[cart._id] = Date.now()
                          AppAnalytics.shoppingCartRoundTrip( Math.abs( Date.now() - new Date( cart.updated_time ) ) / 1000, cart._id )
                      }
                    }
                    
                    return true
                  }
                  return false
                  
              } )
              carts = carts.sort( ( cartA , cartB ) => {
                  let first , second = ''
                  if( cartA.updated_time  && cartB.updated_time ){
                      first = new Date( cartA.updated_time )
                      second = new Date( cartB.updated_time ) 
                  }
                  return second - first 
              } )  
              config.setShownCarts( shownCarts )
              completionHandler( true, carts )
          }
          if( useCache ){
              networkCompletionHandler( AppData.getAllTransactions().carts )
              return
          }
          network.getAllCarts(
              ( response ) => {
                  let resp = response && response.data && response.data.carts ? response.data.carts : []
                  if ( resp === undefined ) {
                      completionHandler( true, [] )
                      return
                  }
                  cartDetails = response && response.data && response.data.carts ? _.first( response.data.carts ) : []
                  networkCompletionHandler( cartDetails )
                  AppAnalytics.apiSource( 'Menu Page' )
              } ,
              ( error )=>{
                  console.log( error )
                  completionHandler( false, null )
                  AppAnalytics.errorLoadingCarts( error.response )
              }
          )
      }else{
          completionHandler( false, [] )
      }
  }

  /**
   * fetches the order models from cloud. Filters and sorts unwanted orders
   * {completionHandler} return true and array of carts when success, false and null when fail .
   **/
  getOrderConfirmed = async ( completionHandler , useCache = false ) => {
      const { activityStatus } = this.props 
      let timeStamp = Date.now() - AppData.ordersFilterTime
      let latestOrders = new Date( timeStamp ).toISOString()
      let query = {
        'created' : { $lt: new Date() , $gte: latestOrders}
      }
      const networkCompletionHandler = ( networkOrders ) => {
          let shownOrders = config.getShownOrders()
          let shownPaidOrders = config.getShownPaidOrders()
          if ( shownOrders === null ) {
              shownOrders = {}
          }
          if( shownPaidOrders === null ){
            shownPaidOrders = {}
          }
          // Filter unwanted orders 
          let ordersTokenArr = []
          let orders =  _.filter( networkOrders, ( order ) => {
              ordersTokenArr.push( order.arbitrationToken )
              order.itemType = 'order'
              let orderId = order.order_id
              if( this.getUnwantedName( order.user_profile.first_name ) ){
                return false
              }
              // Filter by created date
              if ( order.created ) {
                  let orderCreatedDelta = Date.now() - new Date( order.created )
                  if ( orderCreatedDelta > AppData.ordersFilterTime ) {
                      return false
                  }
              }
              // Filter by total display time
              if ( this.isShowAndGo() || order.is_order_confirmed ) {
                  if ( shownOrders && shownOrders[orderId] !== undefined ) {
                      let lastShownTime = shownOrders[orderId]
                      let delta = ( Date.now() - lastShownTime )
                      if ( delta > ( AppData.eraseOrderInterval  ) ) {
                          return false
                      }
                  } else {
                      // Showing for first time, save time to persistence
                      shownOrders[orderId] = Date.now()
                      // for confirmed cart roundtrip time calculation
                      if( AppData.isForeground ){
                        AppAnalytics.confirmedCartRoundTrip( Math.abs( Date.now() - new Date( order.last_updated ) ) / 1000, orderId )
                      }
                  }
              }
              // Filter by feature flag
              if( ( this.props.activityStatus['confirmed'] !== 'confirmed' && order.is_order_confirmed === true ) || ( this.props.activityStatus['paid'] !== 'paid' && order.is_order_confirmed === false ) ){
                  return false
              } 
              //Filter old paid orders
              if ( order.is_order_confirmed === false ) {
                if( shownPaidOrders && shownPaidOrders[orderId] !== undefined ){
                  if( Date.now() - new Date( order.created ).getTime() > ( AppData.erasePaidInterval * 1000 ) ){
                    return false
                  }
                }else{
                  shownPaidOrders[orderId] = Date.now()
                  if( AppData.isForeground ){
                    AppAnalytics.paidCartRoundTrip( Math.abs( Date.now() - new Date( order.transaction_time ) ) / 1000, orderId )
                  }
                }
              }
              // Filter by order status
              return AppData.ordersAllowed.includes( order.order_status )
          } )
          let lastFetchedCartsAndOrders = AppData.getAllTransactions()
          
          let filteredCarts = lastFetchedCartsAndOrders.carts.filter( ( item ) =>{
              return  ordersTokenArr.includes( item.arbitrationToken ) === false
          } )
          orders = this.sortOrders( orders )
          config.setShownPaidOrders( shownPaidOrders )
          config.setShownOrders( shownOrders )
          completionHandler( true, filteredCarts , orders )
      }
      // Fetch only if paid or confirmed is enabled
      if( activityStatus !== undefined && activityStatus.hasOwnProperty( 'paid' ) && activityStatus.hasOwnProperty( 'confirmed' ) && ( activityStatus[ 'paid' ] === 'paid' || activityStatus[ 'confirmed' ] === 'confirmed' ) ){
          if( useCache ){
              networkCompletionHandler( AppData.getAllTransactions().orders )
              return
          }
          network.getOrdersConfirmed(
              ( response )=>{
                  if ( !( response && response.data && response.data.orders ) ) {
                      networkCompletionHandler( [] )
                      return
                  }
                  networkCompletionHandler( response.data.orders )
              },
              ( error )=>{
                  console.log( error )
                  completionHandler( false, null, null )
                  AppAnalytics.errorLoadingOrderedCarts( error.response )
              },
              query
          )
      }else{
          completionHandler( false, [] )
      }
  }
  
  // Generate Cart ViewModels
  cartViewModelsFromModels = ( carts ) => {
      let viewModels = []
      let detailedCart = carts.filter((item)=> item._id === this.state.details._id)
      if (carts === undefined || carts.length < 1) {
        this.state.isOpen && this.updateCartModal({ cartCleared: true });
        return viewModels;
      }
      if(this.state.isOpen &&  detailedCart.length == 0 && !this.state.isOrderPaidOrConfirmed){
        this.updateCartModal( {cartCleared  : true} )
          return viewModels;
      }
      this.setState({detailedCartCleared:false})
      carts.map( ( cart ) => {
          this.updateCartModal( cart )
          let vm = {}
          if ( cart.user_profile ) {
              let user_profile =  cart.user_profile 
              if ( user_profile ) {
                  vm.user_profile = {
                      first_name: _.isArray( user_profile ) ? _.first( user_profile.first_name ) : user_profile.first_name,
                      last_name: _.isArray( user_profile ) ? _.first( user_profile.last_name ) : user_profile.last_name,
                      email: _.isArray( user_profile ) ? _.first( user_profile.email ) : user_profile.email,
                      loyalty_id: _.isArray( user_profile ) ? _.first( user_profile.loyalty_id ) : user_profile.loyalty_id
                  }
              }
          }
          vm._id = cart._id
          vm.customer_id = cart.customer_id
          vm.grandTotal = cart.grandTotal
          vm.updated_time = cart.updated_time
          vm.arbitrationToken = cart.arbitrationToken
          vm.items = []
          vm.itemType = cart.itemType
          vm.cartWithDeals = cart.cartWithDeals
          cart.items.map( ( item ) => {
          let vmItem = {}
          vmItem.product_description = item.product_description 
          vmItem.deals = []
          if ( item.deals && item.deals.length > 0  ) {
              item.deals.map( ( deal ) => {
                  let vmItemDeal = {}
                  vmItemDeal.Description = deal.Description
                  vmItemDeal.SubTotal = deal.SubTotal
                  vmItem.deals.push( vmItemDeal )
                  return null
                  } )
              }
              vmItem.product = ( item.product !== undefined ) ? {
                name: item.product.name ? item.product.name : item.product_name,
                price: item.product.price ? item.product.price : item.price,
                product_id: item.product_id ? item.product_id : item.product.product_id
              }:{
                  name: item.product_id,
                  price: 0,
                  product_id: item.product_id
              }
          vmItem.eco = []
          if ( item.eco && item.eco.length > 0  ) {
                item.eco.map( ( deal ) => {
                    let vmItemDeal = {}
                    vmItemDeal.Description = deal.Description
                    vmItemDeal.Total = deal.Total
                    vmItemDeal.ProductType = deal.ProductType
                    vmItem.eco.push( vmItemDeal )
                    return null
                    } )
            }
          vmItem.deposit = []
          if ( item.deposit && item.deposit.length > 0  ) {
            item.deposit.map( ( deal ) => {
                let vmItemDeal = {}
                vmItemDeal.Description = deal.Description
                vmItemDeal.Total = deal.Total
                vmItemDeal.ProductType = deal.ProductType
                vmItem.deposit.push( vmItemDeal )
                return null            
                } )
            }
          vmItem.quantity = item.quantity
          if ( item.product !== undefined ){
              vm.items.push( vmItem )
          }
              return null
          } )

          //Abandoned carts setTimeout
          const abandonedCartsTimeout = () => {
              let abandonedCartTimer = setTimeout( () => {
                  AppData.clearCartsListener()
                  logger.info( 'NA', 'SetTimeout', 'Abandoned_Carts', 'NA', 'Abandoned Carts erased after 15 min success', 'NA' )
              }, AppData.ordersFilterTime )
              AppData.abandonedCarts[ cart._id ] = {
                  lastUpdateTime : cart.updated_time,
                  abandonedCartTimer : abandonedCartTimer
              }           
          }

          //Adding timer 
          if( !Object.keys( AppData.abandonedCarts ).includes( cart._id  ) && Date.now() - new Date( cart.updated_time ).getTime() < AppData.ordersFilterTime ) {
              abandonedCartsTimeout()
          }else if( AppData.abandonedCarts[cart._id].lastUpdateTime !== cart.updated_time ) {
              clearTimeout( AppData.abandonedCarts[cart._id].abandonedCartTimer )
              abandonedCartsTimeout()
          }
          viewModels.push( vm )
          return null
      } )
      return viewModels
  }


  // Generate Order ViewModels
  orderViewModelsFromModels = ( orders ) => {
      let viewModels = []
      if ( orders === null || orders === undefined  ) {
          return viewModels
      }

      let lastFetchedCartsAndOrders = AppData.getAllTransactions()
      let allOrderIds = _.map( lastFetchedCartsAndOrders.orders, ( order ) => {
              return order.order_id
      } )

      orders.map( ( order ) => {
          let vm = {}
          if ( order.user_profile && order.user_profile.first_name ) {
              vm.user_profile = {
                  first_name: order.user_profile.first_name,
                  last_name: order.user_profile.last_name,
                  email: order.user_profile.email,
                  loyalty_id: order.user_profile.loyalty_id,
              }
          }
          vm.customer_id = order.customer_id
          vm._id = order.cart_id
          vm.order_id = order.order_id
          vm.isOrderConfirmed = ( this.isShowAndGo() || order.is_order_confirmed )
          vm.orderStatus = order.order_status
          vm.transaction_icon = true
          vm.showAndGoIconCheck = this.isShowAndGo()
          let oldOrderExists = allOrderIds.includes( order.order_id )
          if ( !oldOrderExists ) {
              let startTime = Date.now()
              AppAnalytics.OrderConfirmed( startTime, order.order_id )
              setTimeout( () => {
                Mixpanel.orderConfirmedSuccess()
              }, AppData.mixpanelEventDelay )
              
              
          }
          if ( ( this.isShowAndGo() || order.is_order_confirmed ) ) {
              vm.transaction_status = true // changed from this.isGreenOrder(order_id) function call to TRUE
              if ( !AppData.timerArray.includes( order.order_id ) ) {
                  AppData.timerArray.push( order.order_id )
                  vm.successSound = AppData.shouldSoundPlay( order.order_id ) 
                  let startTime = Date.now()
                  let orderId = order.order_id
                  /**
                      * setTimout functionality used to turn carts color from green to white after 30 sec 
                      * FOR THE FUTURE USE  
                  **/
                  /** setTimeout( ()=>{
                      let newCarts = _.map( this.state.allCartsAndOrders ,  ( cartModel ) => {
                           if ( cartModel.order_id === orderId && vm.transaction_status ) {
                               cartModel.transaction_status = false
                          }

                          return cartModel
                      } )
                      this.setState( {
                          allCartsAndOrders: newCarts
                      } )
                      let endTime = Date.now()
                      AppAnalytics.greenCardDuration( orderId,startTime,endTime )
                  }, AppData.quickPeekInterval )
                  **/

                  setTimeout( () => {
                      AppData.clearConfirmedOrderListener()
                      let endTime = Date.now()
                      AppAnalytics.greenCardDuration( orderId,startTime,endTime )
                      logger.info( 'NA', 'SetTimeout', 'Orders_Confirmed', 'NA', 'Order erased after 2 min success', 'NA' )
                  }, AppData.eraseOrderInterval )
              }
          } else {
              if( vm.isOrderConfirmed === false && !AppData.paidOrders.includes( vm.order_id )   ) {
                  AppData.paidOrders.push( vm.order_id )
                  setTimeout( () => {
                     AppData.clearPaidOrderListener()
                     logger.info( 'NA', 'SetTimeout', 'PaidOrders_Confirmed', 'NA', 'Paid Order erased after 5 min success', 'NA' )
                  }, AppData.erasePaidInterval * 1000 )
              }
              vm.transaction_status = false
          }
          vm.grandTotal = order.total
          vm.updated_time = order.created
          vm.items = []
          vm.itemType = order.itemType
          vm.arbitrationToken = order.arbitrationToken
          let lastItem = null
          order.items.forEach( ( item ) => {
              if( ( item.product_id && item.name && item.price ) !== undefined  ){
                  if ( item.price < 0 ||  item.ProductType === 62 || item.ProductType === 53 || item.ProductType === 54  ) {
                      if ( lastItem ) {
                          let vmItemDeal = {}
                          vmItemDeal.Description = item.name
                          vmItemDeal.SubTotal = item.price * item.quantity
                          vmItemDeal.ProductType = item.ProductType ? item.ProductType : ''
                          vmItemDeal.Total = item.Total ? item.Total : ''
                          lastItem.deals.push( vmItemDeal )
                      }
                      return 
                  }
                  let vmItem = {}
                  vmItem.product_description = item.product_description 
                  vmItem.deals = []
                  vmItem.product = {
                      name: item.name,
                      price: item.price,
                      product_id: item.product_id
                  }
                  vmItem.quantity = item.quantity
                  vmItem.deposit_amount = item.deposit_amount
                  lastItem = vmItem
                  vm.items.push( vmItem ) 
                  return null
              }
          } )
          vm.items = vm.items.reverse()
          this.updateOrderModal( vm ) 
          viewModels.push( vm )
          return null
      } )
      return viewModels
  }

   // Open modal
   handleModalOpen =  async( cart ) => {
    this.setState( { 
        details: cart,
        isOpen: true,
        isDetailPageOpen: true
    } )
    await delay( 400 )
    const userStatus = AppData.orderType( this.state.details.itemType,AppData.isStoreShowAndGo(),this.state.details.isOrderConfirmed )
    const orderId = this.state.details.itemType === 'order' ? this.state.details.order_id : ''
    Mixpanel.customerProfileViewed( checkLandscape(),userStatus,this.state.title,orderId )
    // this.getContent() 
    history.push( `/app/liveView/details/${cart._id}`, {
      cart
    } )
}

  // Order sorting used in getOrderConfirmed call
  sortOrders = ( orders ) => {
    return orders.sort( ( orderA , orderB ) => {
        if( AppData.isStoreShowAndGo() ){
            return ( this.isGreenOrder( orderA.order_id ) === this.isGreenOrder( orderB.order_id ) ) ? 0 : this.isGreenOrder( orderA.order_id )  ? -1 : 1
        }
        return ( orderA.is_order_confirmed === orderB.is_order_confirmed ) ? 0 : orderA.is_order_confirmed  ? -1 : 1
    } )
  }


// Allowing or Disallowing green card confirmation timing function - FUTURE USE
isGreenOrder = ( order_id ) => {
    return Date.now() - config.getShownOrders()[order_id] > ( AppData.quickPeekInterval ) ? false : true
}


// Changing state of modal during orderConfirmed
updateOrderModal = order => {
    if( this.state.isOpen && this.state.details._id === order._id ){
        this.setState( {
            details: order
        } )
    }
}

fetchNotificationCartId = () => {
  let pushNotification = window.Android && window.Android.pushCartNotification ? window.Android.pushCartNotification() : ''
  if( pushNotification  ){
      pushNotification = JSON.parse( pushNotification )
  }
  return pushNotification
}

//fetch notification cart model
showPushNotificationCartModel = ( loyaltyId ) => {
  // building mixpanel hardware parameters
  let alertType =  this.fetchNotificationCartId().type === 'shoppingAlert' ? 'Shopping' : 'NA'
  let deviceType = this.fetchNotificationCartId().type === 'shoppingAlert' ? 'NA' : 'Hardware'
  if( loyaltyId  ){
    // fetch notification cart from local storage
    let notificationCarts = config.getNotificationCarts()
    if ( notificationCarts === null ) {
          notificationCarts = {}
    }
    let previouTime = Date.now()
    network.getAllCarts( ( response ) => {
      let cartDetails = response && response.data && response.data.carts ? _.first( response.data.carts ) : []
      let cartDetailsWithdeals = getCartWithDeals( cartDetails , null )
      let newCartDetails = cartModal( cartDetailsWithdeals )
      newCartDetails.map( ( item ) => {
        if( loyaltyId === item.user_profile.loyalty_id   ){
          if( notificationCarts && notificationCarts[item._id] === undefined ){
            notificationCarts[item._id] = Date.now() 
            config.setNotificationCarts( notificationCarts )

            let newItem = Object.assign( {}, item )
            this.handleModalOpen( newItem )
            let finalTime = Date.now()
            AppAnalytics.notificationCartDuration( ( finalTime - previouTime ) / 1000 )
            AppAnalytics.apiSource( 'Notification Cart' ) 
            setTimeout( () => {
              Mixpanel.notificationCartOpened( this.state.screenOrientation, loyaltyId )
              Mixpanel.hardwareAlertTap( alertType,deviceType,this.state.title,loyaltyId,'Push' )
            }, AppData.mixpanelEventDelay ) 
            
          }    
        }
      return null
      } )
   },
    ( error ) => console.log( error ) )
  }
    return 
}

// Changing state of modal during items changes in cart
updateCartModal = cart => {
  if(cart.cartCleared){
    this.setState( {
      detailedCartCleared : true
  } )
  }
    if ( this.state.isOpen && this.state.details && this.state.details['itemType'] && this.state.details['itemType'] === 'cart' && this.state.details.customer_id === cart.customer_id ){
        this.setState( {
            details:cart
        } )
    }
}
// change feedback opened state w.r.t Menu page or Help page
changeFeedbackState = ( bool ) => {
  this.setState( { feedbackOpened: bool} ) }

// banner transformation
transformBanner = ( bool ) => {
  this.setState( { transformBanner : bool } )
}

  renderViews = () => {  
    return ( 
      <Fragment>
        {this.props.location.pathname === '/app'  ? <OnboardingVideo 
        screenOrientation={this.state.screenOrientation}
        queryParam={this.state.queryParam}
        transformBanner={this.state.transformBanner}/> : null}
        {this.props.location.pathname === '/app'? !store.getState().languageChange.isLoading && <Tabs
        historyOrderCount={this.state.historyOrderCount}
        allCartsAndOrders={this.state.allCartsAndOrders}
        helpMenuToggle={this.state.helpMenuToggle}
        transformBanner={this.state.transformBanner}
        /> : null}
        <Switch>
          <Route path="/app/liveView" render={() => <CartContainer 
              gridStyle={this.state.gridStyle}
              cardStyle={this.state.cardStyle}
              scroll={this.state.scrollTop}
              allTips={this.props.allTips}
              onLiveView={this.onLiveView}
              screenOrientation={this.state.screenOrientation}
              title={this.state.title}
              allCartsAndOrders={this.state.allCartsAndOrders}
              isDataAvailable={this.state.isDataAvailable}
              isLoadingPaidOrders={this.state.isLoadingPaidOrders}
              isOrderPaidOrConfirmed={this.state.isOrderPaidOrConfirmed}
              handleModalOpen={this.handleModalOpen}
              details={this.state.details}
              detailedCartCleared={this.state.detailedCartCleared}
              dataRefresh={this.dataRefresh}
              transformBanner={this.state.transformBanner}
              initializePolling={this.initializePolling}
              disablePolling={this.disablePolling}
              isOpen={this.state.isOpen}
            />} 
          />
          <Route path="/app/history" render={ () => <TransactionHistory
              detailPageHandler={this.cartContainerChild}
              activityStatus={this.props.activityStatus}
              modalUpdate={this.modalUpdate}
              navbarStateChange={this.navbarStateChange}
              navbarClicked={this.state.navbarClicked}
              isDetailOpen={this.state.isDetailPageOpen}
              onHistoryView={this.onHistoryView}
              screenOrientation={this.state.screenOrientation}
              title={this.state.title}
              getUnwantedName={this.getUnwantedName}
              transformBanner={this.state.transformBanner}
            />}
          />
           <Route path="/app/help" render={ () => <Help
              isDetailOpen={this.state.isDetailPageOpen}
              detailPageHandler={this.cartContainerChild}
              modalUpdate={this.modalUpdate}
              navbarStateChange={this.navbarStateChange}
              navbarClicked={this.state.navbarClicked}
              isDetailPageOpenFromApp={this.state.isDetailPageOpen}
              faqData={this.props.faqData}
              onFaqView={this.onFaqView}
              screenOrientation={this.state.screenOrientation}
              routeProps={this.props}
              faqMenuToggle={this.state.faqMenuToggle}
              troubleshootingMenuToggle={this.state.troubleshootingMenuToggle}
              feedbackOpened={this.state.feedbackOpened}
              changeFeedbackState={this.changeFeedbackState}
              guidelines={this.state.guidelines}
              incomingNotification={this.state.incomingNotification}
              singleGuideline={this.state.singleGuideline}
              filteredTitle={this.state.filteredTitle}
              transformBanner={this.transformBanner}
              bannerClicked={this.state.bannerClicked}
              bannerPressed={this.bannerPressed}
              guideSource={this.state.guideSource}
              guidelineSourceCheck={this.guidelineSourceCheck}
            />} 
          />
          <Route path="/app/reports" render={ () => <ReportContainer 
              insights={this.props.insightsContent}
              screenOrientation={this.state.screenOrientation}
            /> } 
          />
          <Route path="/app/feedback" render={ () =>  <Feedback 
              screenOrientation={this.state.screenOrientation}
              feedbackOpened={this.state.feedbackOpened} /> } />
          <Route path="/app/whatsNew" render={ () => <WhatsNew 
              screenOrientation={this.state.screenOrientation} /> } />
              { this.props.auth.isLoggedIn && <Redirect from='*' to='/app' /> }
          </Switch>
      </Fragment> 
    )

  }
  
  render() {
    const btnExt = document.getElementById( 'btn-ext' )
    btnExt && btnExt.addEventListener( 'click', () => {
      this.setState( { isVideoClicked: true } ) } )
    return ( 
        <div className="app-menu-container">
          { navigator.onLine === false || AppData.isError === true ? null : ( this.props.allTips !== undefined && !store.getState().languageChange.isLoading )  ? null : <HomeScreen/> }
          <Banner transformBanner={this.state.transformBanner}
                  bannerTransformed={this.transformBanner}
                  title={this.state.title}
                  bannerMessage={this.state.bannerMessage}
                  showBannerGuideline={this.showBannerGuideline}/> 
          <Navbar  
            isOpen={this.state.isDetailPageOpen}
            back={this.cartContainerChild}
            routeProps={this.props}
            onNavigationHandler={this.onNavigationHandler}
            getOrdersAndCartsCount={this.getOrdersAndCartsCount}
          />
          {navigator.onLine && this.state.isError === false ?  this.renderViews() : <Modal isOpen={this.state.isError}> <ErrorHandler/> </Modal> }
        </div>
    )
  }
}

const mapStateToProps =  state  => {

  //Checking confirmationFlow for new relic dashboard
  if( Object.keys( state.configReducer ).length > 0 ){
    let presentFlow = AppData.isStoreShowAndGo() ? 'showAndGo': 'scanAndGo'  
    AppAnalytics.confirmationFlowCheck( presentFlow )
  }

  return {
    activityStatus : state.configReducer.activityStatus,
    layoutStatus: state.configReducer.layoutStatus,                        
    allTips : state.configReducer.allTips,
    faqData : state.configReducer.faqs,
    insightsContent : state.configReducer.insightsContent,
    unwantedNames : state.configReducer.unwantedNames,
    auth: state.auth,
    pollingState: state.configReducer.pollingState,
    pollingInterval: state.configReducer.pollingInterval,
    amplifyState: state.configReducer.amplifyState
  }
}

export default  connect( mapStateToProps , { fetchConfig, userLogin, isSessionUpdated, trackLoginModal, videoEnabled, fetchStationIds,getStoreInfo,fetchTabs,setLoader,isLoggedIn } ) ( App )                    

App.propTypes = {
  activityStatus : PropTypes.object,
  layoutStatus: PropTypes.object,                     
  allTips : PropTypes.array,
  faqData : PropTypes.array,
  insightsContent : PropTypes.array,
  unwantedNames : PropTypes.array,
  fetchConfig : PropTypes.func, 
  location: PropTypes.shape( {
    pathname: PropTypes.string,
    search: PropTypes.string
  } ),
  userLogin : PropTypes.func,
  isSessionUpdated : PropTypes.func,
  trackLoginModal: PropTypes.func,
  auth:PropTypes.object,
  videoEnabled:PropTypes.func,
  pollingState:PropTypes.bool,
  pollingInterval:PropTypes.number,
  pushNotification:PropTypes.func,
  amplifyState: PropTypes.bool,
  fetchStationIds: PropTypes.func,
  getStoreInfo: PropTypes.func,
  fetchTabs: PropTypes.func,
  setLoader: PropTypes.func,
  isLoggedIn: PropTypes.func
}

