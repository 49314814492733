import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import history from '../history'

import '../style/Guidelines.scss'
export default function Guidelines( props  ) {

    const [name] =  useState( props.header )
    const [opacity, setOpacity] = useState( 'none' )

    // Routing GuideLineDetail component
    const onGuidelineClick = () => {
        if( name !== 'Tips for Speaker Placement' ){
            history.push( `/app/help/guides/steps/:${name}` )
            props.setTitleState( name )
        } else {
            history.push( `/app/help/guides/tips/:${name}` )
            props.setTitleState( name )
        }
     }

    return (
        <Fragment>
            <div className="guidelines-wrapper" onTouchStart={() => setOpacity( 0.5 )} onTouchEnd={() => { onGuidelineClick() ; setOpacity( 1 ) }}>
                <div className="guideline-header" style={{opacity:`${opacity}`}}>
                    {name}
                </div>
                <div className="guideline-chevron">
                </div>
            </div>
           
        </Fragment>
    )
}

Guidelines.propTypes = {
    header : PropTypes.string,
    setTitleState: PropTypes.func
}