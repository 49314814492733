import React from 'react'
import ReactDOM from 'react-dom'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'

import history from './history'
import './index.scss'
import 'typeface-roboto'
import Home from './components/Home'
import App from './components/App'
import store from '../src/store/store'
import * as serviceWorker from './registerServiceWorker'
import axios from 'axios'
import {  setHeader } from './common/Util'
import LanguageContextProvider from './context/LanguageContext'
import Logout from './components/Logout'
import UnAuthorize from './components/UnAuthorize'

// Request interceptor for API calls
axios.interceptors.request.use( ( request ) => {
  return request
} )

// Response interceptor for API calls
axios.interceptors.response.use( ( response ) => {
  return response
},
   async function ( error ) {
    const originalRequest = error.config
    if( error && error.response && error.response.status && error.response.status === 401 ){
        return setHeader( originalRequest )
    }
    return Promise.reject( error )
  } )



const routes = (
  <Provider store={store}>
    <Router history={history}>
      <LanguageContextProvider>
        <div>
          <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/app" component={App} />
              <Route path="/logout" component={Logout} />
              <Route path="/unauthorize" component={UnAuthorize} />
              <Redirect from='*' to='/app' />
          </Switch>
        </div>
      </LanguageContextProvider>
    </Router>
  </Provider>
)

ReactDOM.render( routes, document.getElementById( 'root' ) )
serviceWorker.unregister()