// Importing libraries 
import React , { useState , useRef , useEffect  } from 'react'
import PropTypes from 'prop-types'
import dompurify from 'dompurify'
import Highlighter from "react-highlight-words";

// Importing styles
import '../style/faqAccordion.scss'

export default function FAQAccordion( props ) {

    // Preventing XSS while using dangerouslySetInnerHTML
    const sanitizer = dompurify.sanitize 

    // Component States
    const [setActive , setActiveState ] = useState( false )
    const [setHeight , setHeightState ] = useState( '0px' )
    const [chevron , setChevron ] = useState( '' )
    const [setPadding , setPaddingState ] = useState( '' )
    const [opacity, setOpacity] = useState( 'none' )

    // Component refs
    const content = useRef( null )
    const accordionTitle = useRef( null )

    // Lifecycle hook 
    useEffect( ( ) => {
        setPaddingState( accordionTitle.current.scrollHeight >= 28 ? '10px' : '8px' )
        if( props.isOpen !== setActive ){
            setHeightState( !props.isOpen ? '0px' : `${content.current.scrollHeight}px` )
            setChevron( !props.isOpen  ? 'rotate-up' : 'rotate-down' )
            setActiveState( !props.isOpen ? false : true )
        }
    } ,[setActive , props] )

    // Toggling function
    const toggleAccordion = () => {
        props.toggleFAQHandler( props.id )
        setActiveState( props.isOpen ? true : false )
        setHeightState( setActive === true ? '0px' : `${content.current.scrollHeight}px` )
        setChevron( setActive === true ? 'rotate-up' : 'rotate-down' )
    }

    //reset opacity
    const resetOpacity = () => {
        setOpacity( 0.5 )
        setTimeout( () => {
            setOpacity( 1 ) 
        },100 )
    }

    const faqId = Math.random().toString( 36 ).slice( 2 )
    const scrollToView = () => {
        let selectedElement = window.document.getElementById( faqId )
        selectedElement.parentNode.scrollTop = selectedElement.offsetTop - selectedElement.parentNode.offsetTop
    }

    return (
        <div id={faqId} onClick={scrollToView}>
            <div className="accordion-section">
                <div className="accordion" onClick={toggleAccordion} ref={accordionTitle} style={{paddingBottom : `${setPadding}` }}>
                    <div className="accordion-title" onTouchStart={() => resetOpacity()} style={{opacity:`${opacity}`}}>
                    <Highlighter
                        highlightClassName="highlight"
                        searchWords={props.highlightWords}
                        autoEscape={true}
                        textToHighlight={props.question}
                    />
                    </div>
                    <div className={`chevron-down ${chevron}`}>
                    </div>
                </div>
                <div className="accordion-content" ref={content} style={{maxHeight:`${setHeight}`}}>
                    <div className="accordion-text"
                        dangerouslySetInnerHTML = {{__html: sanitizer( props.answer ) }}
                    >
                    </div>
                </div>
            </div>
        </div>
    )
}

FAQAccordion.propTypes = {
    question : PropTypes.string,
    answer : PropTypes.string,
    id:PropTypes.number,
    isOpen: PropTypes.bool,
    toggleFAQHandler : PropTypes.func
}
