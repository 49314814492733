import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import '../style/Trends.scss'
import TrendContent from './TrendContent'
import { getUpdatedTime } from '../common/Util'
import { LanguageContext } from '../context/LanguageContext'

export default function Trends( { reportCount,  responseDateTime } ) {
    const { strings } = useContext( LanguageContext )

    const renderDayAndTime = ( reportDateTime ) => {
        let currentDate = new Date( reportDateTime )
        let reportDate = `${currentDate.getMonth() + 1}/${currentDate.getDate()}, `
        let responseDate = new Date( responseDateTime )

        responseDate = responseDate.toLocaleTimeString()
        let timeFormat = responseDate.split( ':' ).slice( 0, 2 ).join( ':' ) + responseDate.split( ':' ).slice( 2 ).join( ' ' ).split( ' ' ).pop()
        let updateTime = `${reportDate} 12am-${timeFormat.toLowerCase()}`
        return updateTime
    }
    const renderPastDate = ( pastDate ) => {
        let newPastDate = new Date( pastDate )
        let newCurrentDate = new Date( responseDateTime )
        newCurrentDate.setDate( newCurrentDate.getDate() - 1 )
        let newDate = `${newPastDate.getMonth() + 1}/${newPastDate.getDate()}-${newCurrentDate.getMonth() + 1}/${newCurrentDate.getDate()}`
        return newDate
    }

    return (
        <div className="trends-wrapper">
            <div className="trends-header">
                {strings.mcoTransactionsText}
            </div>
            <div className="bottom-label" style={{marginBottom:'4px'}}>updated {getUpdatedTime( responseDateTime )}</div>
            <div className="trends-content-container">
                <TrendContent
                    iconNeeded
                    singleDayOrder
                    title={strings.graphTodayText}
                    count={reportCount.today.thisWeek.count}
                    rateOfChange={reportCount.today.thisWeek.rateOfChange}
                    bottomLabel={strings.bottomLabel}
                    reportDate={renderDayAndTime( reportCount.today.thisWeek.date  ) }
                />
                <TrendContent
                    singleDayOrder
                    title={strings.graphLastWeekText}
                    count={reportCount.today.lastWeekCount.lastWeekFromTodayCurrentTimeCount}
                    reportDate={renderDayAndTime( reportCount.today.lastWeekCount.sameDayLastWeekDate ) }
                />
                <TrendContent
                    title={strings.graphSevenText}
                    reportDate={renderPastDate( reportCount.past7days && reportCount.past7days.startDate )}
                    count={reportCount.past7days && reportCount.past7days.count}
                    aspdCount={reportCount.past7days && reportCount.past7days.apsd}
                />
                <TrendContent
                    title={strings.graphThirtyText}
                    reportDate={renderPastDate( reportCount.past30days && reportCount.past30days.startDate )}
                    count={reportCount.past30days && reportCount.past30days.count}
                    aspdCount={reportCount.past30days && reportCount.past30days.apsd}
                />
            </div>
        </div>
    )
}

Trends.propTypes = {
    reportCount: PropTypes.object,
    marketContent : PropTypes.array,
    responseDateTime: PropTypes.string
}
