import React , {Fragment, useContext }from 'react'
import AppData from '../common/AppData'
import { errors } from '../common/ErrorConfig'
import '../style/ErrorHandler.scss'
import AppAnalytics from '../common/AppAnalytics'
import { LanguageContext } from '../context/LanguageContext'

function ErrorHandler () {
    const { strings } = useContext( LanguageContext )
    const error = errors()

    const reload = () => {
        AppData.errorCount++
        //history.push( '/' )
        AppAnalytics.pressRetryButton()
    }

    const retry = () => {
        window.location.reload()
    }

    const renderFirstError = () => {
        return (
            <Fragment>
                <div className="refresh">
                    <button type="button" className="button-1" onClick={reload}>{strings.retryText}</button>
                </div>
                <div className="error-description">
                    <div className="error-text">
                        {error && error[AppData.errorMessage] ? error[AppData.errorMessage].description.text : ''}
                    </div>
                    <div className="error-phone">
                        {error && error[AppData.errorMessage] ? error[AppData.errorMessage].description.phone : ''}
                    </div>
                </div> 
            </Fragment>
        )
    }
    const renderSecondError = () => {
        return (
            <Fragment>
                <div className="error-description">
                    <div className="error-text">
                        <div>
                            {error ? error['NOT_ABLE_TO_CONNECT']: ''}
                        </div>
                        <div>
                            {error && error[AppData.errorMessage] ? error[AppData.errorMessage].description.text : ''}
                        </div>
                    </div>
                    <div className="error-phone">
                        {error && error[AppData.errorMessage] ? error[AppData.errorMessage].description.phone : ''}
                    </div>
                </div>
                <div className="refresh">
                    <button type="button" className="button-2" onClick={reload}>{strings.retryText}</button>
                </div>
            </Fragment>
        )
    }

    const renderStoreSetUp = () => {
        return (
            <Fragment>    
                <div className = "refresh offline-error-container">
                    <div className = 'offline-error-message'>{strings.connectToInternetMessage}</div>
                    <button className='button-1 offline-retry-button' type="button" onClick={retry}>{strings.retryText}</button>
                </div>
            </Fragment>
        )
    }

    return(
        <div className="error-container">
            <div className="error-content">
                <div className="error-content-top">
                    {AppData.errorCount  === 0 ? 
                        <div className="error-title">{strings.informationText}</div> 
                        : 
                        <div className="error-title">{error && error[AppData.errorMessage] ? error[AppData.errorMessage].title : ''}</div>
                    }
                    <div className="error-icon-wrapper">
                        <div className="error-icon"></div>
                    </div>
                </div>
                { AppData.errorCount  === 0 ? renderStoreSetUp() : AppData.errorCount  === 1? renderFirstError() 
                :renderSecondError()}
            </div>
        </div>
    )
}

export default ErrorHandler