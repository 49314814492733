import React, { Component, Suspense } from 'react'
import PropTypes from 'prop-types'
import Mixpanel from '../common/Mixpanel'

import '../style/ReportContainer.scss'

import Insights from './Insights'
import Trends from './Trends'
import Loader from './Loader'
import { connect } from 'react-redux'
import { getReports } from '../actions/reportActions'

const MCOLazyScanRate = React.lazy( () => import( './MCOScanRate' ) )


class ReportContainer extends Component {  
    constructor( props ){
        super( props )
        this.state = {
            isLoading : false,
            reportData : {},
            responseDateTime: ''
        }
    }

    componentDidMount(){
        Mixpanel.onReportView( this.props.screenOrientation )
        Mixpanel.onCurrentDayReportView( this.props.screenOrientation )
        this.getReportContent()
    }

    statesChange = ( response ) => {
        this.setState( {
            reportData : response,
            isLoading: true,
            responseDateTime : new Date().toISOString()
        } )
    }

    loadingChange = () => {
        this.setState( {
            isLoading: false
        } )
    }
    
    getReportContent = () => {
        let currentDate = Date.now()
        let yesterdayDate =  new Date().setDate( new Date().getDate() - 1 ) 
        let query = {
            'created': { 
                'today': currentDate , 'yesterday': yesterdayDate
            }
        }
        this.props.getReports( query, this.statesChange, this.loadingChange )
    }  

    componentWillUnmount(){
        Mixpanel.exitReportView ( this.props.screenOrientation )
    }
    render() {
        const { isLoading, responseDateTime, reportData } = this.state
        return (
            <div className="report-container">
                {!isLoading ? <div className="loader-postion"><Loader/></div> :
                    <div className="report-wrapper">
                        <div className="trends-container">
                            <Trends reportCount={reportData.orders} responseDateTime={responseDateTime} />
                        </div>
                        <div className="mco-scan-rate-container">
                            <Suspense fallback={<div><Loader/></div>}>
                                <MCOLazyScanRate reportContent={reportData.orders} responseDateTime={responseDateTime} screenOrientation={ this.props.screenOrientation }/>
                            </Suspense>
                        </div>
                        <div className="insights-container">
                            <Insights insightContent={this.props.insights}/>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        marketContent : state.configReducer.marketContent
    }
}

export default  connect( mapStateToProps, { getReports } )( ReportContainer )

ReportContainer.propTypes = {
    insights: PropTypes.array,
    screenOrientation: PropTypes.string,
    marketContent : PropTypes.array,
    getReports: PropTypes.func
}
