
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import _ from 'underscore'

import VideoContainer from './VideoContainer'
import ModalDetails from './ModalDetails'
import FAQContainer from './FAQContainer'

import history from '../history'
import AppData from '../common/AppData'
import { delay } from '../common/Util'

import '../style/Faq.scss'
import Mixpanel from '../common/Mixpanel'
import { navbarConfirmedObject } from '../common/Util'


export default class Faq extends Component {

    constructor( props ){
        super( props )
        this.state = {
            details: {},
            isOpen: false,
            play: false
        }
    }

    componentDidMount(){
        this.props.onFaqView()
    }

    componentWillUnmount(){
        Mixpanel.exitFaqView( this.props.screenOrientation )
    }
    
    // for opening notification
    handleModalOpen = async ( order ) => {
        let detailOrder = navbarConfirmedObject( order )
        this.setState( { 
            details: detailOrder,
            isOpen:true
        } )       
        await delay( 400 )
        this.props.detailPageHandler( this.state.isOpen , order )
        history.push( `/app/help/faq/details/:${order.arbitrationToken}` ) 
     }

    // fetching notification data
    userData = () => {
        let data = _.last( AppData.getAllNotifications() )
        return data
      }
    // checking showAndGo stores
    isShowAndGo = () => {
        return AppData.isStoreShowAndGo()
    }

    // shows only paid notification for showAndGo and only confirmed notification for scanAndGo
    notificationShownCheck = () => {
        let showAndGoNotificationCheck = ( this.isShowAndGo() || ( !this.isShowAndGo() && this.userData().is_order_confirmed ) ) ? true : false
        return showAndGoNotificationCheck
    }

    // runs after notification click
     onConfirmationClick = () => {
        let details =  _.last( AppData.getAllNotifications() )
        this.handleModalOpen( details )
    }
    
    // play state
    onVideoPlay = ( bool ) => {
        this.setState ( { play: bool } )
    }

    componentDidUpdate( prevProps ){
        if( prevProps.navbarClicked !== this.props.navbarClicked ){
            // opening notification in history navbar
            if( this.props.isDetailOpen ){
                this.setState( { details: this.props.modalUpdate() } )
            } 
          // opening notification in detail after it was opened from history notification
            else {   
                this.onConfirmationClick()
            }
        }

    }

    render() {
        return (
            <Fragment>
                <div className= 'faq-container'>
                    <div className='video-container'>
                        <VideoContainer onVideoPlay={this.onVideoPlay} screenOrientation={this.props.screenOrientation}/> 
                    </div> 
                    { !this.state.play ?
                    <div className='faq-content-container'>
                        <FAQContainer faqData={this.props.faqData} screenOrientation={this.props.screenOrientation}/>
                    </div> : null }
                </div>
                <Route path="/app/help/faq/details/:id" component={()=> <div className="modal-container">{this.state.isOpen ? <ModalDetails cart={this.state.details}/>:null }</div>} />         
            </Fragment>
        )
    }
}

Faq.propTypes = {
    detailPageHandler : PropTypes.func,
    isDetailOpen : PropTypes.bool,
    modalUpdate: PropTypes.func,
    navbarStateChange: PropTypes.func,
    navbarClicked:PropTypes.bool,
    faqData : PropTypes.array,
    screenOrientation: PropTypes.string,
    onFaqView: PropTypes.func
}
