import React from 'react'
import { authClient } from '../common/helper'
import { strings } from '../context/LanguageContext/strings'
import '../style/UnAuthorize.scss'

const UnAuthorize = () =>{
    const onLogoutClick = () =>{
        const localStorageIdToken = localStorage.getItem( 'id_token' )
        localStorage.clear()
        authClient.logout( localStorageIdToken )
    }
return (
    <div className='unauthorize-container'>
        <h2> {strings.inValidLoginMessage} </h2>
        <button className='btn-login' onClick={ onLogoutClick }  >{strings.logOut}</button>
    </div>
)
}
export default UnAuthorize