import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import AppData from '../common/AppData'
import UserName from './UserName'
import '../style/ModalDetails.scss'
import config from '../common/config'
import { LanguageContext } from '../context/LanguageContext'
import history from '../history'

export default function ModalDetails( props ) {
    const { strings } = useContext( LanguageContext )
    const cart = props.cart
    if (
      (!props.isOrderPaidOrConfirmed || !props.cart?.orderStatus) &&
      (props.cartCleared || cart.length === 0)
    ) {
      props.isOpen && history.push("/");
    }
    let grandTotal = ( cart.grandTotal / 100 ).toFixed( 2 ).toString()
    const getDate = () => {
        let date = new Date( cart.updated_time )
        date = ( date.getMonth() + 1 ) + '/' + date.getDate() + '/' + date.getFullYear() 
        return date
    }

    const getTime = () => {
        let date = new Date( cart.updated_time )
        let hours = date.getHours()
        let minutes = date.getMinutes()
        let ampm = hours >= 12 ? 'PM' : 'AM'
        hours = hours % 12
        hours = hours ? hours : 12 
        minutes = minutes < 10 ? '0' + minutes : minutes
        let time = hours + ':' + minutes + ampm 
        return time
    }

    //Rendering PromoDetails
    const getPromoDesc = ( deals ) => {
        if( deals === undefined || ( deals && deals.length === 0 ) ) return null
        if( deals !== null ){
            return deals.map( ( deal,index ) => {
                  if( deal.ProductType !== 62 ){
                    return (
                        <div className="promo-desc" key={index}>
                            {deal.Description}
                        </div>
                    )
                  } 
                  return null    
            } )
        }

    }
    const getPromoAmount= ( deals ) => {
        if( deals === undefined || ( deals && deals.length === 0 ) ) return null
        if( deals !== null ){
           return deals.map( ( deal,index )=>{
                if( deal.ProductType !== 62 ){
                return (
                    <div className="promo" key={index}>
                        {( deal.SubTotal !== undefined )?`-$${( deal.SubTotal/100 ).toFixed( 2 ).slice( 1 )}`: ''}
                    </div>
                )
                } 
                 return null             
            } )
        }
    }

    //Rendering Products
    const renderProductList = () => {
        return cart.items.map( ( item,index )=>{
            return(
                <div className="item-desc" key={index}>
                    <div className="qty">{item.quantity}</div>
                    <div className="desc">
                        <div className="only-product">
                            <div className="product-name">{ item.product_description !== undefined ? item.product_description : ( item.product !== undefined ) ?  item.product.name : item.product_id}</div>
                            <div className="price">{item.product !== undefined ? '$'+ totalPrice( item ) : 'N/A'}</div>
                        </div>
                        <div className="only-promo">
                            <div className="promo-deals">
                                {getPromoDesc( item.deals )}
                                <div className="promo-price">
                                    {getPromoAmount( item.deals )}
                                </div>
                            </div>
                        </div>
                        <div className="bottle-deposit">
                            {getBottleDeposit( item ) }
                        </div>
                        <div className="eco-fee">
                            {getEcoFee( item ) }
                        </div>                    
                    </div>
                </div>
            )
        } )
    }
    
    //Calculating total quantities in the cart
    const totalQuantity = () => {
        let total = cart.items
            .map( item => {
            return item.quantity
            } )
            .reduce( ( previouVal, currentVal ) => {
            return previouVal + currentVal
            },0 )
        return total
        }

    const totalPrice = ( item ) => {
        let result = ( item.product.price && item.quantity ) ? item.product.price * item.quantity : 0
        return ( result/100 ).toFixed( 2 )
    }
    //Calculating total amount for the items
    const totalAmount = () => {
        let total = config.localData().storeCountry === 'US' ? `$${grandTotal}` : `CA$${grandTotal}`
        return (
            <div className="total-price">
                <div className="amount">{total}</div>
            </div>
        )
    }

    //redacting MemebrID 
    const redact = ( str ) =>{
        let newStr = str.replace( AppData.redactExp, '•' )
        return newStr
    }
    const confirmIcon = () => {
        let confirm = ( cart.itemType === 'order' && cart.transaction_icon && cart.isOrderConfirmed && !cart.showAndGoIconCheck ) ? true : false
        return confirm
    }

    const getBottleDeposit = ( item ) => {
        if( item.deposit_amount && ( item.deposit_amount !== null || item.deposit_amount !== 0  ) ){
            let Total = item.deposit_amount
            let quant = item.quantity
            return(
                <React.Fragment>
                    <div className="bottle-text">
                        {strings.bottleDepositText}
                    </div>
                    <div className="bottle-price">
                        {'$'+ ( Total*quant/100 ).toFixed( 2 ) }
                    </div>
                </React.Fragment>
            )
        }
        if( item.deposit === undefined || ( item.deposit && item.deposit.length === 0 ) ) return null
        if( item.deposit !== null ){ 
            return item.deposit.map( ( depositItem,index ) => {
                    return (
                        <React.Fragment key={index}>
                                <div className="bottle-text">
                                    {strings.bottleDepositText}
                                </div>
                                <div className="bottle-price">
                                    {'$'+ ( depositItem.Total/100 ).toFixed( 2 ) }
                                </div>
                        </React.Fragment>
                    )   
            } )
        }
        
    }

    const getEcoFee = ( item ) => {
        if( item.deals && !item.eco ){
            return item.deals.map( ( dealItem, index ) => {
                if( dealItem.ProductType === 62 ){
                    return (
                        <React.Fragment key={index}> 
                                <div className="eco-text">
                                    {strings.ecoFeeText}
                                </div>
                                <div className="eco-price">
                                    {'$'+ ( dealItem.SubTotal/100 ).toFixed( 2 )}
                                </div>
                            </React.Fragment>
                    )
                }
                return null
            } )
        }
        if( item.eco === undefined || ( item.eco && item.eco.length === 0 ) ) return null
        if( item.eco !== null ){
            return item.eco.map( ( ecoItem,index ) => {
                    return (
                        <React.Fragment key={index}>
                                <div className="eco-text">
                                    {strings.ecoFeeText}
                                </div>
                                <div className="eco-price">
                                    {'$'+ ( ecoItem.Total/100 ).toFixed( 2 ) }
                                </div>
                        </React.Fragment>
                    )   
            } )
        }
    }

    return (
        <div className='modal'>
            <div className= 'modal-box'>
                <div className='modal-upper'>
                    <div className='date-time-store-wrapper'>
                        <div className="date">{getDate()}</div>     
                        {cart.itemType === 'order'? 
                        <div className="time">{getTime()}</div> : 
                        <div className='hidden-time'></div> } 
                        <div className="store">{strings.storeText} #{config.storeId()}</div>
                    </div>
                    <div className='member-icon-details'>
                        <div className='member-details'>
                            <div className="modal-user-name">
                                { cart.user_profile.first_name  === 'UNKNOWN'  || cart.user_profile.first_name === ''   ? null : <UserName userProfile={cart.user_profile} />}
                            </div> 
                            {cart.user_profile?
                            <div className="modal-member-details" >
                                <div className="fields">
                                    <span className="field">Member ID </span>
                                    <span className="value">{redact( cart.user_profile.loyalty_id )}</span>
                                </div> 
                            </div>:null}
                        </div>
                        <div className='confirm-user-icon'>
                            <div className={ cart.itemType === 'order' ? confirmIcon() ? 'confirm-logo' : 'paid-logo' : 'cart-logo' }></div>
                            {cart.itemType === 'order' ? confirmIcon() ? 
                            <div className='confirm-text'>{strings.confirmedText}</div> :
                            <div className='paid-text'>{strings.paidText}</div> :
                            <div className='shopping-text'>{strings.shoppingText}</div> }
                        </div>
                    </div>
                </div>
                <div className='modal-lower'>
                    <div className="total-tags-wrapper">
                        <div className="total-items-container">
                            <div className="total-items">{totalQuantity()}</div>
                            <div className="item-tag">{strings.itemText}</div>
                        </div>
                        <div className={config.localData().storeCountry === 'US' ? 'total-amount-container' : 'canada-total-amount-container'}>
                            <div className={config.localData().storeCountry === 'US' ? 'price-tag' : 'canada-price-tag'}>{strings.priceTotalText}</div>
                                {totalAmount()}
                        </div>
                    </div>
                    <div className="items">
                        {renderProductList()}
                    </div>
                </div>
            </div>
        </div>
    )
}
ModalDetails.propTypes = {
    cart: PropTypes.object,
    handleModalClose: PropTypes.func,
    isShowAndGo: PropTypes.func,
    isOpen: PropTypes.bool,
    isOrderPaidOrConfirmed: PropTypes.bool,
    cartCleared: PropTypes.bool,
    allCartsAndOrders: PropTypes.array
}

