import React, { PureComponent, Fragment } from 'react'
import { Route } from 'react-router-dom'
import Loader from './Loader'
import PropTypes from 'prop-types'
import history from '../history'
import get from 'lodash/get'
import uuid from 'uuid'

import '../style/GuideContainer.scss'
import Mixpanel from '../common/Mixpanel'
import '../style/feedback.scss'
import Terms from './Terms'
import FeedbackModal from './FeedbackModal'
import { connect } from 'react-redux'
import { userId } from '../actions/authActions'
import { createFeedback } from '../actions/feedbackActions'
import { Prompt } from 'react-router-dom'
import { strings } from '../context/LanguageContext/strings'

class Feedback extends PureComponent {
    constructor( props ){
        super( props )
        this.state = {
            titleState: '',
            feedbackData : '',
            rating: null,
            hover: null,
            reportData : {},
            responseDateTime: '',
            isFeedbackSubmitted: false,
            isCancelClicked: false,
            isOpen: false,
            isTextareaClicked: false,
            submitFeedback: false,
            stars: [],
            selectedIcon: '★',
            deselectedIcon: '☆',
            isButtonDisabled: false,
            isLoading: false,
            feedbackOpened: props.feedbackOpened
        }
        for ( var i = 0; i < 5; i++ ) {
          this.state.stars.push( i + 1 )
        }
    }

    changeRating( newRating ) {
        this.setState( {
          rating: newRating,
        } )
      }

    componentDidMount(){
        setTimeout( () => {
            let feedbackOpenedFrom = this.state.feedbackOpened ? 'Help Page' : 'Menu Page'
            Mixpanel.feedbackOpened( this.props.auth.userId, feedbackOpenedFrom )
        },2000 )
    }

    discardFeedback = () => {
        this.setState( { rating: null, isOpen: false, submitFeedback: false } )
        history.goBack()
    }

    stateChange = () => {
        this.setState( { isFeedbackSubmitted: true, isOpen: false, submitFeedback: true, rating: null, isLoading: false } )
    }

    loadingChange = () => {
        this.setState( { isLoading: true } )
    }


    createFeedback = () => {
        this.setState( { isButtonDisabled: true, isLoading: true } )
        let query = {
            'rating': get( this, 'state.rating', 0 ),
            'user_id': '0000-0800',
            'device_id': '0000-0800-000000000',
            'message': get( this, 'state.feedbackData', '' ),
            'created': 1602775544000
        }
        
        this.props.createFeedback( query, this.stateChange, this.loadingChange )
        let feedbackSubmittedFrom = this.state.feedbackOpened ? 'Help Page' : 'Menu Page'
        Mixpanel.feedbackSubmitted( this.state.rating,this.state.feedbackData,this.props.auth.userId,feedbackSubmittedFrom )
        }  

    componentWillUnmount(){
        let feedbackClosedFrom = this.state.feedbackOpened ? 'Help Page' : 'Menu Page'
        Mixpanel.feedbackClosed( this.props.auth.userId, feedbackClosedFrom )
    }
        handleClick = ( ratingValue ) => {
            this.setState( { rating: ratingValue} )
        }
        
        handleChange = ( e ) => {
            this.setState( { feedbackData: e.target.value } )
        }
        onTextAreaClick = () => {
            this.setState( { isTextareaClicked: true } )
            let limit = 10
            let textarea = document.getElementById( 'txt-area' )
            let spaces = textarea && textarea.getAttribute( 'cols' )

            if( textarea ) {
                textarea.onkeyup = function() {
                let lines = textarea.value.split( '\n' )
        
                for ( let i = 0; i < lines.length; i++ ) 
                {
                    if ( lines[i].length <= spaces ) continue
                    let j = 0
                    let space = spaces
                    while ( j++ <= spaces ) 
                    {
                    if ( lines[i].charAt( j ) === ' ' ) space = j  
                    }
                    lines[i + 1] = lines[i].substring( space + 1 ) + ( lines[i + 1] || '' )
                    lines[i] = lines[i].substring( 0, space )
                }
                    if( lines.length>limit )
                    {
                        textarea.style.color = 'gray'
                        setTimeout( function(){
                            textarea.style.color = ''
                        },500 )
                    }    
                textarea.value = lines.slice( 0, limit ).join( '\n' )
                }
            }
        }
        
        handleCancel = () => {
            this.setState( { rating: null, isOpen: false, submitFeedback: false } )
            history.goBack()

        }
        
        learnMore = () => {
            history.push( '/app/help/feedback/terms' )
        }

        handleBlockedNavigation = () => {
            this.setState( { isOpen: true } )
            return false
        };
        
        render() {
            const { feedbackData, rating, isFeedbackSubmitted, isOpen, 
                isTextareaClicked, stars, deselectedIcon, selectedIcon,isButtonDisabled, isLoading
            } = this.state
            const isGreen = isTextareaClicked ? 'green-outline' : ''
            const isLandscape = this.props.screenOrientation === 'Landscape' ? 'landscape' : ''
            const isWrapper = this.props.screenOrientation === 'Landscape' ? 'form-wrapper' : ''
            const isDisabled = isButtonDisabled ? 'disabled' : ''
            return (
                <Fragment>
                    <Prompt
                    when={this.state.rating}
                    message= {this.handleBlockedNavigation}
                    />
                <> 
                <Route path="/app/help/feedback/terms" render={ () => <Terms screenOrientation={this.props.screenOrientation} /> } />
                <div className={`feedback-container ${isLandscape}`}>
                    {
                    isLoading ? 
                        <div className="loader-postion"> <Loader/> </div> :
                        !isFeedbackSubmitted ?
                        <div className="rating_container">
                             { isTextareaClicked || rating > 0 ? null : 
                            <div className='learn_more'>
                                <p>{strings.learnMoreText1}</p>
                                <div className='learn-more' onClick={() => this.learnMore()}>{strings.learnMoreText2}</div>
                            </div>}
                            {
                                <>
                                {
                                    isTextareaClicked ? null :
                                    <div className="rate-txt-container">
                                        <h1 className="lvt_txt_1">{strings.rateText1} <br /> {strings.showAndGoText}</h1>
                                        <div className="lvt_txt_2">{strings.rateText2}</div>
                                    </div>
                                }
                                <div>
                                    <div className="rating-container">
                                        {stars.map( ( star ) => {
                                        return (
                                            <span
                                            key={uuid.v4()}
                                            className="star-rating"
                                            onClick={() => { this.changeRating( star ) }}>
                                            { rating < star ? deselectedIcon : selectedIcon }
                                            </span>
                                        )
                                        } )}
                                    </div>
                                </div>
                                {
                                this.state.rating !== null ?
                                <div className={isWrapper}>
                                    <form>
                                        <div>
                                            <textarea id="txt-area"
                                            className={isGreen}
                                            placeholder="Help improve this app! Share feedback."
                                            onChange={e => this.handleChange( e )}
                                            onClick={ () => this.onTextAreaClick()}
                                            value={feedbackData}
                                            cols="36" 
                                            />
                                        </div>
                                    </form>
                                    <button className={`submit_fb ${isDisabled}`}
                                    onClick={ ( e ) => this.createFeedback( e )}
                                    disabled={isButtonDisabled}> {strings.submitText} </button><br />
                                    <div>
                                        <button className="cancel_fb" onClick={ () => this.handleCancel() }>{strings.cancelText}</button>
                                       <FeedbackModal 
                                        isOpen={isOpen}
                                        onClose={() => this.setState( { isOpen: false } )}
                                        createFeedback={this.state.isOpen && this.createFeedback}
                                        discardFeedback={this.state.isOpen && this.discardFeedback}
                                        />
                                    </div>
                                </div> : null
                                }
                            </>
                            }
                        </div> : <div className='submit-container'> <h3 className="fb_txt_1"> {strings.feedbackText1} </h3> <br /> <div className="fb_txt_2">{strings.feedbackText2}</div> </div>
                    }
                </div> </>
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth : state.auth
    }
}
export default connect( mapStateToProps, { userId, createFeedback } ) ( Feedback )

Feedback.propTypes = {
    screenOrientation: PropTypes.string,
    auth: PropTypes.object,
    feedbackOpened: PropTypes.bool,
    createFeedback: PropTypes.func
}

