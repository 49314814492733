import React, { Component } from 'react'
import logoutIcon from '../assets/logout.svg'
import { connect } from 'react-redux'
import '../style/OnboardingVideo.scss'
import AppData from '../common/AppData'
// import showAndGoVideo from '../assets/MCO_Store_App_Training-720p-210518.mp4'
// import scanAndGoVideo from '../assets/MCO_Store_App_Training-720p-210518.mp4'
import { userLogin, loginNeeded, videoEnabled, isLoggedIn } from '../actions/authActions'
import PropTypes from 'prop-types'
import Mixpanel from '../common/Mixpanel'
import history from '../history'
import { LanguageContext } from '../context/LanguageContext'
import { authClient } from '../common/helper'
import config from '../common/config'

class OnboardingVideo extends Component {
    static contextType = LanguageContext
    constructor( props ){
        super( props )
        this.state = {
            play: false,
            totalDuration: '',
            opacity: 1,
            loginRequested: false,
            showAccountInfo:false
        }
    }

    // fetching actual video total duration on component mount
    componentDidMount = () => {
        let localStorageRefreshToken = localStorage.getItem( "refresh_token" )

        let localStorageIdToken = localStorage.getItem( 'id_token' )
        let storeId = config.storeId()
        if(!storeId){
            authClient.logout( localStorageIdToken )
            localStorage.clear()
            return
        }
        if( !this.props.auth.isLoggedIn && !localStorageRefreshToken ){
            history.push( '/' )
        }
        else if( !this.props.auth.isLoggedIn ){
            this.getRefreshToken()
        }
        // else{
        //     this.videoDurationOnLoad()
        // }
    }

    getRefreshToken = async () =>{
        let localStorageRefreshToken = localStorage.getItem( "refresh_token" )
        let refreshToken =  await authClient.getRefreshToken( localStorageRefreshToken )
        refreshToken()
            .then( res => {
                localStorage.setItem( 'refresh_token',res.data.refresh_token )
                this.props.isLoggedIn( true )
            } )
            .catch( err => {
                console.log( 'promise rejected',err )  
                history.push( '/' )
            } )
    }

    onLogoutClick = () =>{
        const localStorageIdToken = localStorage.getItem( 'id_token' )
        localStorage.clear()
        authClient.logout( localStorageIdToken )
    }

    // video is played
    play = () => {
        this.props.loginNeeded()
        this.setState( { loginRequested: true } )
        Mixpanel.lvtVideoViewed( this.props.screenOrientation )
    }

    // vide play function after check
    playVideoWithoutCheck = () => {
        let trailer = document.querySelector( '.video-trailer' )
        let video = document.querySelector( 'video' )
        trailer.classList.remove( 'deactive' )
        trailer.classList.add( 'active' )
        this.setState( { play: true } )
        video.play()
        video.currentTime = 0
    }

    // fetching total video time from actual video attached
    videoDurationOnLoad = () => {
        let vid = document.querySelector( '.video' )
        vid.onloadedmetadata = () => {
            let duration = vid.duration
            let minutes =  Math.floor( duration / 60 ) 
            let seconds = Math.floor( duration - minutes * 60 )
            let time = minutes + ':' + seconds
            this.setState( { totalDuration: time } )
        }    
    }
    
    // video is paused
    pause = () => {
        let trailer = document.querySelector( '.video-trailer' )
        let video = document.querySelector( 'video' )
        trailer.classList.remove( 'active' )
        trailer.classList.add( 'deactive' )
        this.setState( { play: false } )
        video.pause()
        video.currentTime = 0
        Mixpanel.lvtVideoClosed( this.props.screenOrientation )
    }

    // checking showAndGo stores
    isShowAndGo = () => {
        return AppData.isStoreShowAndGo()
    }

    // set Opacity for button 
    setOpacity = () => {
        this.setState( { opacity: 0.5 } )
        setTimeout( ()=>{
            this.setState( { opacity: 1 } )
        }, 100 )
    }

    componentDidUpdate( prevProps, prevState ){
        if(  this.state.loginRequested ){
            this.setState( { loginRequested : false} )
            if( this.props.auth.isLoggedIn ){
                // this.playVideoWithoutCheck()
            }
        }
    }

    render() {
        const user = this.context
        const {strings,rtl} = user
        let {showAccountInfo} = this.state
        
        const email = localStorage.getItem( "emailId" )
        return (
            <>  
                
                <div className={this.props.transformBanner ? 'push-onboarding-container' : 'onboarding-container'}>
                    {/* <div className='text'>{strings.newUser}</div>
                    <div className='button-external' style={{opacity:`${this.state.opacity}`}} onTouchStart={this.setOpacity} onClick={this.play}> 
                        <div id="btn-ext" className="button-internal"></div>
                    </div> */}
                    <div className='button-external-logout'>
                        {email && <span className='btn' onClick = {()=>this.setState( {showAccountInfo:!showAccountInfo} )}>{email.substring( 0,3 )}</span>}
                    </div>
                    {
                        showAccountInfo &&(
                            <div className={`account_outer ${rtl ? 'rtl' : 'ltr'}`}>
                                <div className={'account_info'}>
                                    {email && <div className={'account_info__userIcon'}>{email.substring( 0, 3 )}</div>}
                                    <div className={'account_info__details'}>
                                    { email && <div className={'email'}>{email}</div>}
                                </div>
                                <div className={'account_info__logout'} onClick={this.onLogoutClick}>
                                    <img
                                        src={logoutIcon}
                                        alt={strings.logOut}
                                        className={'account_info__logout__icon'}
                                    />
                                    <span className={'account_info__logout__text'}>
                                        {strings.logOut}
                                    </span>
                                </div>
                            </div>
                        </div>
                        )
                    }
                </div> 

                 
                {/* <div className='video-trailer deactive'>
                    <video src={this.isShowAndGo() ? showAndGoVideo : scanAndGoVideo} controls={true} className='video' controlsList='nodownload' />
                    <div className='close-button' onClick={this.pause}>
                        <div className='close-internal'></div>
                    </div>
                    <div className='close-text'>{strings.closeText}</div>
                </div>  */}
            </> 
        )
    }
}
const mapStateToProps = state => {
    return {
        auth : state.auth
    }
}
export default connect( mapStateToProps, { userLogin, loginNeeded, videoEnabled, isLoggedIn } ) ( OnboardingVideo )

OnboardingVideo.propTypes = {
    transformBanner: PropTypes.bool,
    loginNeeded: PropTypes.func,
    userLogin: PropTypes.func,
    auth: PropTypes.object,
    screenOrientation: PropTypes.string,
    videoEnabled: PropTypes.func,
    isLoggedIn: PropTypes.func
}
