import axios from 'axios'
import AppData from '../common/AppData'
import logger from '../common/logger'
import AppAnalytics from './AppAnalytics'
import config from './config'
import { authClient } from '../common/helper'


let network = {}
let urls = {
  'getCarts': '/cart/lvt/cart/',
  'getOrders':  '/order/store/search',
  'getTips': '/content/configuration',
  'getToken':'/auth/getStoreTokenLVT',
  'getGuidelines': '/content/guide',
  'getReports': '/order/store/search/date',
  'createFeedback': '/content/storeSupport/feedback/?app_name=liveviewtracker',
  'getFeaturesAndLocation': '/content/features',
  'getDeviceStatus': '/heartbeat/device-status',
  'getBanner': '/notifications/banner',
  'putBanner': '/notifications/banner',
  'getStoreDetails': '/store-setup/store/lvt'
}
let headers = {
  'Content-Type': 'application/json',
  'x-api-key':process.env.REACT_APP_API_KEY ,
}

const url = window.location.href
const { origin } = new URL( url )

let BASE_URL

//TODO : Revert the below condition once we create a public domain
if ( origin !== process.env.REACT_APP_SSO_REDIRECT_URL_PUB ) {
  BASE_URL = process.env.REACT_APP_BASE_URL_PUB
} else {
  BASE_URL = process.env.REACT_APP_BASE_URL
}

// Function to send a custom event
const sendCustomEvent = ( eventName, attributes ) => {
  if ( window.newrelic ) {
    window.newrelic.addPageAction( eventName, attributes )
  } else {
    logger.error( 'New Relic is not loaded.' )
  }
}

network.getAllCarts = ( successHandler, errorHandler ) => {

  let localStorageIdToken = localStorage.getItem( 'id_token' )
  let storeId = config.storeId()
  if(!storeId){
      authClient.logout( localStorageIdToken )
      localStorage.clear()
      return
  }
    let startTime = Date.now()
    axios( { 
      params:{
        'app_name': 'liveviewtracker',
        'time': 1
      },
      method: 'get',
      url: `${BASE_URL}${urls['getCarts']}?timeStamp=${startTime}`,
      headers: {...headers,'Authorization':`Bearer ${config.getJWTToken()}`,'accept-language':`${config.loadLanguage()}`}
    } )
    .then( ( response ) => {
      successHandler( response )
      let extra = {
        status: ( response && response.status ? response.status : '0' )
      }
      // below three lines of code reducing payload for appAnalytics and deleting api-key for security issue from response
      response.data = {}
      response.request = {}
      response.config.headers = {}

      let stopTime =  Date.now()
      let delta = ( stopTime - startTime ) / 1000
      AppAnalytics.network_get_carts( 'SUCCESS',extra,response,delta )
      logger.info( 'NA', 'network fetch', 'get_carts', 'NA', 'network fetch success', extra )
      sendCustomEvent( 'lvt_custom_event_to_send_store_id', { storeId: storeId } )
    } )
    .catch( ( error )=> {
      errorHandler( error )
      // below lines of code deleting api-key/certificate from error response for security issue
      let extra = {
        status:( error && error.response ? error.response.status : error.message )
      }
      if( error && error.response ){
        error.response.config.headers = {}
        error.response.data = {}
        error.response.request = {} 
      }
      if( error && !error.response ) {
        if( error.config ){
          error.config.headers = {}
        } 
      }

      let stopTime =  Date.now()
      let delta = ( stopTime - startTime ) / 1000
      AppAnalytics.network_get_carts( 'FAILURE',extra, error,delta )
      logger.error( 'NA', 'network fetch', 'get_carts', 'NA', 'network fetch failed', error )
    } )
}

network.getOrdersConfirmed = ( successHandler, errorHandler , newQuery ) => {
  let localStorageIdToken = localStorage.getItem( 'id_token' )
  let storeId = config.storeId()
  if(!storeId){
      authClient.logout( localStorageIdToken )
      localStorage.clear()
      return
  }
    let startTime = Date.now()
    let query = {
      'order_type':'selfcheckout',
      'store_id':config.storeId(),
      'synthetic_event': false,
      'transaction_status': 'success'
    }
    axios( {
      method: 'post',
      url: `${BASE_URL}${urls['getOrders']}?timeStamp=${startTime}`,
      data: {
          'query': {...query , ...newQuery },
      },
      headers: {...headers,'Authorization':`Bearer ${config.getJWTToken()}`,'accept-language':`${config.loadLanguage()}`}
      
    } )
    .then( ( response ) => {
      successHandler( response ) 
      let extra = {
        status: ( response && response.status ? response.status : '0' )
      }
      // below three lines of code reducing payload for appAnalytics and deleting api-key for security issue from response
      response.data = {}
      response.request = {}
      response.config.headers = {}

      let stopTime =  Date.now()
      let delta = ( stopTime - startTime ) / 1000
      AppAnalytics.network_get_orders( 'SUCCESS', extra,response,delta )
      logger.info( 'NA', 'network fetch', 'get_orders', 'NA', 'network fetch success', extra )
    } )
    .catch( ( error )=> {
      errorHandler( error )
      // below lines of code deleting api-key/certificate from error response for security issue
      let extra = {
        status:( error && error.response ? error.response.status : error.message )
      }
      if( error && error.response ){
        error.response.config.headers = {}
        error.response.data = {}
        error.response.request = {} 
      }
      if( error && !error.response ) {
        if( error.config ){
          error.config.headers = {}
        } 
      }

      let stopTime =  Date.now()
      let delta = ( stopTime - startTime ) / 1000
      AppAnalytics.network_get_orders( 'FAILURE',extra, error,delta )
      logger.error( 'NA', 'network fetch', 'get_orders', 'NA', 'network fetch failed', error )
    } )
}

network.getAllTips = ( successHandler, errorHandler )=>{
    let startTime = Date.now()
    axios( {
        method:'post',
        url: `${BASE_URL}${urls['getTips']}`,
        data: {
            'app':'liveviewtracker_v2',
            'type':'api',
            'version':'2.0',
            'country':'US'
        },
        headers:{...headers,'accept-language':`${config.loadLanguage()}`},
        timeout: 30000
    } ).then( ( response ) => {
        successHandler( response )
        let extra = {
          status: ( response && response.status ? response.status : '0' )
        }
        // below three lines of code reducing payload for appAnalytics and deleting api-key for security issue from response
        response.data = {}
        response.request = {}
        response.config.headers = {}

        let stopTime =  Date.now()
        let delta = ( stopTime - startTime ) / 1000
        AppAnalytics.network_get_config( 'SUCCESS', extra, response,delta )
        logger.info( 'NA', 'network fetch', 'get_config', 'NA', 'network fetch success', extra )
      } )
      .catch( ( error )=> {
        errorHandler( error )
        // below lines of code deleting api-key/certificate from error response for security issue
        let extra = {
          status:( error && error.response ? error.response.status : error.message )
        }
        if( error && error.response ){
          error.response.config.headers = {}
          error.response.data = {}
          error.response.request = {} 
        }
        if( error && !error.response ) {
          if( error.config ){
            error.config.headers = {}
          } 
        }

        let stopTime =  Date.now()
      let delta = ( stopTime - startTime ) / 1000
      AppAnalytics.network_get_config( 'FAILURE',extra, error,delta )
        logger.error( 'NA', 'network fetch', 'get_config', 'NA', 'network fetch failed', error )
      } )  
}


//Get Guidelines
network.getGuidelines = ( successHandler, errorHandler ) => {
  let startTime = Date.now()
  axios( {
    method: 'get',
    url: `${BASE_URL}${urls['getGuidelines']}`,
    headers: {...headers,'Authorization':`Bearer ${config.getJWTToken()}`}
  } ).then( ( response )=>{
    successHandler( response.data )
    let extra = {
      status: ( response && response.status ? response.status : '0' )
    }
    // below three lines of code reducing payload for appAnalytics and deleting api-key for security issue from response
    response.data = {}
    response.request = {}
    response.config.headers = {}

    let stopTime =  Date.now()
    let delta = ( stopTime - startTime ) / 1000
    AppAnalytics.network_get_guidelines( 'SUCCESS', extra,response,delta )
    logger.info( 'NA', 'network fetch', 'get_guidelines_content', 'NA', 'network fetch success', extra )
  } ).catch( ( error )=>{
    errorHandler( error )
    // below lines of code deleting api-key/certificate from error response for security issue
    let extra = {
      status:( error && error.response ? error.response.status : error.message )
    }
    if( error && error.response ){
      error.response.config.headers = {}
      error.response.data = {}
      error.response.request = {} 
    }
    if( error && !error.response ) {
      if( error.config ){
        error.config.headers = {}
      } 
    }

    let stopTime =  Date.now()
    let delta = ( stopTime - startTime ) / 1000
    AppAnalytics.network_get_guidelines( 'FAILURE',extra, error,delta )
    logger.error( 'NA', 'network fetch', 'get_guidelines_content', 'NA', 'network fetch failed', error )
  } )
}

//Get Reports
network.getReports = ( successHandler, errorHandler, newQuery ) => {
  let startTime = Date.now()
  let query = {
    'order_type':'selfcheckout',
    'store_id':config.storeId(),
  }
  axios( {
    method: 'post',
    url: `${BASE_URL}${urls['getReports']}`,
    data:{
      query:{
        ...query,
        ...newQuery
      },
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
    },
    headers: {...headers,'Authorization':`Bearer ${config.getJWTToken()}`}
  } ).then( ( response )=>{
    successHandler( response.data )
    let extra = {
      status: ( response && response.status ? response.status : '0' )
    }
    // below three lines of code reducing payload for appAnalytics and deleting api-key for security issue from response
    response.data = {}
    response.request = {}
    response.config.headers = {}

    let stopTime =  Date.now()
    let delta = ( stopTime - startTime ) / 1000
    AppAnalytics.network_get_reports( 'SUCCESS', extra,response,delta )
    logger.info( 'NA', 'network fetch', 'get_reports_content', 'NA', 'network fetch success', extra )
  } ).catch( ( error )=>{
    errorHandler( error )
    // below lines of code deleting api-key/certificate from error response for security issue
    let extra = {
      status:( error && error.response ? error.response.status : error.message )
    }
    if( error && error.response ){
      error.response.config.headers = {}
      error.response.data = {}
      error.response.request = {} 
    }
    if( error && !error.response ) {
      if( error.config ){
        error.config.headers = {}
      } 
    }

    let stopTime =  Date.now()
    let delta = ( stopTime - startTime ) / 1000
    AppAnalytics.network_get_reports( 'FAILURE',extra, error,delta )
    logger.error( 'NA', 'network fetch', 'get_reports_content', 'NA', 'network fetch failed', error )
  } )
}

network.createFeedback = ( successHandler, errorHandler, newQuery ) => {
  let startTime = Date.now()
  axios( {
    method: 'post',
    url: `${BASE_URL}${urls['createFeedback']}`,
    data:{
      ...newQuery
    },
    headers: {...headers,'Authorization':`Bearer ${config.getJWTToken()}`}
  } ).then( ( response )=>{
    successHandler( response.data )
    let extra = {
      status: ( response && response.status ? response.status : '0' )
    }
    // below three lines of code reducing payload for appAnalytics and deleting api-key for security issue from response
    response.data = {}
    response.request = {}
    response.config.headers = {}

    let stopTime =  Date.now()
    let delta = ( stopTime - startTime ) / 1000
    AppAnalytics.network_create_feedback( 'SUCCESS', extra,response, delta )
    logger.info( 'NA', 'network fetch', 'create_feedback', 'NA', 'network fetch success', extra )
  } ).catch( ( error )=>{
    errorHandler( error )
    // below lines of code deleting api-key/certificate from error response for security issue
    let extra = {
      status:( error && error.response ? error.response.status : error.message )
    }
    if( error && error.response ){
      error.response.config.headers = {}
      error.response.data = {}
      error.response.request = {} 
    }
    if( error && !error.response ) {
      if( error.config ){
        error.config.headers = {}
      } 
    }

    let stopTime =  Date.now()
    let delta = ( stopTime - startTime ) / 1000
    AppAnalytics.network_create_feedback( 'FAILURE',extra, error,delta )
    logger.error( 'NA', 'network fetch', 'create_feedback', 'NA', 'network fetch failed', error )
  } )
}

//get jwt token
network.getJWTToken = ( configData, successHandler , errorHandler ) => {
  let startTime = Date.now()
    axios( {
      url : `${BASE_URL}${urls['getToken']}` ,
      method : 'post',
      data: configData,
      headers: {...headers}
  } ).then( ( res ) => {
    successHandler( res )
    let extra = {
      status: ( res && res.status ? res.status : '0' )
    }

    // below three lines of code reducing payload for appAnalytics and deleting api-key for security issue from response
    res.data = {}
    res.request = {}
    res.config = {}

    let stopTime =  Date.now()
    let delta = ( stopTime - startTime ) / 1000
    setTimeout( () => {
      AppAnalytics.network_get_jwtToken( 'SUCCESS', extra,res,delta )
    }, AppData.eventDelay )
    
  } ).catch( ( error )=>{
    if( error &&  error.response && error.response.status === 401 ){
      config.setSetupComplete( false )
      // history.push( '/' )
    }else{
      console.log( error ) 
    }
    errorHandler( error )
    // below lines of code deleting api-key/certificate from error response for security issue
    let extra = {
      status:( error && error.response ? error.response.status : error.message )
    }
    if( error && error.response ){
      error.response.config.headers = {}
      error.response.data = {}
      error.response.request = {} 
    }
    if( error && !error.response ) {
      error.config.headers = {}
    }

    let stopTime =  Date.now()
    let delta = ( stopTime - startTime ) / 1000
    AppAnalytics.network_get_jwtToken( 'FAILURE',extra, error,delta )
  } )
}

network.handleConnectionChange = ( webIOTStatus, nativeIOTStatus ) => {
  if ( navigator.onLine === false ) {
      AppAnalytics.internetConnectionCheck( false, webIOTStatus, nativeIOTStatus )
    logger.error( 'NA', 'handle connection', 'NA', 'NA', 'Connection Error', 'NA' )
    return
  }
  axios( {
    url:`${window.location.origin}`,
    method: 'head',
    mode: 'no-cors',
    } )
  .then( ( response ) => {
    AppData.isDisconnected = response.status !== 200
    setTimeout( () => {
      AppAnalytics.internetConnectionCheck( response.status === 200, webIOTStatus, nativeIOTStatus )
    }, AppData.eventDelay )
    if( response.status === 200 && AppData.isError === true ){
      AppData.isError = false
      AppData.errorCount = 0
      // history.push( '/app' )
    }
  } ).catch( () =>  {
      AppAnalytics.internetConnectionCheck( false, webIOTStatus, nativeIOTStatus )
    AppData.isDisconnected = true
  } )
}

// get store location and feature lists
network.getLocationAndFeatureList = ( successHandler, errorHandler ) => {
  let startTime = Date.now()
  axios( { 
    params:{
      'store_id': config.storeId(),
      'app_name': 'live_view_tracker'
    },
    method: 'get',
    url: `${BASE_URL}${urls['getFeaturesAndLocation']}`,
    headers: {
      'Content-Type': 'application/json',
      'x-api-key':`${process.env.REACT_APP_API_KEY}` 
    }
  } )
  .then( ( response ) => {
      successHandler( response.data )
      let extra = {
        status: ( response && response.status ? response.status : '0' )
      }
      // below three lines of code reducing payload for appAnalytics and deleting api-key for security issue from response
      response.data = {}
      response.request = {}
      response.config.headers = {}

      let stopTime =  Date.now()
      let delta = ( stopTime - startTime ) / 1000
      AppAnalytics.network_get_location_feature( 'SUCCESS', extra,response,delta )
      logger.info( 'NA', 'network fetch', 'get_location_and_featureList', 'NA', 'network fetch success', extra )
  } )
  .catch( ( error )=>{
    errorHandler( error )
    // below lines of code deleting api-key/certificate from error response for security issue
    let extra = {
      status:( error && error.response ? error.response.status : error.message )
    }
    if( error && error.response ){
      error.response.config.headers = {}
      error.response.data = {}
      error.response.request = {} 
    }
    if( error && !error.response ) {
      if( error.config ){
        error.config.headers = {}
      }
    }

    let stopTime =  Date.now()
    let delta = ( stopTime - startTime ) / 1000
    AppAnalytics.network_get_location_feature( 'FAILURE',extra, error,delta )
    logger.error( 'NA', 'network fetch', 'get_location_and_featureList', 'NA', 'network fetch failed', error )
  } )
}

  network.startHeartbeat = ( webIOTStatus, nativeIOTStatus ) => {
  setInterval(
    () => {
      network.handleConnectionChange( webIOTStatus, nativeIOTStatus )
    }, AppData.heartbeatInterval )
}

network.getDeviceStatus = ( successHandler, errorHandler ) => {
  let query = {
    'store_id':config.storeId()
  }
  axios( {
    data: {
      'query': {...query}
    },
    method: 'get',
    url: `${BASE_URL}${urls['getDeviceStatus']}/${config.storeId()}`,
    headers: {...headers,'Authorization':`Bearer ${config.getJWTToken()}`}
    
  } )
  .then( ( response ) => {
    successHandler( response ) 
  } )
  .catch( ( error )=> {
    errorHandler( error )
    logger.error( 'NA', 'network fetch', 'get_device_status', 'NA', 'network fetch failed', error )
  } )
}

// get Notification Banner
network.getNotificationBanner = ( successHandler, errorHandler ) => {
  let startTime = Date.now()
  axios( { 
    method: 'get',
    url: `${BASE_URL}${urls['getBanner']}`,
    headers: {...headers,'Authorization':`Bearer ${config.getJWTToken()}`}
  } )
  .then( ( response ) => {
      successHandler( response.data )
      let extra = {
        status: ( response && response.status ? response.status : '0' )
      }
      // below three lines of code reducing payload for appAnalytics and deleting api-key for security issue from response
      response.data = {}
      response.request = {}
      response.config.headers = {}

      let stopTime =  Date.now()
      let delta = ( stopTime - startTime ) / 1000
      AppAnalytics.network_get_location_feature( 'SUCCESS', extra,response,delta )
      logger.info( 'NA', 'network fetch', 'get_Banner', 'NA', 'network fetch success', extra )
  } )
  .catch( ( error )=>{
    errorHandler( error )
    // below lines of code deleting api-key/certificate from error response for security issue
    let extra = {
      status:( error && error.response && error.response.status ? error.response.status : error.message )
    }
    if( error && error.response ){
      error.response.config.headers = {}
      error.response.data = {}
      error.response.request = {} 
    }
    if( error && !error.response ) {
      if( error.config ){
        error.config.headers = {}
      }
    }

    let stopTime =  Date.now()
    let delta = ( stopTime - startTime ) / 1000
    AppAnalytics.network_get_location_feature( 'FAILURE',extra, error,delta )
    logger.error( 'NA', 'network fetch', 'get_Banner', 'NA', 'network fetch failed', error )
  } )
}

// Put request for notification banner
network.putBanner = ( status,successHandler, errorHandler, id ) => {
  let startTime = Date.now()
  axios( { 
    method: 'put',
    url: `${BASE_URL}${urls['getBanner']}/${id}`,
    headers: {...headers,'Authorization':`Bearer ${config.getJWTToken()}`},
    data: status
  } )
  .then( ( response ) => {
    successHandler( response ) 
    let extra = {
      status: ( response && response.status ? response.status : '0' )
    }
    // below three lines of code reducing payload for appAnalytics and deleting api-key for security issue from response
    response.data = {}
    response.request = {}
    response.config.headers = {}

    let stopTime =  Date.now()
    let delta = ( stopTime - startTime ) / 1000
    AppAnalytics.network_get_location_feature( 'SUCCESS', extra,response,delta )
    logger.info( 'NA', 'network fetch', 'put_Banner', 'NA', 'network fetch success', extra )
  } )
  .catch( ( error )=> {
    errorHandler( error )
    // below lines of code deleting api-key/certificate from error response for security issue
    let extra = {
      status:( error && error.response && error.response.status ? error.response.status : error.message )
    }
    if( error && error.response ){
      error.response.config.headers = {}
      error.response.data = {}
      error.response.request = {} 
    }
    if( error && !error.response ) {
      if( error.config ){
        error.config.headers = {}
      }
    }

    let stopTime =  Date.now()
    let delta = ( stopTime - startTime ) / 1000
    AppAnalytics.network_get_location_feature( 'FAILURE',extra, error,delta )
    logger.error( 'NA', 'network fetch', 'put_Banner', 'NA', 'network fetch failed', error )
  } )
}

network.getStoreDetails = ( storeId, successHandler, errorHandler ) => {
  let startTime =  Date.now()
  axios( { 
    method: 'get',
    url: `${process.env.REACT_APP_BASE_URL}${urls['getStoreDetails']}/${storeId}?timeStamp=${startTime}`,
    headers: {...headers,'accept-language':`${config.loadLanguage()}`}
  } )
  .then( ( response ) => {
    if ( response?.data?.status ){
      successHandler( response )
    } else {
      errorHandler()
    }
  
  } )
  .catch( ( error )=> {
    errorHandler( error )
  } )
}

export default network

