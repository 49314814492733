import React, {Component}from 'react'
import PropTypes from 'prop-types'
import UserName from './UserName'
import SuccessAudio from './SuccessAudio'
import AppData from '../common/AppData'
import _ from 'underscore'
import { strings } from '../context/LanguageContext/strings'

export default class Cart extends Component {
    constructor( props ){
        super( props )
        this.state = {
            confirm : this.props.cardStyle,
            opacity: 1
        }
    }

    componentDidMount(){
        this.orderConfirmStyle()
    }

    componentDidUpdate( prevProps ){
        if( prevProps.cardStyle !== this.props.cardStyle ) {
            this.orderConfirmStyle()
        }
    }

    //Rendering PromoDetails
    getPromoDesc = ( deals ) => {
        if( deals === undefined || ( deals && deals.length === 0 ) ) return null
        if( deals !== null ){
            return deals.map( ( deal,index )=>{
                return (
                    <div className="promo-desc" key={index}>
                        {deal.Description}
                    </div>
                )
            } )
        }

    }
    getPromoAmount= ( deals ) => {
        if( deals === undefined || ( deals && deals.length === 0 ) ) return null
        if( deals !== null ){
           return deals.map( ( deal,index )=>{
                return (
                    <div className="promo" key={index}>
                        {( deal.SubTotal !== undefined )?`-$${( deal.SubTotal/100 ).toFixed( 2 ).slice( 1 )}`: ''}
                    </div>
                )
            } )
        }

    }

    totalPrice = ( item ) => {
        let result = ( item.product.price && item.quantity ) ? item.product.price * item.quantity : 0
        return ( result/100 ).toFixed( 2 )
    }


    //Rendering Products
    renderProductList = () => {
        return this.props.cart.items.map( ( item,index )=>{
            return(
                <div className="item-desc" key={index}>
                    <div className="qty">{item.quantity}</div>
                        <div className="desc">
                            <div className="product-name">{( item.product !== undefined )?  item.product.name  : item.product_id}</div>
                            {this.getPromoDesc( item.deals )}
                        </div>
                        <div className="price">
                            {item.product !== undefined ? `$${this.totalPrice( item )}` : 'N/A'}
                            {this.getPromoAmount( item.deals )}
                        </div>
                </div>
            )
        } )
    }
    
    //Calculating total quantities in the cart
    totalQuantity = () => {
        let total = this.props.cart.items
            .map( item => {
                if ( this.props.cart.itemType === 'order' && item.product.price <= 0 ) {
                    return 0
                }
            return item.quantity
            } )
            .reduce( ( previouVal, currentVal ) => {
            return previouVal + currentVal
            },0 )
        return total
      }

    //Calculating total amount for the items
    totalAmount = () => {
        let grandTotal = ( this.props.cart.grandTotal / 100 ).toFixed( 2 ).toString().split( '.' )
        return (
            <div className="total-price">
                <div className="amount">${grandTotal[0]}</div>
                <div className="cents">{grandTotal[1]}<span className="line"></span></div>
            </div>
        )
    }
    handleModalOpen = () => {
        this.props.handleModalOpen()
    }

    orderConfirmStyle = () => {     
        if( this.props.cardStyle === 'card-container' ){
            this.setState( {
                confirm : 'confirm-card-container'
            } )
        }
        if( this.props.cardStyle === 'tile-container' ){
            this.setState( {
                confirm : 'confirm-tile-container'
            } )
        }
        if( this.props.cardStyle === 'row-container' ){
            this.setState( {
                confirm : 'confirm-row-container'
            } )
        }
    }

    //set cart opacity
    setOpacity = () => {
        this.setState( { opacity: 0.5 } )
        setTimeout( ()=>{
        this.setState( { opacity: 1 } )
        },100 )
    }
    
    //returning UI 
    render(){ 
        const userProfile = _.isArray( this.props.cart.user_profile ) ? _.first( this.props.cart.user_profile ) : this.props.cart.user_profile
        return (
            <div className={( this.props.cart.transaction_status && this.props.cart.isOrderConfirmed  )? this.state.confirm : this.props.cardStyle} 
                onClick={this.handleModalOpen} onTouchStart={this.setOpacity} style={{opacity:`${this.state.opacity}`}}>
                {this.props.cart.transaction_status && this.props.cart.successSound === true &&  AppData.isForeground === true ? <SuccessAudio cart={this.props.cart}/> : null }
                <div className="user-container"> 
                    <div className="user-name">
                        <UserName userProfile={userProfile} />
                    </div>
                    {this.props.cart.transaction_icon && !this.props.cart.showAndGoIconCheck && this.props.cart.isOrderConfirmed && this.props.cardStyle !== 'row-container'?
                            <div className="confirm-user-icon">
                                <div className="confirm-logo"> 
                                    <span className="order-logo-confirmed">{strings.confirmedText}</span>
                                </div>  
                            </div>
                        :  ( this.props.cart.itemType === 'order' && this.props.cardStyle !== 'row-container' )?
                            <div className="confirm-user-icon">
                                <div className="paid-logo"> 
                                    <span className="order-logo-paid">{strings.paidText}</span>
                                </div>  
                            </div>:
                            <div className="confirm-user-icon">
                                <div className="shopping-logo"> 
                                <span className="shopping-logo-active">{strings.shoppingText}</span>
                                </div>  
                             </div>
                     }
                </div>
                {( this.props.cart.transaction_icon && this.props.cart.isOrderConfirmed && this.props.cardStyle === 'row-container' )?
                        <div className="note">
                            <div className="confirm-user-icon">
                                <div className="logo"> 
                                    <span className="logo-confirmed">{strings.confirmedText}</span>
                                </div>  
                            </div>
                        </div>
                    :  ( this.props.cart.itemType === 'order' && this.props.cardStyle === 'row-container'  )?                         
                        <div className="note">
                            <div className="confirm-user-icon">
                                <div className="paid-logo"> 
                                    <span className="order-logo-paid">{strings.paidText}</span>
                                </div>  
                            </div>
                        </div> : null
                }
    
                <div className="items-container">
                    <div className="total-container">
                        <div className="tags">
                            <div className="item-tag">{strings.itemText}</div>
                            <div className="price-tag">{strings.priceTotalText}</div>
                        </div>
                        <div className="total">
                            <div className="total-items">{this.totalQuantity()}</div>
                            {this.totalAmount()}
                        </div>
                    </div>
                    <div className="items">
                        {this.renderProductList()}
    
                    </div>
                    <div className="fade"></div>
                </div>
                <div className="btn-position">
                    <button className="btn-container" onClick={this.handleModalOpen}>
                        <span className="btn-detail">{strings.btnDetailsText}</span>
                    </button>
                </div>
            </div>
        )
    }

    }
   
Cart.propTypes = {
    cardStyle: PropTypes.string,
    cart: PropTypes.object,
    handleModalOpen: PropTypes.func
}
