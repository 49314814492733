// import Amplify from '@aws-amplify/core'
import {Amplify,PubSub } from "aws-amplify";
import { AWSIoTProvider } from "@aws-amplify/pubsub/lib/Providers";
import AppAnalytics from "./AppAnalytics";
import _ from "underscore";
import AppData from "./AppData";
import config from "./config";
import logger from "./logger";

let amplify = {};
let _subscription = "";
let timer = AppData.topicRefreshPeriod;

amplify.isDisconnected = false;

// Amplify.Logger.LOG_LEVEL = 'DEBUG'

//Setup Amplify
amplify.configure = () => {
  try {
    const AuthConfig = {
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
      region: process.env.REACT_APP_REGION,
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    };
    Amplify.configure({
      Auth: AuthConfig,
    });
    amplify.IOTConnection();
    AppAnalytics.mqttConnectionSuccessful(true);
  } catch (error) {
    logger.error(
      "NA",
      "amplify configuration",
      "amplify_configure",
      "NA",
      "Amplify Configuration failed",
      error
    );
  }
};

//Connection to AWS IoT
amplify.IOTConnection = () => {
  let sock = `wss://${process.env.REACT_APP_MQTT_ID}.iot.${process.env.REACT_APP_REGION}.amazonaws.com/mqtt`;
  Amplify.addPluggable(
    new AWSIoTProvider({
      aws_pubsub_region: process.env.REACT_APP_REGION,
      aws_pubsub_endpoint: sock,
    })
  );
  amplify.isDisconnected = false;
  AppData.pollingEnabled = false;
};

//Subscribe to the MQTT topic
amplify.pubsubSubscribe = () => {
  AppData.pollingEnabled = false;
  let cartTopic =
    "mspLVTracker/" +
    process.env.REACT_APP_MQTT_ENVIRONMENT +
    "/" +
    config.storeId();
  let confirmedOrderTopic =
    "mspOrderConfirmations/" +
    process.env.REACT_APP_MQTT_ENVIRONMENT +
    "/" +
    config.storeId();
  let paidOrderTopic =
    "mspOrderSelfcheckedout/" +
    process.env.REACT_APP_MQTT_ENVIRONMENT +
    "/" +
    config.storeId();
  let clearCartTopic =
    "mspLVTClearCart/" +
    process.env.REACT_APP_MQTT_ENVIRONMENT +
    "/" +
    config.storeId();
  let updateBannerTopic =
    "updateBanner/" +
    process.env.REACT_APP_MQTT_ENVIRONMENT +
    "/" +
    config.storeId();
  let createBannerTopic =
    "createBanner/" +
    process.env.REACT_APP_MQTT_ENVIRONMENT +
    "/" +
    config.storeId();

  _subscription = PubSub.subscribe([
    cartTopic,
    confirmedOrderTopic,
    paidOrderTopic,
    clearCartTopic,
    updateBannerTopic,
    createBannerTopic,
  ]).subscribe({
    next: (data) => {
       AppData.pollingEnabled = false;
      _.forEach(AppData.getAllPubSubListeners(), (pubSubListener) => {
        pubSubListener(data.value);
      });
    },
    error: (error) => {
      if (error.error === "Disconnected, error code: 8") {
        logger.error(
          "NA",
          "pubsub fetch",
          "pubsub_subscribe",
          "NA",
          "Topic disconnected",
          error.error
        );
        AppData.isError = true;
        amplify.isDisconnected = true;
        AppData.errorMessage = "NO_INTERNET_ACCESS";
        // history.push( '/app' )
        if (navigator.onLine === true && AppData.isError === true) {
          setTimeout(() => {
            // history.push( '/app' )
          }, timer);
        }
      }
      if (error.error.errorCode && error.error.errorCode === 1) {
        logger.error(
          "NA",
          "pubsub fetch",
          "pubsub_subscribe",
          "NA",
          "Topic connection timed out",
          error
        );
        AppData.isError = true;
        amplify.isDisconnected = true;
        AppData.errorMessage = "NO_INTERNET_ACCESS";
        // history.push( '/app' )
        if (navigator.onLine === true && AppData.isError === true) {
          setTimeout(() => {
            // history.push( '/app' )
          }, timer);
        }
      }
    },
    close: () => console.log("Done"),
  });
};

//Unsubscribe to the MQTT topic
amplify.unsubscribe = () => {
  if (_subscription) {
    console.log("Unsubscribe Topic");
    _subscription.unsubscribe();
    _subscription = null;
  }
};

//Reload Iot Topic for reconnecting

// For Future use
amplify.reload = () => {
  let extra = {
    status: 200,
  };
  logger.info(
    "NA",
    "IoT reload",
    "topic_reload",
    "NA",
    "reconnecting IoT topic",
    extra
  );
  AppAnalytics.mqttReConnectionCheck();
  amplify.configure();
  amplify.pubsubSubscribe();
};

export default amplify;
