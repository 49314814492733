import React, { Component } from 'react'
import '../style/GuidelineDetail.scss'
import AppData from '../common/AppData'
import Mixpanel from '../common/Mixpanel'
import PropTypes from 'prop-types'

export default class GuidelineResponse extends Component {
    constructor( props ){
        super( props )
        this.state = {
            clicked: '',
            YesOpacity: 1,
            NoOpacity:1
        }
    }

    YesConfirmation = () => {
        this.setState( { clicked : 'Yes' } )
        Mixpanel.troubleshootingResponse( 'Yes', this.props.guideSource, this.props.guidelineName )
    }

    NoConfirmation = () => {
        this.setState( { clicked : 'No' } )
        Mixpanel.troubleshootingResponse( 'No', this.props.guideSource, this.props.guidelineName )
    }

    // setting buttons opacity
    setYesOpacity = () => {
        this.setState( { YesOpacity: 0.5 } )
    }

    setNoOpacity = () => {
        this.setState( { NoOpacity: 0.5 } )
    }


    render() {
        const { clicked } = this.state
        return (
            <div id='button-container' className={ 
                clicked === 'Yes' ? 'guide-detail-button-container-yes-text' :
                clicked === 'No' ? 'guide-detail-button-container-no-text':
                'guide-detail-button-container'}>
                <div id='guide-text' className='guide-detail-text'>
                {clicked === 'Yes' ? AppData.guideYesText :
                 clicked === 'No' ? AppData.guideNoText :
                 AppData.guideButtonText }</div>
                    <div id='child-button-container'className='button-container'>
                        <div className='button-1' style={{opacity:`${this.state.YesOpacity}`}} onTouchStart={()=>{this.setYesOpacity()}} onTouchEnd={()=>{this.YesConfirmation()}}>
                            <span className='button-1-detail'>YES</span>
                        </div>
                        <div className='text-1'>{AppData.guideNoText1}</div>
                        <div className='text-2'>{AppData.textNumber}</div>
                        <div className='button-2' style={{opacity:`${this.state.NoOpacity}`}} onTouchStart={()=>{this.setNoOpacity()}} onTouchEnd={()=>{this.NoConfirmation()}}>
                            <span className='button-2-detail' onClick={()=>{this.NoConfirmation()} }>NO</span>
                        </div>  
                    </div>
            </div>
        )
    }
}

GuidelineResponse.propTypes = {
    guidelineName: PropTypes.string,
    guideSource: PropTypes.string
}


