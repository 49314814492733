import React, { useContext } from 'react'
import '../style/feedback.scss'
import triangleIcon from '../assets/exclamation-mark-triangle.png'
import { LanguageContext } from '../context/LanguageContext'

const FeedbackModal = props => {
    const { strings } = useContext( LanguageContext )
    const { children , isOpen, onClose, createFeedback, discardFeedback } = props
    if( !isOpen ) return null
    return (
        <>
            <div className='OVERLAY_MODAL'/>
            <div className='MODAL_STYLES'>
                <img className="triangle-icon" src={triangleIcon} alt="triangle-icon" />
                <div className="confirmation"><h2 style={{margin: '16px 0 5px 0', color: '#383c46', fontSize: '30px'}}>{strings.confirmationText1}</h2><div className="conf-txt">{strings.confirmationText2}<br /> {strings.confirmationText3}</div></div>
                <button className="submit-feedback-btn" onClick={() => createFeedback()}>{strings.submitFeedbackText}</button>
                <button className="discard-feedback-btn" onClick={() => discardFeedback()}>{strings.discardFeedbackText}</button>
                <button className="edit-feedback-btn" onClick={onClose}>{strings.editFeedbackText}</button>
                {children}
            </div>
        </>
        )
}

export default FeedbackModal 
