import { actions } from './actions'

const initialState = {
    isLoading: true,
}

export default ( state = initialState, action ) => {
    const { type, payload } = action
    switch ( type ) {
        case actions.SET_LOADER: {
            return {
                isLoading: payload,
            } 
        }
        default:
            return state
    }
}
