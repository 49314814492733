import axios from 'axios'

const url = window.location.href
const { origin } = new URL( url )

let SSO_DOMAIN, SSO_REDIRECT_URL, SSO_LOGOUT_URL

//TODO : Revert the below condition once we create a public domain

if ( origin !== process.env.REACT_APP_SSO_REDIRECT_URL_PUB ) { 
  SSO_DOMAIN = process.env.REACT_APP_SSO_DOMAIN_PUB
  SSO_REDIRECT_URL = process.env.REACT_APP_SSO_REDIRECT_URL_PUB
  SSO_LOGOUT_URL = process.env.REACT_APP_SSO_LOGOUT_URL_PUB
} else {
  SSO_DOMAIN = process.env.REACT_APP_SSO_DOMAIN
  SSO_REDIRECT_URL = process.env.REACT_APP_SSO_REDIRECT_URL
  SSO_LOGOUT_URL  = process.env.REACT_APP_SSO_LOGOUT_URL
}

const authorize = () => {
    const url = `${SSO_DOMAIN}/openid/authorize?response_type=${process.env.REACT_APP_SSO_RESPONSE_TYPE}&client_id=${process.env.REACT_APP_CLIENT_ID}&scope=${process.env.REACT_APP_SSO_SCOPE}&redirect_uri=${SSO_REDIRECT_URL}`
    window.location.replace( url )
}

const getTokens = async ( code ) => {
    const reqData = `grant_type=${process.env.REACT_APP_GRANT_TYPE}&code=${code}&client_id=${process.env.REACT_APP_CLIENT_ID}&client_secret=${process.env.REACT_APP_CLIENT_SECRET}&redirect_uri=${SSO_REDIRECT_URL}`
    return axios( {
      url: `${SSO_DOMAIN}/openid/token`,
      headers: {
         'Content-Type': 'application/x-www-form-urlencoded'      
      },
      method: 'POST',
      data: reqData,
    } )
  }

const getUser = token =>{
   return axios( {
        url: `${SSO_DOMAIN}/openid/userinfo`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    } )
}


const logout = token => {
  const reqData = `id_token_hint=${token}`

  let url = `${SSO_DOMAIN}/openid/end-session-api`

  return axios( {
    url: url,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'      
    },
    method: 'POST',
    data: reqData
  }).then( response => {
    if( response && response.status && response.status === 200 ){
      window.location.replace( SSO_LOGOUT_URL )
    }
  }).catch (err => {
    window.location.replace( SSO_LOGOUT_URL )
    console.log('err',err)
  })
  
}
  
  
const getRefreshToken = ( refreshToken ) => async () => {
    const reqData = `grant_type=refresh_token&client_id=${process.env.REACT_APP_CLIENT_ID}&refresh_token=${refreshToken}&client_secret=${process.env.REACT_APP_CLIENT_SECRET}&redirect_uri=${SSO_REDIRECT_URL}`
    return axios( {
      url: `${SSO_DOMAIN}/openid/token`,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      method: 'POST',
      data: reqData
    } )
  }

export const authClient = {
    authorize,
    getTokens,
    getUser,
    getRefreshToken,
    logout
}