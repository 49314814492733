/* eslint-disable no-constant-condition */
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import AppData from '../common/AppData'


export default function UserName( { userProfile } ) {
    let userProfileData = {
        firstName: '',
        loyaltyId:'',
        lastName:''
    }

    if( userProfile && userProfile !== null && userProfile.first_name !== null  ){
        userProfileData.firstName = userProfile.first_name
        userProfileData.lastName =userProfile.last_name || ''
        userProfileData.loyaltyId = userProfile.loyalty_id
       
    }

    //redacting MemebrID 
    const redact = ( str ) =>{
        let newStr = str.replace( AppData.redactExp, '•' )
        return newStr
    }

    const nameCheck = () => {
        if( userProfileData.firstName === '' || userProfileData.firstName === 'UNKNOWN'  ){
            return true
        }else{
            return false
        }
    }

    const nameStyle = () => {
        let firstName = userProfileData.firstName || ''
        
        if ( !nameCheck() ){
           return ( <Fragment>
            <div className="fullName">{firstName}</div>
           </Fragment> )
        }else{
            return ( <div className="loyalty-id-name">{ redact ( userProfileData.loyaltyId )}</div> )
        }
    }
    
    return (
        <Fragment>
            { nameStyle() }
        </Fragment>
    )
}

UserName.propTypes = {
    userProfile: PropTypes.object,
}
