import React, { useContext } from 'react'
import Loader from './Loader'
import '../style/HomeScreen.scss'
import { LanguageContext } from '../context/LanguageContext'
import { useSelector } from 'react-redux'


export default function HomeScreen() {
    const { strings } = useContext( LanguageContext )
    const { isLoggedIn } = useSelector( state=> state.auth )
    return (
        isLoggedIn ? <div className="home-screen ">
            <div className="first-line">{strings.showAndGoText}</div>
            {/* <div className="second-line">LIVE VIEW TRACKER</div>  */}
            <div className="mobile-checkout"></div>
            <Loader/>
        </div> : null

    )
}
