import { FETCH_CONFIG } from '../actions/types'

const initialState = {}

export default function( state = initialState, action ){

    switch( action.type ){
        case FETCH_CONFIG:
            return {
                ...state,
                activityStatus : action.payload['activityStatus'],
                layoutStatus: action.payload['layoutStatus'],                        
                allTips : action.payload['allTips'],
                faqs : action.payload['faqs'],
                insightsContent : action.payload['insightsContent'],
                unwantedNames : action.payload['unwantedNames'],
                marketContent : action.payload['marketContent'],
                pollingState: action.payload['pollingState'],
                pollingInterval: action.payload['pollingInterval'],
                amplifyState: action.payload['amplifyState'],
                languageState: action.payload['languageState']
            }
        default:
            return state
    }
}