import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import history from '../history'
import '../style/Navbar.scss'
import AppData from '../common/AppData'
import AppAnalytics from '../common/AppAnalytics'
import { LanguageContext } from '../context/LanguageContext'
import config from '../common/config'

export default function Navbar ( { back, routeProps, onNavigationHandler } ){
   const { strings } = useContext( LanguageContext )
   const [title, setTitle] = useState( '' )
   //Life cycle hooks
   useEffect( () => {

      //Get title from active routes
      function getTitle () {
         let pathname = routeProps.location.pathname
         pathname = pathname.split( ':' )[0]
         switch( pathname ){
            case '/app':
               setTitle( 'showAndGoText' )
               break
            case '/app/liveView':
               setTitle( 'liveViewText' )
               break
            case '/app/history':
               setTitle( 'historyText' )
               break
            case '/app/help':
               setTitle( 'helpText' )
               break
            case '/app/reports':
               setTitle( 'reportsText' )
               break
            case '/app/help/faq':
               setTitle( 'faqAndVideosText' )
               break
            case '/app/help/guides':
               setTitle( 'troubleshootingGuidesText' )
               break  
            case '/app/help/guides/steps/':
               setTitle( 'stepsText' )
               break
            case '/app/help/guides/tips/':
               setTitle( 'tipsText' )
               break  
            case '/app/help/faq/details':
               setTitle( 'detailsText' )
               break
            case '/app/liveView/details/':
               setTitle( 'detailsText' )
               break
            case '/app/history/details/':
               setTitle( 'detailsText' )
               break
            case '/app/help/details/':
               setTitle( 'detailsText' )
               break
            case '/app/whatsNew':
               setTitle( 'whatsNewText' )
               break
            case '/app/feedback':
               setTitle( 'feedbackText' )
               break
            case '/app/help/feedback':
               setTitle( 'feedbackText' )
               break
            case '/app/help/feedback/terms':
               setTitle( 'feedbackTermsText' )
               break
            case '/app/help/feedback/submit':
               setTitle( 'feedbackText' )
               break
            default:
               break
         }   
         onNavigationHandler( title )
      }

         let startTime = AppData.modeStartTime
         let endTime = Date.now()
         AppAnalytics.pageLoaded( startTime, endTime )
      
      getTitle()   
   }, [routeProps, onNavigationHandler, title] )
   
   const goBack = ( bol ) => {
      history.goBack()
      back( bol )  
   }
   
   const classnameTitle = title === 'What\'s New?' ? 'wts-new' : title.toLowerCase().replace( ' ', '-' )
   return (
         <div className="navbar-main">
            <div className="navbar-container">
            <div className="lvt-icon-container">
               { routeProps.location.pathname === '/app' ? 
                  <div className="lvt-icon"></div> : 
                  <div id="bk-icn" className="back-icon-container" onClick={() => goBack( false )}>
                  <div className="back-icon"></div>
                  </div>
               }
            </div>
            <div className="navbar-title-container">
                  <div className={`navbar-title ${classnameTitle}`}>{strings[title]}</div>
            </div>
            <div className="navbar-center-container">
               <div className="disp-store-id">{'Store: '+config.storeId()}</div>
            </div>
            </div>
         </div>
      )
}

Navbar.propTypes = {
   back : PropTypes.func,
   onNavigationHandler: PropTypes.func,
   routeProps : PropTypes.shape( {
      location : PropTypes.object
   } )
}