import React from 'react'
import PropTypes from 'prop-types'

import '../style/InsightContent.scss'

export default function InsightContent( { title, descriptions } ) {
    return (
        <div className="insight-content">
            <div className="insight-title">{title}</div>
            <div className="insight-description">{descriptions}</div>
        </div>
    )
}

InsightContent.propTypes = {
    title: PropTypes.string,
    descriptions: PropTypes.string
}
