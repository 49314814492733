const config = {
    lvtConfig: {},
    storeObj: {},
    load: () => {
        let retrievedObject = localStorage.getItem( 'lvt.config' )
        config.lvtConfig = JSON.parse( retrievedObject )
    },
    storeId: () =>  {
        config.load()
        if( config && config.lvtConfig !== null && config.lvtConfig.storeId ){
            return config.lvtConfig.storeId
        }else{
            return null
        }
    } ,
    loadLocalData: () => {
        let getStoreObject = localStorage.getItem( 'store_object' )
        config.storeObj = JSON.parse( getStoreObject )
    },
    loadCity: () => {
        let getStoreObject = localStorage.getItem( 'store_object' )
        let storeObj = JSON.parse( getStoreObject )
        let city = storeObj.storeCity
        return city
    },
    loadState: () => {
        let getStoreObject = localStorage.getItem( 'store_object' )
        let storeObj = JSON.parse( getStoreObject )
        let state = storeObj.storeState
        return state
    },
    loadZoneId: () => {
        let getStoreObject = localStorage.getItem( 'store_object' )
        let storeObj = JSON.parse( getStoreObject )
        let zoneId = storeObj.storeZoneId
        return zoneId
    },
    loadCountry: () => {
        let getStoreObject = localStorage.getItem( 'store_object' )
        let storeObj = JSON.parse( getStoreObject )
        let country = storeObj.storeCountry
        return country
    },
    loadLanguage: () => {
        let language = localStorage.getItem( 'language' )
        return language
    },
    loadMarketId: () => {
        let getStoreObject = localStorage.getItem( 'store_object' )
        let storeObj = JSON.parse( getStoreObject )
        let marketId = storeObj.storeMarketId
        return marketId
    },
    localData: () => {
        config.loadLocalData()        
        return config && config.storeObj && config.storeObj
    },
    getJWTToken: () =>{
        config.load()
        if( config && config.lvtConfig !== null && config.lvtConfig.jwt ){
            return config.lvtConfig.jwt
        }else{
            return null
        }
    },
    setDeviceProfile : () => {
        config.load()
        let deviceProfileText = window.Android && window.Android.getDeviceProfile ? window.Android.getDeviceProfile() : ''
        let deviceProfile = deviceProfileText.length > 0 ? JSON.parse( deviceProfileText ) : {}
        let newConfig = { ...config.lvtConfig , ...deviceProfile }
        localStorage.setItem( 'lvt.config', JSON.stringify( newConfig ) )
    },
    getDeviceId : () => {
        config.load()
        if( config.lvtConfig ){
            return config.lvtConfig.deviceId
        }else{
            return null
        }
    },
    getDeviceType: () => {
        config.load()
        if( config.lvtConfig ){
            return config.lvtConfig.deviceType
        }else{
            return null
        }
    },
    isProxyEnabled: () =>  {
        return config.lvtConfig.isProxyEnabled
    },
    getShownOrders: () => {
        let persistenceOrders = localStorage.getItem( 'lvt.persistence.orders' )
        if ( persistenceOrders === null ){
            return {}
        }
        return JSON.parse( persistenceOrders )
    },
    setShownOrders: ( orders ) => {
        return localStorage.setItem( 'lvt.persistence.orders', JSON.stringify( orders ) )
    },
    setSetupComplete: ( isComplete ) => {
        config.load()
        config.lvtConfig.setupComplete = isComplete
        return localStorage.setItem( 'lvt.config', JSON.stringify( config.lvtConfig  ) )
    },
    getSystemIdentifier: () => {
        config.load()
        if( config.lvtConfig ){
            return config.lvtConfig.systemIdentifier
        }else{
            return null
        }
    },
    setShownCarts: ( carts ) => {
        return localStorage.setItem( 'lvt.persistence.carts', JSON.stringify( carts ) )
    },
    getShownCarts: () => {
        let persistenceCarts = localStorage.getItem( 'lvt.persistence.carts' )
        if ( persistenceCarts === null ){
            return {}
        }
        return JSON.parse( persistenceCarts )
    },
    setShownPaidOrders: ( orders ) => {
        return localStorage.setItem( 'lvt.persistence.paid', JSON.stringify( orders ) )
    },
    getShownPaidOrders: () => {
        let persistenceCarts = localStorage.getItem( 'lvt.persistence.paid' )
        if ( persistenceCarts === null ){
            return {}
        }
        return JSON.parse( persistenceCarts )
    },
    removeOlderStorageCarts: () => {
        let carts = JSON.parse( localStorage.getItem( 'lvt.persistence.carts' ) )
        carts = carts ? carts : {}
        const newCarts =  Object.entries( carts ).filter( ( [ key, value] ) =>  value > new Date( new Date().setDate( new Date().getDate() - 1 ) ).getTime() ) 
        const newCartsObj = newCarts.reduce( function( prev,curr ){prev[curr[0]]=curr[1];return prev},{} )
        config.setShownCarts( newCartsObj )
    },
    removeOlderStoragePaid: () => {
        let paid = JSON.parse( localStorage.getItem( 'lvt.persistence.paid' ) )
        paid = paid ? paid : {}
        const newPaid =  Object.entries( paid ).filter( ( [ key, value] ) =>  value > new Date( new Date().setDate( new Date().getDate() - 1 ) ).getTime() ) 
        const newPaidObj = newPaid.reduce( function( prev,curr ){prev[curr[0]]=curr[1];return prev},{} )
        config.setShownPaidOrders( newPaidObj )
    },
    removeOlderStorageOrders: () => {
        let orders = JSON.parse( localStorage.getItem( 'lvt.persistence.orders' ) )
        orders = orders ? orders : {}
        const newOrders =  Object.entries( orders ).filter( ( [ key, value] ) =>  value > new Date( new Date().setDate( new Date().getDate() - 1 ) ).getTime() ) 
        const newOrdersObj = newOrders.reduce( function( prev,curr ){prev[curr[0]]=curr[1];return prev},{} )
        config.setShownOrders( newOrdersObj )
    },
    setNotificationCarts: ( carts ) => {
        return localStorage.setItem( 'lvt.notification.carts', JSON.stringify( carts ) )
    },
    getNotificationCarts: () => {
        let notificationCarts = localStorage.getItem( 'lvt.notification.carts' )
        if ( notificationCarts === null ){
            return {}
        }
        return JSON.parse( notificationCarts )
    },
    removeOlderNotificationCarts: () => {
        let carts = JSON.parse( localStorage.getItem( 'lvt.notification.carts' ) )
        carts = carts ? carts : {}
        const newCarts =  Object.entries( carts ).filter( ( [ key, value] ) =>  value > new Date( new Date().setDate( new Date().getDate() - 1 ) ).getTime() ) 
        const newCartsObj = newCarts.reduce( function( prev,curr ){prev[curr[0]]=curr[1];return prev},{} )
        config.setNotificationCarts( newCartsObj )
    }
}

export default config
