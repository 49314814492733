/* eslint-disable no-case-declarations */
import { DEVICE_STATUS, GUIDELINES, INCOMING_NOTIFICATION } from '../actions/types'

const initialState = {
   'deviceStatus': [],
   'guidelines': [],
   'incomingNotification': false
}

export default function ( state = initialState, action ){
   
   switch( action.type ){
       case DEVICE_STATUS:
           return {...state, deviceStatus:action.payload }
       case GUIDELINES:
           return {...state, guidelines:action.payload }
       case INCOMING_NOTIFICATION:
           return {...state, incomingNotification:action.payload}
       default:
           return state
   }
}
