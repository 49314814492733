export const FETCH_CONFIG = 'fetch_config'
export const USER_LOGIN = 'user_login'
export const LOGIN_NEEDED = 'login_needed'
export const IS_SESSION_NEEDED = 'is_session_needed'
export const USER_ID = 'user_id'
export const FAILED_LOGIN = 'failed_login'
export const SUCCESS_LOGIN = 'success_login'
export const DISMISS_LOGIN = 'dismiss_login'
export const AUTO_DISMISS_LOGIN = 'auto_dismiss_login'
export const DISPLAY_LOGIN_INACTIVE = 'display_login_inactive'
export const VIDEO_ENABLED = 'video_enabled'
export const PUSH_NOTIFICATION = 'push_notification'
export const DEVICE_STATUS = 'device_status'
export const GUIDELINES = 'guidelines'
export const INCOMING_NOTIFICATION = 'incoming_notification'
export const IS_LOGGEDIN = 'is_loggedin'
