import React, { useContext } from 'react'
import { LanguageContext } from '../context/LanguageContext'
import PropTypes from 'prop-types'


export default function Loader( {languageFetched = true} ) {
    const { strings } = useContext( LanguageContext )

    return (
        <div className="spinner">
           {languageFetched &&  <div className="loading">{strings.loadingText}</div>}
        </div>
    )
}

Loader.propTypes = {
    languageFetched: PropTypes.bool
}