import React, { Component, Fragment } from 'react'
import { Route } from 'react-router-dom'
import Loader from './Loader'
import PropTypes from 'prop-types'
import store from '../store/store'
import { fetchStationIds } from '../actions/notificationActions'
import { connect } from 'react-redux'

import '../style/GuideContainer.scss'
import Guidelines from './Guidelines'
import GuidelineDetail from './GuidelineDetail'
import Mixpanel from '../common/Mixpanel'
import AppData from '../common/AppData'

class GuideContainer extends Component {
    constructor( props ){
        super( props )
        this.state = {
            titleState: '',
            isloading: true,
            guidelinesData : []
        }
    }

    componentDidMount(){
        this.getGuidelines()
        setTimeout( () => {
            if( store.getState().notificationReducer === false ){
                Mixpanel.onTroubleshootingListView( this.props.screenOrientation, this.state.guidelinesData.length )
            }
            if( this.props.incomingNotification === false ){
                this.props.guidelineSourceCheck( 'Menu' ) 
            }
        },2000 )
    }

    componentWillUnmount(){
        if( store.getState().notificationReducer === false ){
            Mixpanel.exitTroubleshootingListView( this.props.screenOrientation, this.state.guidelinesData.length )
        }
    }


    getGuidelines = () => {
        if( store.getState().notificationReducer.incomingNotification ){
            this.setState( { guidelinesData: store.getState().notificationReducer.singleGuideline } )
        }else{
            this.setState( { guidelinesData: store.getState().notificationReducer.guidelines } )
        }   
        setTimeout( () => {
            this.setState( { guidelinesData: store.getState().notificationReducer.guidelines } )
            this.setState( { isloading: false } )
        }, AppData.guidelineDelay )
    }

    
    
    // used in GuideLine component as a callback and set the title in state.
    setTitleState = ( title ) => {
        this.setState( { titleState : title} )
    }
 
    renderGuideView = () => {

         return(
             <Fragment>
                { this.state.titleState !== 'Tips for Speaker Placement' ? 
                <Route path='/app/help/guides/steps/:id' render={ () => <GuidelineDetail titleState={this.state.titleState} 
                    guidelinesData={this.state.guidelinesData} incomingNotification={this.props.incomingNotification} 
                    singleGuideline={this.props.singleGuideline} filteredTitle={this.props.filteredTitle}
                    transformBanner={this.props.transformBanner} guideSource={this.props.guideSource}/> }/> :
                <Route path='/app/help/guides/tips/:id' render={ () => <GuidelineDetail titleState={this.state.titleState} 
                    guidelinesData={this.state.guidelinesData} transformBanner={this.props.transformBanner} guideSource={this.props.guideSource} /> }/>
                 }  
             </Fragment>
             
         )
    }
    
    render() {
        const {  isloading, guidelinesData } = this.state
        return (
            <Fragment>
                <div className="guide-container">
                    { isloading ? 
                        <div className="loader-postion"> <Loader/> </div> :   
                        <div className="guidelines" >
                            {guidelinesData.map( ( guideline ) => {
                                if( guideline ){
                                    return ( 
                                        <Guidelines key={guideline._id} header={guideline.name} setTitleState={this.setTitleState}/> 
                                    )
                                } else {
                                    return null
                                }                                   
                                    } ) 
                            }
                        </div>
                            
                        } 
                </div>
                { this.renderGuideView() }
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth : state.auth
    }
  }
export default connect( mapStateToProps, { fetchStationIds } ) ( GuideContainer )

GuideContainer.propTypes = {
    screenOrientation: PropTypes.string,
    deviceStatus: PropTypes.array,
    guidelines: PropTypes.array,
    incomingNotification: PropTypes.bool,
    singleGuideline: PropTypes.array,
    filteredTitle: PropTypes.string,
    transformBanner: PropTypes.func,
    bannerClicked: PropTypes.bool,
    bannerPressed: PropTypes.func,
    guideSource: PropTypes.string,
    guidelineSourceCheck: PropTypes.func
}
