import mixpanel from 'mixpanel-browser'
import config from './config'

const Mixpanel = {
    newOrientation : '' ,
    lvtSessionStarted: '',
    superProperties : () => {        
        mixpanel.identify( config.storeId() )
        mixpanel.register( {
            'Store Number' :  +config.storeId(),
            'Device Type' : config.getDeviceType(),
            'Serial Number' : config.getDeviceId(),
            'Product Area' : 'LVT',
            'Orientation': window.innerWidth > 360 ? 'Landscape' : 'Portrait',
            'Store City' : config.loadCity(),
            'Store State' : config.loadState(),
            'Store ZoneId' : config.loadZoneId(),
            'Store Country' : config.loadCountry(),
            'Store MarketId' : config.loadMarketId()
        } )
    },
    init: () => {
        mixpanel.init( process.env.REACT_APP_MIXPANEL_TOKEN )
        Mixpanel.superProperties()
        Mixpanel.lvtSessionStarted = Date.now()
    },

    open: ( presentOrientation ) => {
        mixpanel.track( 'LVT Opened', {
            'Local Time Of The Day': Date.now(),
            'Device Orientation' : presentOrientation
        } )
    },
    closed: ( previousOrientation ) => {
        let lvtSessionFinished = Date.now()
        mixpanel.track( 'LVT Closed', {
            'Local Time Of The Day' : Date.now(),
            'Device Orientation' :  Mixpanel.newOrientation.length > 0 ? Mixpanel.newOrientation : previousOrientation,
            'duration' :  ( lvtSessionFinished - Mixpanel.lvtSessionStarted ) / 1000
        } )
    },
    orientationChanged: ( previousOrientation ) => {
        let changedDuration = Date.now()
        setTimeout( ()=>{
            Mixpanel.newOrientation =  window.innerWidth > 360 ? 'Landscape' : 'Portrait'
            mixpanel.track( 'Orientation changed',{
                'Previous Orientation' : previousOrientation,
                'New Orientation':  Mixpanel.newOrientation,
                'duration': ( changedDuration - Mixpanel.lvtSessionStarted ) / 1000
            } )
            mixpanel.register( { 'Orientation': window.innerWidth > 360 ? 'Landscape' : 'Portrait' } )
        },100 )
    },
    onLiveView: ( presentOrientation ) => {
        Mixpanel.liveViewOpenTime = Date.now()
        mixpanel.track( 'LVT Live View Viewed', {
            'Local Time Of The Day': Date.now(),
            'Device Orientation' : presentOrientation
        } )
    },
    exitLiveView : ( previousOrientation ) => {
        let liveViewClosedTime = Date.now()
        mixpanel.track( 'LVT Live View Closed', {
            'Local Time Of The Day': Date.now(),
            'Device Orientation' : Mixpanel.newOrientation.length > 0 ? Mixpanel.newOrientation : previousOrientation,
            'duration' : ( liveViewClosedTime - Mixpanel.liveViewOpenTime ) / 1000
        } )
    },
    onHistoryView: ( presentOrientation ) => {
        Mixpanel.historyViewOpenTime = Date.now()
        mixpanel.track( 'LVT History Viewed', {
            'Local Time Of The Day': Date.now(),
            'Device Orientation' : presentOrientation
        } )
    },
    exitHistoryView : ( previousOrientation ) => {
        let historyViewClosedTime = Date.now()
        mixpanel.track( 'LVT History Closed', {
            'Local Time Of The Day': Date.now(),
            'Device Orientation' : Mixpanel.newOrientation.length > 0 ? Mixpanel.newOrientation : previousOrientation,
            'duration' : ( historyViewClosedTime - Mixpanel.historyViewOpenTime ) / 1000
        } )
    },
    customerProfileViewed : ( presentOrientation, userStatus, previousView, orderId ) => {
        mixpanel.track( 'LVT Customer Profile Viewed', {
            'Local Time Of The Day': Date.now(),
            'Device Orientation' : presentOrientation,
            'User Status' : userStatus,
            'Previous View' : previousView,
            'Order ID': orderId
        } )
    },
    onFaqView: ( presentOrientation ) => {
        Mixpanel.faqViewOpenTime = Date.now()
        mixpanel.track( 'LVT Faq Viewed', {
            'Local Time Of The Day': Date.now(),
            'Device Orientation' : presentOrientation
        } )
    },
    exitFaqView : ( previousOrientation ) => {
        let faqViewClosedTime = Date.now()
        mixpanel.track( 'LVT Faq Closed', {
            'Local Time Of The Day': Date.now(),
            'Device Orientation' : Mixpanel.newOrientation.length > 0 ? Mixpanel.newOrientation : previousOrientation,
            'duration' : ( faqViewClosedTime - Mixpanel.faqViewOpenTime ) / 1000
        } )
    },
    onTroubleshootingListView : ( presentOrientation, count ) => {
        Mixpanel.troubleshootingListViewOpenTime = Date.now()
        mixpanel.track( 'Troubleshooting List Viewed', {
            'Local Time Of The Day': Date.now(),
            'Device Orientation' : presentOrientation,
            'Guide Count' : count
        } )
    },
    exitTroubleshootingListView : ( previousOrientation, count ) => {
        let troubleshootingListViewClosedTime = Date.now()
        mixpanel.track( 'Troubleshooting List Closed', {
            'Local Time Of The Day': Date.now(),
            'Device Orientation' : Mixpanel.newOrientation.length > 0 ? Mixpanel.newOrientation : previousOrientation,
            'duration' : ( troubleshootingListViewClosedTime - Mixpanel.troubleshootingListViewOpenTime ) / 1000,
            'Guide Count' : count
        } )
    },
    onTroubleshootingGuideView : ( presentOrientation, guideSelected, source, guideName ) => {
        Mixpanel.troubleshootingGuideViewOpenTime = Date.now()
        mixpanel.track( 'Troubleshooting Guide Viewed', {
            'Local Time Of The Day': Date.now(),
            'Device Orientation' : presentOrientation,
            'Guide Selected' : guideSelected,
            'Source': source,
            'Guide Name': guideName
        } )
    },
    exitTroubleshootingGuideView : ( previousOrientation, guideSelected ) => {
        let troubleshootingGuideViewClosedTime = Date.now()
        mixpanel.track( 'Troubleshooting Guide Closed', {
            'Local Time Of The Day': Date.now(),
            'Device Orientation' : Mixpanel.newOrientation.length > 0 ? Mixpanel.newOrientation : previousOrientation,
            'duration' : ( troubleshootingGuideViewClosedTime - Mixpanel.troubleshootingGuideViewOpenTime ) / 1000,
            'Guide Selected' : guideSelected
        } )
    },

    onReportView: ( presentOrientation ) => {
        Mixpanel.reportViewOpenTime = Date.now()
        mixpanel.track( 'LVT Report Viewed', {
            'Local Time Of The Day': Date.now(),
            'Device Orientation' : presentOrientation
        } )
    },
    exitReportView: ( previousOrientation ) => {
        let reportViewClosedTime = Date.now()
        mixpanel.track( 'LVT Report Closed', {
            'Local Time Of The Day': Date.now(),
            'Device Orientation' : Mixpanel.newOrientation.length > 0 ? Mixpanel.newOrientation : previousOrientation,
            'duration' : ( reportViewClosedTime - Mixpanel.reportViewOpenTime ) / 1000
        } )
    },
    onCurrentDayReportView: ( presentOrientation ) => {
        Mixpanel.reportViewOpenTime = Date.now()
        mixpanel.track( 'LVT Report Viewed', {
            'Local Time Of The Day': Date.now(),
            'Device Orientation' : presentOrientation,
            'Report Name': 'Current Day'
        } )
    },
    on7DayReportView: ( presentOrientation ) => {
        Mixpanel.reportViewOpenTime = Date.now()
        mixpanel.track( 'LVT Report Viewed', {
            'Local Time Of The Day': Date.now(),
            'Device Orientation' : presentOrientation,
            'Report Name': '7-Day'
        } )
    },
    on30DayReportView: ( presentOrientation ) => {
        Mixpanel.reportViewOpenTime = Date.now()
        mixpanel.track( 'LVT Report Viewed', {
            'Local Time Of The Day': Date.now(),
            'Device Orientation' : presentOrientation,
            'Report Name': '30-Day'
        } )
    },
    troubleshootingResponse: ( response, source, guideName ) => {
        mixpanel.track( 'LVT Troubleshooting Response', {
            'Did this solve your problem': response,
            'Source': source,
            'Guide Name': guideName
        } )
    },
    orderConfirmedSuccess: () => {
        mixpanel.track( 'LVT Transaction Completed', {
            'Local Time of The Day': Date.now(), 
        } )
    },
    feedbackOpened : ( associateId, feedbackOpenedFrom ) => {
        mixpanel.track( 'Feedback Opened', {
            'Associate ID': associateId,
            'Feedback Opened From': feedbackOpenedFrom
        } )
    },
    feedbackSubmitted : ( starRating,feedback,associateId,feedbackSubmittedFrom ) => {
        mixpanel.track( 'Feedback Submitted', {
            'Star Rating': starRating,
            'Feedback': feedback,
            'Associate ID': associateId,
            'Feedback Submitted From': feedbackSubmittedFrom
        }  )
    },
    feedbackClosed : ( associateId,feedbackClosedFrom ) => {
        mixpanel.track( 'Feedback Closed', {
            'Associate ID': associateId,
            'Feedback Closed From': feedbackClosedFrom
        } )
    },
    whatsNewViewed : ( presentOrientation ) => {
        Mixpanel.whatsNewViewedTime = Date.now()
        mixpanel.track( 'What\'s New Viewed', {
            'Local Time Of The Day': Date.now(),
            'Device Orientation' : presentOrientation,
        } )
    },
    whatsNewClosed : ( presentOrientation ) => {
        let whatsNewClosedTime = Date.now()
        mixpanel.track( 'What\'s New Closed', {
            'Local Time Of The Day': Date.now(),
            'Device Orientation' : presentOrientation,
            'duration': ( whatsNewClosedTime - Mixpanel.whatsNewViewedTime ) / 1000
        } )
    },
    lvtVideoViewed : ( presentOrientation ) => {
        Mixpanel.lvtVideoViewedTime = Date.now()
        mixpanel.track( 'LVT Video Viewed', {
            'Local Time Of The Day': Date.now(),
            'Device Orientation' : presentOrientation,
        } )
    },
    lvtVideoClosed : ( presentOrientation ) => {
        let lvtVideoClosedTime = Date.now()
        mixpanel.track( 'LVT Video Closed', {
            'Local Time Of The Day': Date.now(),
            'Device Orientation' : presentOrientation,
            'duration': ( lvtVideoClosedTime - Mixpanel.lvtVideoViewedTime ) / 1000
        } )
    },
    notificationCartOpened : ( presentOrientation, loyaltyId ) => {
        mixpanel.track( 'Notification Cart Opened', {
            'Local Time Of The Day': Date.now(),
            'Device Orientation' : presentOrientation,
            'Loyalty_ID': loyaltyId
        } )
    },
    hardwareAlertTap: ( alertType,deviceType,screen,identifier,source ) => {
        mixpanel.track( 'Hardware Alert Tap', {
         alertType: alertType,
         deviceType: deviceType,
         screen: screen,
         identifier: identifier,
         source: source,
         'Local Time Of The Day': Date.now()
        } )
    }

}

export default Mixpanel

