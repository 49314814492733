import React, {Component ,Fragment} from 'react'
import _ from 'underscore'
import PropTypes from 'prop-types'
import AppData from '../common/AppData'
import '../style/Tips.scss'
import { strings } from '../context/LanguageContext/strings'

class Tips extends Component {
    constructor( props ){
      super( props )
      this.state = {
        presentTip : '',
        totalTips : []
      }
    }

    // Lifecycle Method runs after the first render
    componentDidMount() {
      this.getTips()
    }

    // Lifecycle Method runs if props get updated 
    componentDidUpdate( prevProps ){
      if( prevProps.allTips !== this.props.allTips ){
        this.getTips()
      }
    }

    // Start timer added  setInterval call on getRandomTips
    startTimer() {
      this.timer = setInterval( ()=>{
        this.getRandomTips()
      }, AppData.tipInterval )
    }
    
    //Getting tips from props
    getTips = () => {
      let tips = {}
      if( this.props.allTips && this.props.allTips.length > 0 ){
        tips = _.first( this.props.allTips.filter( ( item ) => item.id === 'tips' ) ).current_value
        this.setTips( tips )
        this.startTimer()
      } 
    }

    setTips = ( tips ) => {
      ( () => {
        this.setState( {
          totalTips: tips,
          presentTip : _.first( tips )
        } )
      } )() 
    }

    //random tip generator 
    getRandomTips = () => {
      let random = Math.floor( Math.random() * this.state.totalTips.length )
      this.setState( {
        presentTip : this.state.totalTips[random]
      } )
    }

    // Lifecycle Method runs to clear the interval timer to clear the stack
    componentWillUnmount(){
      clearInterval( this.timer )
    }

    render(){
        return(
          <Fragment>
                 { this.props.allCartsAndOrders && this.props.allCartsAndOrders.length === 0  ?
                 <div className="empty-cart-container">
                    <div className="tip-icon">
                    {/* <img src={logo} alt="information"/> */}
                    </div>
                    <div className="tip-title">
                      {strings.mobileCheckoutTipsText}
                    </div>
                    <div className="tip-text">
                      {this.state.presentTip}
                    </div>
                </div> : null }
          </Fragment>
        )
    }
}

Tips.propTypes = {
  allCartsAndOrders: PropTypes.array,
  allTips: PropTypes.array,
}

export default Tips
