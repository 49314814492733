import React, { Fragment, useContext, useState } from 'react'
import PropTypes from 'prop-types'

import '../style/HelpTabs.scss'
import { LanguageContext } from '../context/LanguageContext'
export default function HelpTabs( props ) {
    const { strings } = useContext( LanguageContext )
    const [opacity, setOpacity] = useState( 'none' )
    const {title,description,onTabsClick} = props
    
    const tabsToDisplay = ( ) => {
        
        return ( 
            <Fragment>
            <div id={title} className="help-wrapper" onTouchStart={() => setOpacity( 0.5 )} onTouchEnd={() => { onTabsClick( title ) ; setOpacity( 1 ) }}>
                <div className="help-detail" style={{opacity:`${opacity}`}}>
                    <div className="help-title">{strings[title]}</div>
                    <div className="help-description">{strings[description]}</div>
                </div>
            </div> 
        </Fragment>
        )
    }
    
    return (
        props.title === 'FAQ & Videos' ? ( props.faqMenuToggle ? tabsToDisplay() : null ) : 
        props.title === 'Troubleshooting Guides' ? ( props.troubleshootingMenuToggle ? tabsToDisplay() : null ) : tabsToDisplay()
    )
}

HelpTabs.propTypes = {
    title : PropTypes.string,
    description: PropTypes.string,
    onTabsClick: PropTypes.func,
    opacity: PropTypes.func,
    faqMenuToggle: PropTypes.bool,
    troubleshootingMenuToggle: PropTypes.bool
}
