import React from 'react'
import PropTypes from 'prop-types'

import '../style/Insights.scss'
import InsightContent from './InsightContent'

export default function Insights( { insightContent } ) {
    return (
        <div className="insights-wrapper">
            <div className="insights-header">
                Insights
            </div>
            <div className="insights-divider"></div>
            <div className="insights-content-container">
                {insightContent !== undefined ? insightContent.map( ( { title, descriptions } ) => {
                    return <InsightContent key={title} title={title} descriptions={descriptions}/>
                } ) : null}
            </div>
        </div>
    )
}

Insights.propTypes = {
    insightContent: PropTypes.array
}
