import AppData from './AppData'
import network from '../common/network'
import logger from '../common/logger'
// import history from '../history'
import AppAnalytics from './AppAnalytics'
import uuid from 'uuid'
import axios from 'axios'
import _ from 'underscore'

let timer = AppData.topicRefreshPeriod

export const checkLandscape = () => {
    return ( window.innerWidth > 361 )? 'Landscape' : 'Portrait' 
}


export const delay = ( ms ) => {
    return new Promise( response => setTimeout( response, ms ) )
}

export const navbarConfirmedObject = ( data ) => {
    let newItem = []
    let lastItem = null
    data.items.forEach( ( item ) => {
        if( ( item.product_id && item.name && item.price ) !== undefined  ){
            if ( item.price < 0 ||  item.ProductType === 62 || item.ProductType === 53 || item.ProductType === 54 ) {
                if ( lastItem ) {
                    let vmItemDeal = {}
                    vmItemDeal.Description = item.name
                    vmItemDeal.SubTotal = item.price * item.quantity
                    vmItemDeal.ProductType = item.ProductType ? item.ProductType : ''
                    lastItem.deals.push( vmItemDeal )
                }
                    return 
                }
                    let vmItem = {}
                    vmItem.deals = []
                    vmItem.product = {
                    name: item.name,
                    price: item.price,
                    product_id: item.product_id
                    }
                    vmItem.quantity = item.quantity
                    vmItem.deposit_amount = item.deposit_amount
                    lastItem = vmItem
                    newItem.push( vmItem )
                    return null
                    }
    } )
    return {
        arbitrationToken : data.arbitrationToken,
        customer_id: undefined,
        grandTotal: data.total,
        isOrderConfirmed: AppData.isStoreShowAndGo() ? true : data.is_order_confirmed,
        itemType: 'order',
        items: newItem,
        orderStatus: data.order_status,
        order_id: data.order_id,
        transaction_icon: true,
        transaction_status: false,
        showAndGoIconCheck: AppData.isStoreShowAndGo() ? true : false,
        updated_time: data.created,
        user_profile: data.user_profile,
        _id: data._id
    }
}

export const cartModal = ( carts ) => {
    let viewModels = []
    if ( carts === undefined || carts.length < 1 ) {
        return viewModels
    }
    carts.map( ( cart ) => {
        let vm = {}
        if ( cart.user_profile ) {
            let user_profile =  cart.user_profile 
            if ( user_profile ) {
                vm.user_profile = {
                    first_name: _.isArray( user_profile ) ? _.first( user_profile.first_name ) : user_profile.first_name,
                    last_name: _.isArray( user_profile ) ? _.first( user_profile.last_name ) : user_profile.last_name,
                    email: _.isArray( user_profile ) ? _.first( user_profile.email ) : user_profile.email,
                    loyalty_id: _.isArray( user_profile ) ? _.first( user_profile.loyalty_id ) : user_profile.loyalty_id
                }
            }
        }
        vm._id = cart._id
        vm.customer_id = cart.customer_id
        vm.grandTotal = cart.grandTotal
        vm.updated_time = cart.updated_time
        vm.arbitrationToken = cart.arbitrationToken
        vm.items = []
        vm.itemType = cart.itemType
        vm.cartWithDeals = cart.cartWithDeals
        cart.items.map( ( item ) => {
        let vmItem = {}
        vmItem.product_description = item.product_description 
        vmItem.deals = []
        if ( item.deals && item.deals.length > 0  ) {
            item.deals.map( ( deal ) => {
                let vmItemDeal = {}
                vmItemDeal.Description = deal.Description
                vmItemDeal.SubTotal = deal.SubTotal
                vmItem.deals.push( vmItemDeal )
                return null
                } )
            }
            vmItem.product = ( item.product !== undefined ) ? {
                name: item.product.name ? item.product.name : item.product_name,
                price: item.product.price ? item.product.price : item.price,
                product_id: item.product_id ? item.product_id : item.product.product_id
            }:{
                name: item.product_id,
                price: 0,
                product_id: item.product_id
            }
        vmItem.eco = []
        if ( item.eco && item.eco.length > 0  ) {
                item.eco.map( ( deal ) => {
                    let vmItemDeal = {}
                    vmItemDeal.Description = deal.Description
                    vmItemDeal.Total = deal.Total
                    vmItemDeal.ProductType = deal.ProductType
                    vmItem.eco.push( vmItemDeal )
                    return null
                    } )
            }
        vmItem.deposit = []
        if ( item.deposit && item.deposit.length > 0  ) {
            item.deposit.map( ( deal ) => {
                let vmItemDeal = {}
                vmItemDeal.Description = deal.Description
                vmItemDeal.Total = deal.Total
                vmItemDeal.ProductType = deal.ProductType
                vmItem.deposit.push( vmItemDeal )
                return null            
                } )
            }
        vmItem.quantity = item.quantity
        if ( item.product !== undefined ){
            vm.items.push( vmItem )
        }
            return null
        } )
        viewModels.push( vm )
        return null
    } )
    return viewModels
}

/**
 * Mapping products details with cart details using productId
 * @param {*} cartDetails 
 * @param {*} productsData 
 */
export const getCartWithDeals = ( cartDetails , productsData ) => {
    cartDetails.map( ( cart ) => { 
        cart.itemType = 'cart'
        let cartWithDealsMap = {}
        let mapItemProducts = {}
        cart.cartWithDeals.forEach( ( cartWithDeal ) => {
            cartWithDealsMap[cartWithDeal.Suiid] = cartWithDeal
        } )

        if( productsData === null || productsData.length === 0 ){
            cart.items.reverse().map( ( item ) => {
                mapItemProducts = {
                    ...mapItemProducts,

                    age_restricted : item.age_restricted,
                    age_restriction : item.age_restriction, 
                    name : item.name,
                    price : item.price,
                    suiid : item.suiid,
                    thumbnail : item.thumbnail,
                    quantity : item.quantity
                } 
                item.product = mapItemProducts
                if ( 'suiid' in item ) {
                    let deals = ( cartWithDealsMap[mapItemProducts['suiid']] === undefined ) ? null :cartWithDealsMap[mapItemProducts.suiid].Deals
                    let deposit = ( cartWithDealsMap[mapItemProducts['suiid']] === undefined ) ? null :cartWithDealsMap[mapItemProducts.suiid].Deposit
                    let eco = ( cartWithDealsMap[mapItemProducts['suiid']] === undefined ) ? null :cartWithDealsMap[mapItemProducts.suiid].Eco
                    item.deals = deals
                    item.deposit = deposit
                    item.eco = eco
                }
                return null
            } ) 
        }else{
            let productIdMap = {}
            productsData.map( ( product ) => ( product && product.product_id !== undefined ) ? productIdMap[product.product_id] = product: null )
    
            cart.items.reverse().map( ( item ) => {
                let product = productIdMap[item.product_id]
                item.product = product

                if ( product && 'suiid' in product ) {
                    let deals = ( cartWithDealsMap[product['suiid']] === undefined ) ? null :cartWithDealsMap[product.suiid].Deals
                    let deposit = ( cartWithDealsMap[product['suiid']] === undefined ) ? null :cartWithDealsMap[product.suiid].Deposit
                    let eco = ( cartWithDealsMap[product['suiid']] === undefined ) ? null :cartWithDealsMap[product.suiid].Eco
                    item.deals = deals
                    item.deposit = deposit
                    item.eco = eco
                }
                return null
            } ) 
        }
        return null
    } )

    cartDetails = cartDetails.reverse()
    return cartDetails
}
/**
 * Convert time in format : HH:MM AM/PM
 * @param {*} currentDateTime 
 */
export const getUpdatedTime = ( reportDate ) => {

    let currentDate = ''
    if( reportDate.length > 0 ){
        currentDate = new Date( reportDate )
    }
    let dayWithDate = `${currentDate.toDateString().split( ' ' )[0]}, ${currentDate.getMonth() + 1}/${currentDate.getDate()}, `
    currentDate = currentDate.toLocaleTimeString()
    let timeFormat = currentDate.split( ':' ).slice( 0, 2 ).join( ':' ) + currentDate.split( ':' ).slice( 2 ).join( ' ' ).split( ' ' ).pop()
    let updateTime = dayWithDate + timeFormat.toLowerCase()
    return updateTime
}


export const topicConnection = ( data ) => {
    logger.info( 'NA', 'pubsub fetch', 'pubsub_subscribe', 'NA', 'Topic Connected/disconnected', data )
    AppData.isError = true
    AppData.errorMessage = 'NO_INTERNET_ACCESS'
    AppAnalytics.trackReloading( 'Reloading app from topicConnection' )
    console.log( 'Reloading app from topicConnection' )
    // history.push( '/app' )
    if( navigator.onLine === true && AppData.isError === true ){
      setTimeout( () => {
        AppAnalytics.trackReloading( 'Reloading app from topicConnection' )
        console.log( 'Reloading app from topicConnection' )
        // history.push( '/app' )
      }, timer )
    }
}

export const getTokenCall = ( completionHandler , errorHandler ) => {
      let config = {}
      let deviceProfileText = ( window.Android && window.Android.getDeviceProfile ) ? window.Android.getDeviceProfile() : ''
      logger.info( 'NA', 'fetch device profile in home', 'fetchStoreId', 'NA', 'success', deviceProfileText + '' )
      let storeId = localStorage.getItem( 'storeId' )  
      let Uuid = uuid.v4()
      let configData = {
        'store_id' : storeId,
         'uuid' : Uuid
        }
        network.getJWTToken(
          configData,
          ( res )=>{
              const deviceId = JSON.parse( localStorage.getItem( 'lvt.config' ) ).deviceId
              const deviceType = JSON.parse( localStorage.getItem( 'lvt.config' ) ).deviceType
              localStorage.removeItem( 'lvt.config' )
              const response = res
              config = Object.assign( config,  {'deviceId': deviceId , 'deviceType': deviceType, 'storeId' : storeId, 'jwt' : response.data.jwt_token , systemIdentifier: Uuid  , setupComplete : true} )
              localStorage.setItem( 'lvt.config', JSON.stringify( config ) )
            //   let newLvtConfig = JSON.parse( localStorage.getItem( 'lvt.config' ) )
              completionHandler()
          }, 
          ( error )=>{
              logger.error( 'NA', 'JWT token fetch', 'get_JWTtoken', 'NA', 'JWT token fetch', error )
            // TODO: Once successfull implementation of login uncomment below line.
            // config.setSetupComplete( false )
            // history.push( '/' )
              errorHandler( error )
          }
      ) 
}

export const historyCount = async () => {
    let currentDate = new Date()
      let yesterdayDate = new Date( new Date().setDate( new Date().getDate() - AppData.previousOrderPerDays ) ) 
      let query = {
          'created' : { $lt: currentDate , $gte: yesterdayDate}
      }
      network.getOrdersConfirmed(
          ( response )=>{
            let ordersData = response && response.data ? response.data : []   
            // eslint-disable-next-line no-unused-vars
            let ordersDataCount = ordersData && ordersData.orders ? ordersData.orders : []
             ordersDataCount = ordersDataCount.filter( ( order )=>{
                if( this.getUnwantedName( order.user_profile.first_name ) ){
                return false
                  }
                  if( order.order_status === 'selfcheckedout' ){
                      return true
                  } 
                  return null
                } ).length      
          },
          ( error )=>{
              console.log( error )
          },
          query
      )
}

export const setHeader =  ( originalRequest ) => {
    return new Promise( ( resolve, reject ) => {
        getTokenCall( () => { 
            const newToken = JSON.parse( localStorage.getItem( 'lvt.config' ) ).jwt
            let headers = {
                'Content-Type': 'application/json',
                'x-api-key':process.env.REACT_APP_API_KEY ,
            }
            const header = {
                ...headers, Authorization: `Bearer ${newToken}`,
            }
            axios.defaults.headers = header
            originalRequest.headers = header
            return axios( originalRequest )
        } ,
        ( error ) => {
            reject( error )
        } ) 
    } )       
}

export const getCurrentStoreId =  () => {
    return localStorage.getItem( 'storeId' )
}