import { DEVICE_STATUS, GUIDELINES } from './types'
import network from '../common/network'
import store from '../store/store'


// fetch stationIDs and call guidelines
export const fetchStationIds = ( hardwareNotificationPathCheck ) => async ( dispatch ) => {
    // getting station version Ids from localStorage
    let stationIds = JSON.parse( JSON.stringify( localStorage.getItem( 'lvt.stations' ) ) )

    // check if stationId doesnt exist in localStorage, make fetchStation api call
    if( !stationIds ){
      await network.getDeviceStatus(
        ( response ) => {
          let deviceStatusIds = []
          response.data.device.forEach( ( deviceId ) => {
            if( deviceId.stationVersion ){
              deviceStatusIds.push( deviceId.stationVersion )
            }
          } )
          localStorage.setItem( 'lvt.stations' , JSON.stringify( deviceStatusIds ) )  
          dispatch ( {
            type: DEVICE_STATUS,
            payload:deviceStatusIds
        } )
        network.getGuidelines(
            ( response ) => {
            if( store.getState().notificationReducer.deviceStatus.length === 0 ){
                dispatch ( {
                    type: DEVICE_STATUS,
                    payload:['SNP02']
                } )
            }
                let filteredResponse = []
                response.forEach( ( item ) => {
                    if( item.tags.includes( store.getState().notificationReducer.deviceStatus[0] ) ){
                        filteredResponse.push( item )
                    }
                } )
                dispatch( {
                    type: GUIDELINES,
                    payload:filteredResponse
                } )
        
                // Hardware notification check
                hardwareNotificationPathCheck()         
            },
            ( error )=>{
                console.log( error )
            }
        )
        },
        ( error ) => {
          console.log( error )
          localStorage.setItem( 'lvt.stations' , JSON.stringify( ['SNP02'] ) )
          dispatch ( {
            type: DEVICE_STATUS,
            payload:['SNP02']
        } )
        
        network.getGuidelines(
            ( response ) => {
            if( store.getState().notificationReducer.deviceStatus.length === 0 ){
                dispatch ( {
                    type: DEVICE_STATUS,
                    payload:['SNP02']
                } )
            }
                let filteredResponse = []
                response.forEach( ( item ) => {
                    if( item.tags.includes( store.getState().notificationReducer.deviceStatus[0] ) ){
                        filteredResponse.push( item )
                    }
                } )
                dispatch( {
                    type: GUIDELINES,
                    payload:filteredResponse
                } )
        
                // Hardware notification check
                hardwareNotificationPathCheck()         
            },
            ( error )=>{
                console.log( error )
            }
        )
        }
      )
    }

    //If stationId exist in localStorage, only make Guideline api call
    if( stationIds ){
        network.getGuidelines(
            ( response ) => {
            if( store.getState().notificationReducer.deviceStatus.length === 0 ){
                dispatch ( {
                    type: DEVICE_STATUS,
                    payload:['SNP02']
                } )
            }
                let filteredResponse = []
                response.forEach( ( item ) => {
                    if( item.tags.includes( store.getState().notificationReducer.deviceStatus[0] ) ){
                        filteredResponse.push( item )
                    }
                } )
                dispatch( {
                    type: GUIDELINES,
                    payload:filteredResponse
                } )
        
                // Hardware notification check
                hardwareNotificationPathCheck()         
            },
            ( error )=>{
                console.log( error )
            }
        )
    }
  }