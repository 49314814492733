import axios from 'axios'

import { REACT_APP_LOCALIZATION_BASE_PATH } from '../../config/environment'

export default async ( { component, app, platform, language } ) => {

  const res = await axios( {
    method: 'GET',
    url: `${REACT_APP_LOCALIZATION_BASE_PATH}/${component}/${app}-${platform}.${language}`,
    headers: {
        'Content-Type': 'application/json',
    },
  } )

  return res.data.data
}
