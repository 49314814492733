import { USER_LOGIN, LOGIN_NEEDED, IS_SESSION_NEEDED, USER_ID,
        SUCCESS_LOGIN, DISMISS_LOGIN, AUTO_DISMISS_LOGIN, FAILED_LOGIN, DISPLAY_LOGIN_INACTIVE, VIDEO_ENABLED, IS_LOGGEDIN } from './types'
import history from '../history'
import AppAnalytics from '../common/AppAnalytics'
import logger from '../common/logger'
import network from '../common/network'
import AppData from '../common/AppData'


export const userLogin = ( ) => {
        let usersSession = window.Android && window.Android.userSession ? window.Android.userSession() : ''
        if( usersSession ){
            usersSession = JSON.parse( usersSession )
        } else {
            AppAnalytics.missingContract( 'userLogin', 'User Login Failed' )
        }
        return {
            type: USER_LOGIN,
            payload:usersSession
        }
    }
//For write mode
export const loginNeeded = ( title ) => ( dispatch ) => {

        let userLoginNeeded = window.Android && window.Android.loginIfNeeded ? window.Android.loginIfNeeded() : ''
        userLoginNeeded = window.Android && !window.Android.loginIfNeeded ?  '{"validUserSession": true , "userDismiss": false, "autoDismiss": false}' : userLoginNeeded
        if( userLoginNeeded ){
            userLoginNeeded = JSON.parse( userLoginNeeded )
        }
        else{
            AppAnalytics.missingContract( 'loginNeeded', 'Login Needed Failed' )
            return 
        }
        dispatch( {
            type: LOGIN_NEEDED,
            payload: userLoginNeeded
        } )
        if( userLoginNeeded.validUserSession ) {
            dispatch( {
                type: SUCCESS_LOGIN,
            } )
        } 
        if( !userLoginNeeded.validUserSession && !userLoginNeeded.userDismiss ) {
            dispatch( {
                type: FAILED_LOGIN,
            } )
        }
        if( userLoginNeeded.userDismiss ) {
            dispatch( {
                type: DISMISS_LOGIN
            } )
        }
        if( userLoginNeeded.autoDismiss ) {
            dispatch( {
                type: AUTO_DISMISS_LOGIN
            } )
        }
        if( userLoginNeeded.validUserSession && title && title.length > 0 ){
            history.push( `/app/${title}` )
        } 
    }

export const isSessionUpdated = ( isValidSession, isVideoClicked ) => dispatch  => {
        let userSessionUpdated = {}
        if( isValidSession === false && ( history.location.pathname === '/app/help' || history.location.pathname === '/app/help/feedback'
        || history.location.pathname === '/app/help/faq' || history.location.pathname === '/app/help/guides'
        || history.location.pathname === '/app/reports' || history.location.pathname === '/app/whatsNew'
        || history.location.pathname === '/app/feedback' || ( history.location.pathname === '/app' && isVideoClicked )  ) ){
            userSessionUpdated = window.Android && window.Android.loginIfNeeded ? window.Android.loginIfNeeded() : ''
            userSessionUpdated = window.Android && !window.Android.loginIfNeeded ? '{"validUserSession": true}' : userSessionUpdated
            if( userSessionUpdated ){
                userSessionUpdated = JSON.parse( userSessionUpdated )
            } else {
                AppAnalytics.missingContract( 'isSessionUpdated', 'Is Session Updated Failed' )
            }
        }

        if( userSessionUpdated.validUserSession === false ){
            dispatch( {
                type: IS_SESSION_NEEDED,
                payload: userSessionUpdated
            } )
            history.push( '/app' )
            dispatch( {
                type: FAILED_LOGIN,
            } )
            dispatch ( {
                    type: VIDEO_ENABLED
            } )
        }
        if( userSessionUpdated.validUserSession === true ){
            dispatch( {
                type: SUCCESS_LOGIN,
            } )
        }
        if( userSessionUpdated.userDismiss ){
            dispatch( {
                type: DISMISS_LOGIN
            } )
        }
        dispatch( {
            type: IS_SESSION_NEEDED,
            payload: userSessionUpdated
        } )
    }

export const userId = ( ) => {
        let usersId = window.Android && window.Android.userProfile ? window.Android.userProfile() : ''
        if( usersId ){
            usersId = JSON.parse( usersId )
        } else {
            AppAnalytics.missingContract( 'userId', 'User Id Failed' )
        }
        return {
            type: USER_ID,
            payload:userId
        }
}

export const trackLoginModal = ( isValidSession ) => ( dispatch ) => {
        if( isValidSession === false && ( history.location.pathname === '/app/help' || history.location.pathname === '/app/help/feedback'
        || history.location.pathname === '/app/help/faq' || history.location.pathname === '/app/help/guides'
        || history.location.pathname === '/app/reports' || history.location.pathname === '/app/whatsNew'
        || history.location.pathname === '/app/feedback' ) ){
            dispatch ( {
                type: DISPLAY_LOGIN_INACTIVE
            } )
        }
}

export const videoEnabled = ( ) => ( dispatch ) => {
    dispatch ( { 
        type: VIDEO_ENABLED
    } )
} 

export const isLoggedIn = ( data ) => ( dispatch ) => {
    dispatch ( { 
        type: IS_LOGGEDIN,
        payload: data
    } )
} 

export const homeTokenCall = ( configData, config, isSysConfigValid, errorState ) => {
    network.getJWTToken(
        configData,
        ( res )=>{
            localStorage.setItem( 'lvt.persistence.refreshToken', JSON.stringify( true ) )
            config = Object.assign( config,  {'storeId' : this.storeId , 'jwt' : res.data.jwt_token , systemIdentifier: this.uuid  , setupComplete : true} )
            localStorage.setItem( 'lvt.config', JSON.stringify( config ) )
            if ( config.storeId && config.jwt.length > 0 ) {
                isSysConfigValid ()
            }
        }, 
        ( error )=>{
            logger.error( 'NA', 'JWT token fetch', 'get_JWTtoken', 'NA', 'JWT token fetch', error )
            if( error ){
                AppData.errorMessage = 'GET_JWT_FAILED' 
                errorState()
            }
        }
    )
}
