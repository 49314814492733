import { combineReducers } from 'redux'
import configReducer from './configReducer'
import authReducer from './authReducer'
import notificationReducer from './notificationReducer'
import languageChange from '../modules/languageChange/reducers'

export default combineReducers( {
  configReducer: configReducer,
  auth: authReducer,
  notificationReducer: notificationReducer,
  languageChange: languageChange,
} )
