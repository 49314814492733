/* eslint-disable no-case-declarations */
import { USER_LOGIN, LOGIN_NEEDED, IS_SESSION_NEEDED, DISPLAY_LOGIN_INACTIVE,
    USER_ID, SUCCESS_LOGIN, DISMISS_LOGIN, FAILED_LOGIN, VIDEO_ENABLED, IS_LOGGEDIN } from '../actions/types'

import AppAnalytics from '../common/AppAnalytics'

const initialState = {
   'validUserSession': false ,
   'userDismiss': false,
   'autoDismiss': false,
   'userId':'',
   'successLogin': 0,
   'dismissLogin': 0,
   'failedLogin': 0,
   'displayLoginInactive': 0,
   'videoEnabled':true,
   'isLoggedIn': false
}

export default function ( state = initialState, action ){
   
   switch( action.type ){
       case USER_LOGIN:
           return {...state, ...action.payload }
       case LOGIN_NEEDED:
           return {...state, ...action.payload }
       case IS_SESSION_NEEDED:
           return {...state, ...action.payload }
       case USER_ID:
           return {...state, ...action.payload }
       case SUCCESS_LOGIN:
            const successCapture = { ...state, successLogin: state.successLogin + 1 }
               AppAnalytics.captureSuccessLogin( successCapture.successLogin )
                return successCapture
       case DISMISS_LOGIN:
           const dismissCapture = { ...state, dismissLogin: state.dismissLogin + 1 }
                AppAnalytics.captureDismissLogin( dismissCapture.dismissLogin )
               return dismissCapture
       case FAILED_LOGIN:
           const failedCapture = { ...state, failedLogin: state.failedLogin + 1 }
               AppAnalytics.captureFailedLogin( failedCapture.failedLogin )
                return failedCapture
       case DISPLAY_LOGIN_INACTIVE:
           const inactiveCapture = { ...state, displayLoginInactive: state.displayLoginInactive + 1 }
                AppAnalytics.captureDisplayLoginInactive( inactiveCapture.displayLoginInactive )
                return inactiveCapture
       case VIDEO_ENABLED:
           return {...state, videoEnabled: !state.videoEnabled }
       case IS_LOGGEDIN:
            return {...state, isLoggedIn: action.payload }
       default:
           return state
   }
}
