
export const whatsNewData = [
    {
        title: 'Feedback',
        released: 'Released 14 Nov 2020',
        description: 'Send us feedback on your store\'s experience with Mobile Checkout. We want to hear from you!',
        classname: 'wts-new-feedback',
        link: 'Feedback'
    },
    {
        title: 'Reports',
        released: 'Released 06 Oct 2020',
        description: 'Analyze your store\'s performance with charts of Mobile Checkout transaction.',
        classname: 'wts-new-report',
        link: 'Report'
    },
    {
        title:'Troubleshooting Guides',
        released: 'Released 20 Oct 2020',
        description:'Step-by-step guidance to fix common device problems on your own.',
        classname: 'wts-new-troubleshoot',
        link: 'Troubleshoot'
    },
    {
        title: 'First-Scan Notifications',
        released: 'Released 11 Aug 2020',
        description: 'Message with sound notifies you when a new mobile checkout customer has started scanning, so you can monitor their activity on the Mobile Checkout. NOTE: Turn up volume on 7MD to hear the alert sound on the device.',
        classname: 'wts-new-notifications',
        link: null
    },
]
