import React , {Fragment, useContext }from 'react'
import AppData from '../common/AppData'
import '../style/StoreInput.scss'
import { LanguageContext } from '../context/LanguageContext'
import {useState} from 'react'
import network from '../common/network'
function StoreInput () {
    const { strings } = useContext( LanguageContext )

    const [errorMessage, setErrorMessage] = useState( '' )
    const [loading, setLoading] = useState( false )

    const storeIdInput = React.useRef()

    const handleSubmit = () => {

        let storeId = storeIdInput.current.value

        if( !storeId || ( storeId && storeId.length !== 5 ) ){
            setErrorMessage( 'Enter valid Store Id' )
            return
        }
        setErrorMessage( '' )

        setLoading( true )

        network.getStoreDetails(
            storeId,
            ( res )=>{
                setLoading( false )
                AppData.storeId = storeId
                localStorage.setItem( 'storeId', storeId )
                window.location.reload()
            }, 
            ( error )=>{
                setLoading( false )
                setErrorMessage( 'Enter valid Store ID' )
                return
            }
        )
    }

    const renderStoreSetUp = () => {  
        return (
            <Fragment>    
                <div className="refresh">
                    <input type="text" ref={storeIdInput} maxLength="5" placeholder='Enter Store ID'/>
                    <div className = 'store-error-message'>{errorMessage}</div>
                    {loading ? <div className='loading-container'>LOADING<div className="loader"></div></div>
                     :<button type="button" className="button-1" onClick={handleSubmit}>CONFIRM</button>}
                </div>
            </Fragment>
        )
    }

    return(
        <div className="store-container">
            <div className="store-content">
                <div className="store-content-top">
                    
                    <div className="store-title">{strings.provideStoreId}</div> 
                      
                    <div className="info-icon-wrapper">
                        <div className="info-icon"></div>
                    </div>
                </div>
                { renderStoreSetUp() }
            </div>
        </div>
    )
}

export default StoreInput
