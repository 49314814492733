import React from 'react'
import { authClient } from '../common/helper'
import { strings } from '../context/LanguageContext/strings'
import '../style/Logout.scss'
import '../style/Navbar.scss'


const Logout = () =>{
    const onLoginClick = () =>{
        authClient.authorize()
    }
return (
    <div>
        <div className='navbar-main'>
            <div className='navbar-container'>
                <div className='lvt-icon-container'>
                <div className="lvt-icon"></div>
                </div>
                <div className="navbar-title-container">
                <div className='navbar-title'>{strings['showAndGoText']}</div>
            </div>
            </div>
        </div>
        <div className='logout'>
            <h2>{strings.logOutDescription}</h2>
            <button className='btn-login' onClick={ onLoginClick }  >{strings.logIn}</button>
        </div>
    </div>
)
}
export default Logout