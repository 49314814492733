import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'

import UserName from './UserName'

export default function HistoryOrders( { order , handleModalOpen } ) {

    const [opacity, setOpacity] = useState( 'none' )

    //rendering total quantity
    const totalQuantity = ( order ) => {
        let total = order.items
            .map( item => {
                if ( item.price <= 0 || item.ProductType ) {
                    return 0
                }
                return item.quantity
            } )
            .reduce( ( previouVal, currentVal ) => {
                return previouVal + currentVal
            }, 0 )
        return total
    }

    //rendering total amount
    const totalAmount = ( order ) => {
        let grandTotal = '$' + ( order.total / 100 ).toFixed( 2 ).toString()
        return grandTotal
    }

    //rendering time
    const getTime = ( order ) => {
        let date = new Date( order.created )
        let hours = date.getHours()
        let minutes = date.getMinutes()
        let ampm = hours >= 12 ? 'PM' : 'AM'
        hours = hours % 12
        hours = hours ? hours : 12 
        minutes = minutes < 10 ? '0' + minutes : minutes
        let time = hours + ':' + minutes + ampm 
        return time
    }

    //Open Modal
    const modalOpen = () => {
        handleModalOpen( order )
    }

    // reset opacity
    const resetOpacity = () => {
        setOpacity( 0.5 )
        setTimeout( ()=>{
            setOpacity( 1 )
        }, 100 )
    }

    return (
        <div className="row-container" style={{opacity:`${opacity}`}} onTouchStart={resetOpacity} onClick={modalOpen}>
                <div className="transaction-details-wrapper">
                    <Fragment>
                        <div className="headers">
                                <div className="time-value">{ getTime( order )}</div>
                        </div>
                        <div className="headers">
                            <UserName userProfile={order.user_profile} />
                        </div>
                    </Fragment>
                    <div className="headers">
                        <div className="qty">
                            {totalQuantity( order )}
                        </div>
                    </div>
                    <div className="headers">
                        <div className="total-price">
                            {totalAmount( order )}
                        </div>
                    </div>
                </div>
            </div>
    )
}

HistoryOrders.propTypes = {
    handleModalOpen : PropTypes.func,
    order: PropTypes.object
}