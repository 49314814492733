import React from 'react'
import ReactDom from 'react-dom'

const modalRoot = document.querySelector( '#modal' )

const Modal = props => {
    const { children , isOpen } = props
    
    return (
            isOpen && ReactDom.createPortal(
                <div className="modal-error-container">
                    {children}
                </div>
            , modalRoot )
        )
}

export default Modal 