import React, { useContext } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import history from '../history'
import TabsChild from './TabsChild'

import {userLogin, loginNeeded } from '../actions/authActions'

import '../style/Tabs.scss'
import { LanguageContext } from '../context/LanguageContext'

const Tabs = ( props ) => {
    const {strings} = useContext( LanguageContext )
    const tabsData = [
            {
                title_display:strings.liveViewText,
                title:'liveViewText',
                description:strings.liveViewDescriptionText,
                iconClass : 'live-view-icon'
            },
            {
                
                title_display:strings.historyText,
                title:'historyText',
                description:strings.historyDescriptionText,
                iconClass : 'history-icon'
            },
    ]
    const onTabsClick = ( title ) => {
        switch( title ){
            case 'liveViewText':
                history.push( '/app/liveView' )
                break
            case 'historyText':
                history.push( '/app/history' )
                break
            default:
                break 
        }
    }

    return (
        <div className={props.transformBanner ? 'push-tabs-container' : 'tabs-container'} >
            {tabsData.map( ( {title_display, title, description, iconClass } ) => {
                return ( 
                    <TabsChild 
                        key={title} 
                        onTabsClick={onTabsClick} 
                        iconClass={iconClass} 
                        description={description} 
                        historyOrderCount={props.historyOrderCount}
                        allCartsAndOrders={props.allCartsAndOrders} 
                        title={title}
                        title_display={title_display}
                        helpMenuToggle={props.helpMenuToggle}
                    />
                    )
            } ) }
        </div>
    )
}

const mapStateToProps = state => {
    return {
        auth : state.auth
    }
}

export default connect( mapStateToProps, { userLogin, loginNeeded } )( Tabs )

Tabs.propTypes = {
    historyOrderCount: PropTypes.number,
    allCartsAndOrders: PropTypes.array,
    loginNeeded : PropTypes.func,
    auth : PropTypes.shape( {
        validUserSession : PropTypes.bool,
    } ),
    helpMenuToggle: PropTypes.bool,
    transformBanner: PropTypes.bool
}
