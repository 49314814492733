import React, { Component } from 'react'
import history from '../history'

import { whatsNewData }from '../data/whatsNewData'
import '../style/WhatsNew.scss'
import uuid from 'uuid'
import PropTypes from 'prop-types'
import Mixpanel from '../common/Mixpanel'


class WhatsNew extends Component {

    componentDidMount(){
        Mixpanel.whatsNewViewed( this.props.screenOrientation )
    }

    componentWillUnmount(){
        Mixpanel.whatsNewClosed( this.props.screenOrientation )
    }

    render() {
        const onTabsClick = ( link ) => {
            switch( link ){
                case 'Report':
                    history.push( '/app/reports' )
                    break
                case 'Troubleshoot':
                    history.push( '/app/help/guides' )
                    break
                case 'Feedback':
                    history.push( '/app/help/feedback' )
                    break
                default:
                    break
            }            
        }
        
        return (
            <div className="wts-new-container" >
                {whatsNewData && whatsNewData.map( ( item )  => {
                    const displayBtn = item.link !== null ? <button className={`${item.classname} btn`} onClick={() => onTabsClick( item.link ) }>VIEW</button> : null
                    return(
                        <div key={uuid.v4()}>
                            <div className={item.classname}>{item.title}</div>
                            <div className={`${item.classname} relsd`}>{item.released}</div>
                            <div className={`${item.classname} desc`}>{item.description}</div>
                            <div className={`${item.classname} btn-container`}>{displayBtn}</div>
                        </div>
                    )
                } ) }
            </div>
        )
    }
}

export default WhatsNew

WhatsNew.propTypes = {
    screenOrientation: PropTypes.string
}
