// Importing libraries 
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Importing React Components 
import FAQAccordion from './FAQAccordion'

// Importing styles
import '../style/faqContainer.scss'

export default class FAQContainer extends Component {
    constructor(){
        super()
        this.state = {
            faqs : [],
            faqSearch: '',
            isInputClicked: false
        }
    }

    // Life Cycle Method runs after initial render
    componentDidMount(){
        let newFAQs = this.props.faqData.map( ( item , index ) => {
            item.isOpen = false
            item.id = index
            return item
        } ) 
        this.setState( {
            faqs : newFAQs
        } )
    }

    // Toggle previous accordion when new accordion clicked 
    toggleFAQHandler = ( id ) => {
        let newFAQs = this.state.faqs.map( ( item , i ) => {
            if( i === id ){
                item.isOpen = !item.isOpen
            }else{
                item.isOpen = false
            }
            return item
        } ) 
        this.setState( {
            faqs : newFAQs
        } )
    }
    
    // Rendering Accordion with data
    renderFAQContent = () => {
        return this.state.faqs.map( ( item , index ) => {
            return <FAQAccordion 
                        question={item.question} 
                        answer={item.answer} 
                        isOpen={item.isOpen}
                        key={index}
                        id={index}
                        toggleFAQHandler={this.toggleFAQHandler}
                    />
        } )
    }
    
    filterFaq = ( event ) => {
        this.setState( {faqSearch: event.target.value} )
        if( !event.target.value ) {
        this.setState( {isInputClicked: false} )
        } 
        else {
        this.setState( {isInputClicked: true} )
        }
    }
    
    render() {
        const { isInputClicked, } = this.state
        const isTop = isInputClicked ? 'top' : ''
        const setHeight = isInputClicked ? 'set-height' : ''

        return (
            <div className="faq-content-wrapper">
                <div className={`faq-input-wrapper ${isTop}`}>
                    <div className="faq-title">FAQ</div>
                    <div className={'search-container'}>
                        <input type="text" className="search-bar" placeholder="Search..." value={this.state.faqSearch} autoComplete="off"
                        onChange={( e )=>{this.filterFaq( e )}} />
                    </div>
                </div>
                    <div className={`faq-accordion-wrapper ${setHeight}`}>
                        {
                            this.state.faqs.filter( ( faq )=> {
                                if ( this.state.faqSearch === '' ) {
                                    return faq
                                }
                                if ( faq.question.toLowerCase().includes( this.state.faqSearch.toLowerCase() ) ) {
                                    return faq
                                }
                                return null
                            } ).map( ( item, index ) => {
                                return <FAQAccordion 
                                            question={item.question} 
                                            answer={item.answer} 
                                            isOpen={item.isOpen}
                                            key={index}
                                            id={index}
                                            toggleFAQHandler={this.toggleFAQHandler}
                                            highlightWords={this.state.faqSearch.split( ' ' )}
                                        />
                            } )
                        }
                    </div>
            </div>
        )
    }
}

FAQContainer.propTypes = {
    faqData : PropTypes.array,
}