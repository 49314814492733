import React, { Component  } from 'react'
import history from '../history'
import network from '../common/network'
import uuid from 'uuid'
import HomeScreen from './HomeScreen'
import AppData from '../common/AppData'
import logger from '../common/logger'
import AppAnalytics from '../common/AppAnalytics'
import Login from './Login'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isLoggedIn } from '../actions/authActions'
import { authClient } from '../common/helper'
import Modal from './Modal'
import StoreInput from './storeInput'


class Home extends Component {
    constructor() {
        super()
        this.state = {
            isError : false,
            email:'',
            isValid: true,
            noStore:false
        }
        this.uuid = uuid.v4()
        
        if ( localStorage.getItem( 'storeId' ) ) {
            this.storeId = localStorage.getItem( 'storeId' )  
        } else {
            this.setState( {isError:true,noStore:true} )
        }
    }

    componentDidMount(){
        if( !localStorage.getItem( 'access_token' ) ){
            localStorage.clear()
        }
        else{
            this.props.isLoggedIn( true )
        }
        this.systemSetup()
        if(!this.props.auth.isLoggedIn){
            this.getTokenId()
        }
    }

    isSysConfigValid = () => {
        let retrievedObject = localStorage.getItem( 'lvt.config' )
        retrievedObject = JSON.parse( retrievedObject )
        if ( retrievedObject && retrievedObject.storeId && retrievedObject.setupComplete && this.props.auth.isLoggedIn && localStorage.getItem( 'access_token' ) ) {
            history.push( '/app' )
        }else{
            this.setState( {
                isError : true 
            } )
            AppData.errorMessage = 'CONFIG_ERROR' 
            AppAnalytics.configStoreIdError()
            if( !this.props.auth.isLoggedIn  && this.state.isValid ) history.push( '/' )
        }
    }

    getTokenId = async () =>{
        const codeMatch = window.location.href.match( '[?#&]code=([^&]*)' )
        let tokens,access_token,refresh_token,id_token
            if( codeMatch && codeMatch[1]  && !localStorage.getItem("access_token")){
                try{
                    tokens = await authClient.getTokens( codeMatch[1] )
                    access_token = tokens?.data?.access_token
                    refresh_token = tokens?.data?.refresh_token
                    id_token = tokens?.data?.id_token
                    localStorage.setItem("access_token", access_token);
                    localStorage.setItem( 'refresh_token', refresh_token )
                    localStorage.setItem( 'id_token',id_token )
                    this.props.isLoggedIn( true )
                    this.getUserdata( localStorage.getItem("access_token") ?? access_token ) 
                }
                catch( e ){
                    this.props.isLoggedIn( false )
                }
            }
        }
    
        getUserdata = async ( access_token ) =>{
            const user = await authClient.getUser( access_token )
            const {permissions} = user?.data
            let lvt_permission = permissions.filter( permission => permission === 'lvt_permission_enable' )
            if( lvt_permission.length === 0 ){
                localStorage.setItem( 'validUser',false )
                this.setState( {
                        isValid: false
                } )
                this.props.isLoggedIn( false )
                history.push( '/unauthorize' )
            }
            else{
                const userEmail = user?.data?.email
                localStorage.setItem( 'emailId',userEmail )
            }
            
        }

    systemSetup =  ( ) => {

        if( !this.storeId ){
            this.setState( {isError:true, noStore:true} )
            return
        }
        let configData = {
            'store_id' : this.storeId,
             'uuid' : this.uuid
            }
        let config = {}
        let localData =  localStorage.getItem( 'lvt.config' ) 
        let lvtPersistenceToken = JSON.parse( localStorage.getItem( 'lvt.persistence.refreshToken' ) )
        if( navigator.onLine ){
            if( configData && configData.store_id && lvtPersistenceToken === null  ){
                console.log( 'refreshing token' )
                network.getJWTToken(
                    configData,
                    ( res )=>{
                        localStorage.setItem( 'lvt.persistence.refreshToken', JSON.stringify( true ) )
                        config = Object.assign( config,  {'storeId' : this.storeId , 'jwt' : res.data.jwt_token , systemIdentifier: this.uuid  , setupComplete : true} )
                        localStorage.setItem( 'lvt.config', JSON.stringify( config ) )
                        if ( config.storeId && config.jwt.length > 0 ) {
                            this.isSysConfigValid ()
                        }
                    }, 
                    ( error )=>{
                        logger.error( 'NA', 'JWT token fetch', 'get_JWTtoken', 'NA', 'JWT token fetch', error )
                        if( error ){
                            AppData.errorMessage = 'GET_JWT_FAILED' 
                            this.setState( {
                                isError: true 
                            } )
                        }
                    }
                )
            }else if( JSON.parse( localData )?.setupComplete ){
                this.isSysConfigValid()
            }else{
                this.setState( {
                    isError: true
                } )
                AppAnalytics.configStoreIdError()
                AppData.errorMessage = 'CONFIG_ERROR' 
            }
        }else{
            AppData.errorMessage = 'NO_INTERNET'
            this.setState( {
                isError : true
            } )
        }
    }

    renderOnBoarding = () =>{

        if( this.state.noStore ){
            return( <Modal isOpen={this.state.noStore}> <StoreInput/> </Modal> )
        }else{
            history.push( '/app' )
        }
        
    }

    render() {
        const { isLoggedIn } = this.props.auth
        return (
                <div>
                    { ( this.state.isError === false ) ? <HomeScreen/> : isLoggedIn ? this.renderOnBoarding() : <Login /> }
                </div>         
        )
    }
}

const mapStateToProps = state => {
    return {
        auth : state.auth
    }
}
export default connect( mapStateToProps,{isLoggedIn} )( Home )

Home.propTypes = {
  auth: PropTypes.object,
  isLoggedIn: PropTypes.func
}