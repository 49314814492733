import network from '../common/network'

export const createFeedback = ( query, stateChange, loadingChange ) => () => {
    network.createFeedback( 
        ( ) => {
            stateChange()
        },
        ( error ) => {
            console.log( error )
            loadingChange()
        },
        query
        )
}

