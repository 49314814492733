import React, { Component, Fragment } from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'underscore'

import ModalDetails from './ModalDetails'
import Loader from './Loader'
import HistoryHeader from './HistoryHeader'
import HistoryOrders from './HistoryOrders'

import history from '../history'
import AppData from '../common/AppData'
import network from '../common/network'
import { delay } from '../common/Util'
import { checkLandscape, navbarConfirmedObject } from '../common/Util'
import { strings } from '../context/LanguageContext/strings'

import '../style/TransactionHistory.scss'
import Mixpanel from '../common/Mixpanel'
import { connect } from 'react-redux'
import { authClient } from '../common/helper'
import { isLoggedIn } from '../actions/authActions'
import store from '../store/store'
import { setLoader } from '../modules/languageChange/actions'
import HomeScreen from './HomeScreen'
import config from '../common/config'


class TransactionHistory extends Component {
    constructor( props ) {
        super( props )
        this.state = {
            transactionData : [],
            details : {},
            isOpen: false,
            transactionOrderDates : [],
            ordersNotAvailable: false,
            historyCount: 0,
            isRefreshed: false
        }
    }

    //Lifecycle methods
    componentDidMount(){
        let localStorageRefreshToken = localStorage.getItem( "refresh_token" )

        let localStorageIdToken = localStorage.getItem( 'id_token' )
        let storeId = config.storeId()
        if(!storeId){
            authClient.logout( localStorageIdToken )
            localStorage.clear()
            return
        }
        if( !this.props.auth.isLoggedIn && !localStorageRefreshToken ){
            history.push( '/' )
        }
        else if( !this.props.auth.isLoggedIn ){
            this.getRefreshToken()
        }
        else{
            this.getTransactionHistory()
            this.props.onHistoryView()
        }
    }
    getRefreshToken = async () =>{
        let localStorageRefreshToken = localStorage.getItem( "refresh_token" )
        let refreshToken =  await authClient.getRefreshToken( localStorageRefreshToken )
        refreshToken()
            .then( res => {
                localStorage.setItem( 'refresh_token',res.data.refresh_token )
                this.props.isLoggedIn( true )
                this.setState( {
                    isRefreshed : true
                } )
            } )
            .catch( err => {
                console.log( 'promise rejected',err )  
                history.push( '/' )
            } )
    }

    componentWillUnmount(){
        if( this.props.auth.isLoggedIn ){
            Mixpanel.exitHistoryView( this.props.screenOrientation )
        }
    }
    

    // Fetching old orders
    getTransactionHistory = () => {
        const { activityStatus } = this.props 
        let currentDate = new Date()
        let yesterdayDate = new Date( new Date().setDate( new Date().getDate() - AppData.previousOrderPerDays ) ) 

        let query = {
            'created' : { $lt: currentDate , $gte: yesterdayDate}
        }
        network.getOrdersConfirmed(
            ( response )=>{
                this.props.setLoader( false )
                let ordersData = response.data
                
                ordersData = ordersData.orders.filter( ( order )=>{
                    if( this.props.getUnwantedName( order.user_profile.first_name ) ){
                        return false
                    }
                    if( activityStatus[ 'confirmed' ] === 'confirmed' && order.is_order_confirmed === true && order.order_status === 'selfcheckedout' ){
                        return true
                    } 
                    if( activityStatus[ 'paid' ] === 'paid' && order.is_order_confirmed === false && order.order_status === 'selfcheckedout' ) {
                        return true
                    }

                    return null
                } )           
                if( ordersData.length === 0 ){
                    this.setState( {
                        ordersNotAvailable : true
                    } )
                }else{
                    this.mapTransactionData( ordersData )
                    this.setState( {
                        ordersNotAvailable : false
                    } )
                    let count = ordersData.length
                    this.setState( { historyCount : count } )
                }
            },
            ( error )=>{
                console.log( error )
            },
            query
        )
    }

    //Seprate transaction orders data per Date
    mapTransactionData = ( ordersData ) => {
        let groupByDate = []
        ordersData.map( ( order ) => {
            order.orderDate = this.getDate( order.created )
            return null
        } )
        groupByDate = _.groupBy( ordersData , 'orderDate' )
        this.setState( {
            transactionData : groupByDate
        } )
    }

    //rendering date 
    getDate = ( orderDate ) => {
        let date = new Date( orderDate )
        date = ( date.getMonth() + 1 ) + '/' + date.getDate() + '/' + date.getFullYear() 
        return date
    }

    userData = () => {
        let data = _.last( AppData.getAllNotifications() )
        return data
    }

    isShowAndGo = () => {
        return AppData.isStoreShowAndGo()
    }

    notificationShownCheck = () => {
        let showAndGoNotificationCheck = ( this.isShowAndGo() || ( !this.isShowAndGo() && this.userData().is_order_confirmed ) ) ? true : false
        return showAndGoNotificationCheck
    }

    handleModalOpen = async ( order ) => {
        let detailOrder = navbarConfirmedObject( order )
        this.setState( { 
            details: detailOrder,
            isOpen:true
        } )       
        await delay( 400 )
        const userStatus = AppData.orderType( this.state.details.itemType,AppData.isStoreShowAndGo(),this.state.details.isOrderConfirmed )
        const orderId = this.state.details.itemType === 'order' ? this.state.details.order_id : ''
        Mixpanel.customerProfileViewed( checkLandscape(),userStatus,this.state.title,orderId )
        this.props.detailPageHandler( this.state.isOpen , order )
        history.push( '/app/history/details' ) 
     }

    //render Date header with orders
    renderDateHeader = () => {
        const { transactionData } = this.state
        
        let keysOfTransactionData = Object.keys( transactionData )
        return keysOfTransactionData.map( ( orderDate , index )=>{
            return (
                <Fragment key={index}>
                    <HistoryHeader orderDate={orderDate}/>
                    <div className="grid-container-3">
                        {this.renderTransactionData( orderDate )}
                    </div>
                </Fragment>
            )
        } )
    }

    onConfirmationClick = () => {
        let details =  _.last( AppData.getAllNotifications() )
        this.handleModalOpen( details )
    }


    componentDidUpdate( prevProps ){
        if( prevProps.navbarClicked !== this.props.navbarClicked ){
            // opening notification in history navbar
            if( this.props.isDetailOpen ){
                this.setState( { details: this.props.modalUpdate() } )
            } 
          // opening notification in detail after it was opened from history notification
            else {   
                this.onConfirmationClick()
            }
        }
        if( this.state.isRefreshed ){
           
            this.getTransactionHistory()
            setTimeout(()=>{
                this.setState({isRefreshed:false})
            },500)
        }
    }
    
    //rendering all order history details
    renderTransactionData = ( date ) => {
        return this.state.transactionData[date].map( ( order ) => {
            return <HistoryOrders 
                        key={order.order_id} 
                        order={order} 
                        handleModalOpen={this.handleModalOpen}
                    />
        } )
    }
    
    render() {
        const { transactionData, isOpen, details, ordersNotAvailable } = this.state
        const keysOfTransactionData = Object.keys( transactionData )
        
        return (
            <Fragment>
                <div className={this.props.transformBanner ? 'history-content-push-container' :'history-content-container'} >
                { store.getState().languageChange.isLoading  && <HomeScreen /> }
                        { keysOfTransactionData.length > 0 && ordersNotAvailable === false ? 
                            this.renderDateHeader() :
                            ( ordersNotAvailable ) ? 
                                <div className="no-history-wrapper">
                                    <div className="no-history-text">
                                        {strings.noHistoryText}
                                    </div>
                                </div> :                            
                                <div className="loader-postion"><Loader/></div> 
                        }
                    <Route path="/app/history/details" component={()=> <div className="modal-container">{isOpen  ? <ModalDetails cart={details}/>: history.push( '/' ) }</div>} /> 
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth : state.auth
    }
}

export default connect( mapStateToProps,{ isLoggedIn,setLoader } ) ( TransactionHistory )

TransactionHistory.propTypes = {
    detailPageHandler : PropTypes.func,
    isDetailOpen : PropTypes.bool,
    activityStatus: PropTypes.object,
    modalUpdate: PropTypes.func,
    navbarStateChange: PropTypes.func,
    navbarClicked:PropTypes.bool,
    onHistoryView: PropTypes.func,
    screenOrientation: PropTypes.string,
    title: PropTypes.string,
    getUnwantedName: PropTypes.func,
    transformBanner: PropTypes.bool,
    auth: PropTypes.object,
    isLoggedIn: PropTypes.func,
    setLoader: PropTypes.func
}
