import React , { useRef } from 'react'
import PropTypes from 'prop-types'

import successSound from '../assets/success_sound.wav'
import AppData from '../common/AppData'

export default function SuccessAudio(  { cart } ) {
    const audioRefs = useRef( null )
    
    const onPlaySound = () => {
        AppData.isPlaying = true
        AppData.playingOrderId =  cart.order_id

        setTimeout( () => { 
            AppData.isPlaying = false
            AppData.playingOrderId = ''
            onSoundEnded()
        }, AppData.successSoundInterval )
    }

    const onSoundEnded = () => {
        if( !AppData.ignoredSoundIds.includes( cart.order_id ) ){
            AppData.ignoredSoundIds.push( cart.order_id ) 
        }
    }

    return (
        <div>
            { ( AppData.isStoreShowAndGo() === false ) ? null : ( AppData.isPlaying === true ) ? null :               
                <audio  
                    autoPlay
                    onPlay={onPlaySound} 
                    ref={audioRefs} 
                    src={successSound} 
                    onEnded={onSoundEnded}
                />
            }
        </div>
    )
}

SuccessAudio.propTypes = {
    cart : PropTypes.shape( {
        order_id : PropTypes.string
    } )
}