import AppData from './AppData'
import config from './config'
import { checkLandscape } from './Util'
import  get  from 'lodash/get'

const AppAnalytics = {
    columns: {
        error: 'error'
    },
    trackAction: ( name, properties ) => {
        properties.storeId = `${config.storeId()}`
        properties.appVersion = `${AppData.appVersion}`
        properties.environment = process.env.REACT_APP_ENVIRONMENT
        properties.systemIdentifier = `${config.getSystemIdentifier()}`
        properties.isForeground = AppData.isForeground
        properties.deviceId = config.getDeviceId()
        properties.defaultOrientation = checkLandscape()
        properties.storeCity = get( config.localData(), 'storeCity', '' ) 
        properties.storeState = get( config.localData(), 'storeState', '' )
        properties.storeZoneId = get( config.localData(), 'storeZoneId', '' )
        properties.storeCountry = get( config.localData(), 'storeCountry', '' )
        properties.storeMarketId = get( config.localData(), 'storeMarketId', '' )
        properties.nativeAppVersion = window.Android && window.Android.appVersion ? window.Android.appVersion() : ''
        window?.newrelic?.addPageAction && window.newrelic.addPageAction( name, properties )
    },
    captureLogin: ( type, loginCount ) => {
        AppAnalytics.trackAction( 'captureLogin', {
            type: type,
            count: loginCount
        } )
    },
    captureSuccessLogin: ( successLogin ) => {
        AppAnalytics.captureLogin( 'successLogin',successLogin )
    },
    captureFailedLogin: ( failedLogin ) => {
        AppAnalytics.captureLogin( 'failedLogin', failedLogin )
    },
    captureDisplayLoginInactive: ( displayLoginInactive ) => {
        AppAnalytics.captureLogin( 'displayLoginInactive', displayLoginInactive )
    },
    captureDismissLogin: ( dismissLogin ) => {
        AppAnalytics.captureLogin( 'dismissLogin', dismissLogin )
    },
    captureAutoDismissLogin: ( autoDismissLogin ) => {
        AppAnalytics.captureLogin( 'autoDismissLogin', autoDismissLogin )
    },
    captureReadWriteLogin: ( readWriteLogin ) => {
        AppAnalytics.captureLogin( 'readWriteLogin', readWriteLogin )
    },
    captureReadOnlyLogin: ( readOnlyLogin ) => {
        AppAnalytics.captureLogin( 'readOnlyLogin', readOnlyLogin )
    },
    homePageReload: ( startTime ) => {
        AppAnalytics.trackAction( 'homePageReload', {
            startTime: startTime
        } )
    },
    errorLoadingCarts: ( errorMessage ) => {
        AppAnalytics.trackAction( 'errorLoadingCarts', {
            error: errorMessage
        } )
    },
    errorLoadingOrderedCarts: ( errorMessage ) => {
        AppAnalytics.trackAction( 'errorLoadingOrderedCarts', {
            error: errorMessage
        } )
    },
    modeLoaded: ( name, startTime ) => {
        AppAnalytics.trackAction( 'modeLoaded', {
            type: name,
            startTime: startTime
        } )
    },
    cardModeLoaded: ( startTime ) => {
        AppAnalytics.modeLoaded( 'cardModeLoaded', startTime )
    },
    gridModeLoaded: ( startTime ) => {
        AppAnalytics.modeLoaded( 'gridModeLoaded', startTime )
    },
    listModeLoaded: ( startTime ) => {
        AppAnalytics.modeLoaded( 'listModeLoaded', startTime )
    },
    pageLoaded: ( startTime,endTime ) => {
        AppAnalytics.trackAction( 'pageLoaded', {
            startTime: startTime,
            endTime: endTime,
            timeDifference: ( endTime - startTime ) / 1000
        } ) 
    },
    cartsCount: ( startTime ) => {
        AppAnalytics.trackAction( 'cartsCount', {
            startTime:startTime
        } )
    },
    internetConnectionCheck: ( isNavigatorOnline=true, webIOTStatus, nativeIOTStatus ) => {
        AppAnalytics.trackAction( 'internetConnectionCheck', {
            isNavigatorOnline:isNavigatorOnline,
            status: isNavigatorOnline ? 'online': 'offline',
            webIOTStatus: webIOTStatus,
            nativeIOTStatus: nativeIOTStatus
        } )
    },
    detailPageOpened: ( type, startTime ) => {
        AppAnalytics.trackAction( 'detailPageOpened',{
            startTime: startTime,
            type: type
        } )
    },
    detailPageClosed: ( type, startTime, endTime ) => {
        AppAnalytics.trackAction( 'detailButtonDuration',{
            startTime: startTime,
            endTime: endTime,
            type: type,
            timeDifference: endTime - startTime
        } )
    },
    detailNotificationShown: ( orderId, startTime ) => {
        AppAnalytics.trackAction( 'detailNotificationShown',{
            startTime: startTime
        } )
    },
    mqttConnectionSuccessful: ( connected ) => {
        AppAnalytics.trackAction( 'mqttConnectionSuccessful',{
            isConnected:connected
        } )
    },
    mqttReConnectionCheck : (  ) => {
        AppAnalytics.trackAction( 'mqttReConnectionCheck',{
        } )
    },
    greenCardDuration: ( orderId,startTime,endTime ) => {
        AppAnalytics.trackAction( 'greenCardDuration',{
            startTime: startTime,
            endTime: endTime,
            orderId: orderId,
            timeDifference: endTime - startTime,
            event: 'order confirmation'
        } )
    },

    OrderConfirmed: ( startTime, orderId ) => {
        AppAnalytics.trackAction( 'orderConfirmedCardsShown', {
            startTime: startTime,
            order_id: orderId
        } )
    },

    networkStatus: ( requestType,status,statusCode,response,apiCallDuration ) => {
        AppAnalytics.trackAction( 'networkStatus', { 
            actionName: 'network fetch',
            type: requestType,
            status: status,
            statusCode: statusCode,
            response: response,
            apiCallDuration: apiCallDuration
        } )
    },

    network_get_carts: ( status,statusCode,response,apiCallDuration ) => {
        AppAnalytics.networkStatus( 'get_carts',status,statusCode,response,apiCallDuration )
    },

    network_get_orders: ( status,statusCode,response,apiCallDuration ) => {
        AppAnalytics.networkStatus( 'get_orders', status,statusCode,response,apiCallDuration )
    },

    network_get_config: ( status,statusCode,response,apiCallDuration ) => {
        AppAnalytics.networkStatus( 'get_config', status,statusCode,response,apiCallDuration )
    },

    network_get_order_by_id: ( status,statusCode,response,apiCallDuration ) => {
        AppAnalytics.networkStatus( 'get_order_by_id', status,statusCode,response,apiCallDuration )
    },

    network_get_guidelines: ( status,statusCode,response,apiCallDuration ) => {
        AppAnalytics.networkStatus( 'get_guidelines', status,statusCode,response,apiCallDuration )
    },

    network_get_reports: ( status,statusCode,response,apiCallDuration ) => {
        AppAnalytics.networkStatus( 'get_reports', status,statusCode,response,apiCallDuration )
    },

    network_create_feedback: ( status,statusCode,response,apiCallDuration ) => {
        AppAnalytics.networkStatus( 'create_feedback', status,statusCode,response,apiCallDuration )
    },

    network_get_jwtToken: ( status,statusCode,response,apiCallDuration ) => {
        AppAnalytics.networkStatus( 'get_jwtToken', status,statusCode,response,apiCallDuration )
    },

    network_get_location_feature: ( status,statusCode,response,apiCallDuration ) => {
        AppAnalytics.networkStatus( 'get_location_feature', status,statusCode,response,apiCallDuration )
    },

    // network
    networkError: ( serviceName, error ) => {
        AppAnalytics.trackAction( 'networkError', {
            serviceName: serviceName,
            error: JSON.stringify( error ),
            type: 'Network Error'
        } )
    },
    confirmationFlowCheck: ( confirmationFlow ) => {
        AppAnalytics.trackAction( 'confirmationFlowCheck', {
            confirmationFlow: confirmationFlow
        } )
    },
    orientationOnLoad: ( orientation ) => {
        AppAnalytics.trackAction( 'orientationOnLoad', {
            defaultOrientation: orientation
        } )
    },
    orientationOnChange: ( startTime, orientation ) => {
        setTimeout( () => {
            AppAnalytics.newOrientation =  window.innerWidth > 360 ? 'Landscape' : 'Portrait'
            AppAnalytics.trackAction( 'orientationOnChange', {
                startTime: startTime,
                newOrientation: AppAnalytics.newOrientation,
                previousOrientation: orientation
            } )
        },100 )
    },
    wifiSpeedCheck: () => {
        AppAnalytics.trackAction( 'wifiSpeedCheck', {
            wifiSpeed : `${String( navigator.connection && navigator.connection.downlink ? navigator.connection.downlink : '')} Mbps`,
            networkType: navigator.connection && navigator.connection.effectiveType ? navigator.connection.effectiveType : ''
        } )
    },
    trackCachedLogs: ( cachedLog ) => {
        AppAnalytics.trackAction ( 'trackCachedLogs' , {
            cachedLog: cachedLog
        } )
    },
    trackTopicConnection: ( data ) => {
        AppAnalytics.trackAction( 'trackTopicConnection', {
            topicLog: data 
        } )
    },
    trackLaunchType: ( data, type ) => {
        AppAnalytics.trackAction ( 'trackLaunchType', {
            data : data,
            type: type
        } )
    },
    missingContract: ( type, message ) => {
        AppAnalytics.trackAction( 'missingContract', {
            type: type,
            message: message
        } )
    },
    captureLogs: ( logs ) => {
        AppAnalytics.trackAction( 'captureLogs', {
            logs: logs
        } )
    },  
    trackReloading: ( log ) => {
        AppAnalytics.trackAction( 'trackReloading', {
            log: log
        } )
    },
    captureEvents: ( cartsAndOrders, historyOrderCount, customerCartCreation,
        customerPayed, customerPayedAndConfirmed ) => {
        AppAnalytics.trackAction( 'captureEvents', {
            cartsAndOrders: cartsAndOrders,
            historyOrderCount: historyOrderCount,
            customerCartCreation: customerCartCreation,
            customerPayed: customerPayed,
            customerPayedAndConfirmed: customerPayedAndConfirmed
       } )
   },
   shoppingCartRoundTrip: ( timeDifference, identifier ) => {
       AppAnalytics.trackAction( 'shoppingCartRoundTrip', {
            timeDifference: timeDifference,
            identifier: identifier
       } )
   },
   paidCartRoundTrip: ( timeDifference, identifier ) => {
    AppAnalytics.trackAction( 'paidCartRoundTrip', {
         timeDifference: timeDifference,
         identifier: identifier
    } )
   },
   confirmedCartRoundTrip: ( timeDifference, identifier ) => {
    AppAnalytics.trackAction( 'confirmedCartRoundTrip', {
         timeDifference: timeDifference,
         identifier: identifier
    } )
   },
   configStoreIdError : (  ) => { 
    AppAnalytics.trackAction( 'configStoreIdError',{
    } )
   },
   pressRetryButton : () => {
    AppAnalytics.trackAction( 'pressRetryButton',{
  } )
   },
   notificationCartDuration : ( duration ) => {
    AppAnalytics.trackAction( 'notificationCartDuration',{
         notificationCartDuration: duration
    } )
   },
   apiSource : ( source ) => {
       AppAnalytics.trackAction( 'apiSource', {
         apiSource: source
       } )
   },
   fetchBanner: ( source ) => {
       AppAnalytics.trackAction( 'fetchBanner', {
         fetchBannerSource: source
       } )
   }

}


export default AppAnalytics
