import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import '../style/HistoryHeader.scss'
import { LanguageContext } from '../context/LanguageContext'

export default function HistoryHeader( { orderDate } ) {
    const { strings } = useContext( LanguageContext )

    return (
        <div className="header-container">
            <div className="date-wrapper">
                <div className="date">
                    {orderDate}
                </div>
            </div>
            <div className="history-header">
                    <div className="headers">
                        <div className="time">{strings.timeText}</div>
                    </div>
                    <div className="headers">
                        <div className="name">{strings.nameText}</div>
                    </div>
                    <div className="headers">
                        <div className="qty">{strings.qtyText}</div>
                    </div>
                    <div className="headers">
                        <div className="total">{strings.totalText}</div>
                    </div>
            </div>
        </div>
    )
}

HistoryHeader.propTypes = {
    orderDate: PropTypes.string
}

