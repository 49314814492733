import _ from 'underscore'
import { FETCH_CONFIG } from './types'
import network from '../common/network'
import AppData from '../common/AppData'


const activityStatesObject = ( activityStatesArray ) => {
    let newObject = {'scan':'','paid':'','confirmed':''}
    for( let i in activityStatesArray  ){
         let prop = Object.keys( newObject )[i]
         newObject[prop] = activityStatesArray[i]
    }
    return newObject
}


export const fetchConfig  = () => async ( dispatch ) => {
    await network.getAllTips(
        ( response ) => {
          AppData.didLoadConfig()
            let activity = {}
            let activeStates = {}
            let tipsConfiguration = {}
            let showAndGo = {}
            let faq = {}
            let insightsContent = {}
            let unwantedNames = {}
            let pollingState = {}
            let pollingInterval = {}
            let amplifyState = {} 
            let languageState = {}
            activity = _.first( response.data.configurations.filter( ( item ) => item.feature === AppData.activityFeature ) ).properties
            activity = _.first( activity )
            activeStates = activityStatesObject( activity.default_value ) 
            languageState = _.first( response.data.configurations.filter( ( item ) => item.feature === AppData.languageFeature ) ) ? 
                            _.first( response.data.configurations.filter( ( item ) => item.feature === AppData.languageFeature ) ).properties[0].default_value : 'en'
            showAndGo = _.first( response.data.configurations.filter( ( item ) => item.feature === AppData.showAndGoFeature ) ).properties
            tipsConfiguration = _.first( response.data.configurations.filter( ( configuration ) => configuration.feature === AppData.tipFeature ) ).properties   
            faq = _.first( response.data.configurations.filter( ( item ) => item.feature === 'faq' ) ).properties
            insightsContent = _.first( response.data.configurations.filter( ( config ) => config.feature === AppData.insightFeature ) ).properties
            faq = _.first( response.data.configurations.filter( ( item ) => item.feature === AppData.faqFeature ) ).properties
            unwantedNames = _.first( response.data.configurations.filter( ( item ) => item.feature === AppData.unwantedSyntheticNames ) ).properties
            pollingState = _.first( response.data.configurations.filter( ( item ) => item.feature === AppData.polling ) ) ?
                            _.first( response.data.configurations.filter( ( item ) => item.feature === AppData.polling ) ).properties[0].current_value : true
            pollingInterval = _.first( response.data.configurations.filter( ( item ) => item.feature === AppData.polling ) ) ?
                                _.first( response.data.configurations.filter( ( item ) => item.feature === AppData.polling ) ).properties[1].current_value : 3000
            amplifyState = _.first( response.data.configurations.filter( ( item ) => item.feature === AppData.amplifyState ) ) ?
                            _.first( _.first( response.data.configurations.filter( ( item ) => item.feature === AppData.amplifyState ) ).properties ).current_value : true
            AppData.saveConfig( tipsConfiguration  , showAndGo )
            return dispatch( {
                type: FETCH_CONFIG, 
                payload: {
                    activityStatus : activeStates,
                    layoutStatus: activity.layoutStates,                        
                    allTips : tipsConfiguration,
                    faqs : faq,
                    insightsContent: insightsContent,
                    unwantedNames: unwantedNames,
                    pollingState: pollingState,
                    pollingInterval: pollingInterval,
                    amplifyState: amplifyState,
                    languageState: languageState
                }
            } )
        },
        ( error ) => { 
            console.log( 'Error', error )
        }
    )
}