import config from '../common/config'

var _lastFetchedCartsAndOrders = {
  carts: [],
  orders: [],
  updatedDatetime: Date.now()
}
var _config = {}
var _pubSubListerners = []
var _receivedNotifications = []
var _showAndGo = []

const AppData = {
  storeInfo: {},
  storeId: config.storeId(),
  token: config.getJWTToken(),
  ordersAllowed : [
    'selfcheckedout'
  ],
  systemIdentifier: config.getSystemIdentifier(),
  quickPeekInterval: ( 30 * 1000 ),
  eraseOrderInterval: ( 120 * 1000 ), // 90sec - >  120sec 
  erasePaidInterval: ( 5 * 60 ),
  cartFilterTimeBuffer: ( 5 * 1000 ), // to allow delays and inconsistencies across system clocks
  ordersFilterTime : ( 15 * 60 * 1000 ),
  previousOrderPerDays : 1,
  tipInterval: 20000,
  successSoundInterval: ( 15 * 1000 ),
  isForeground: true,
  timerArray: [],
  paidOrders:[],
  abandonedCarts:{},
  modeStartTime: 0,
  isError:false,
  errorMessage:'',
  notificationShownTime: 4000,
  topicRefreshPeriod : ( 1000 * 60 ),
  tipFeature:'tipoftheday',
  activityFeature : 'activitystates',
  showAndGoFeature :'showAndGo',
  insightFeature:'insight',
  faqFeature:'faq',
  languageFeature: 'languagestates',
  unwantedSyntheticNames : 'unwantednames',
  marketContent: 'market_data',
  heartbeatInterval: ( 1000 * 60 ),
  reconnectionIOTPeriod : ( 5 * 60 ),
  modalDetailTopMargin: '48',
  startIoTTimer:false,
  showAndGoText: 'showAndGoText',
  scanAndGoText: 'scanAndGoText',
  shownOrders: [],
  ignoredSoundIds : [],
  playingOrderId:'',
  analytics: {
    detailStartTime: 0,
    notificationStartTimes: {}
  },
  isPlaying : false,
  isTablet: window.matchMedia( '(min-width:670px)' ),
  portrait: window.matchMedia( '(max-width:360px)' ),
  landscape: window.matchMedia( '(min-width:361px)' ),
  appVersion: '2.2.9',
  errorCount: 0,
  redactExp : /\d(?=\d{4})/g,
  shoppingText: 'shoppingText',
  paidText: 'paidText',
  confirmedText: 'confirmedText',
  guideText: 'guideText',
  guideButtonText: 'guideButtonText',
  guideYesText: 'guideYesText',
  guideNoText: 'guideNoText',
  guideNoText1: 'guideNoText1',
  textNumber: 'textNumber',
  goalPercentage: 0.15, // in percantage
  eventDelay: 3000,
  polling: 'polling',
  pollingInitialDelay: 5000,
  notificationCartTimeDifference: 10000,
  guidelineDelay: 7000,
  amplifyState: 'amplifyState',
  infoText: 'moreInfoText',
  bannerDetailShownDelay: 500,
  locationFeatureDelay: 1000,
  mixpanelEventDelay: 5000,
  guidelineNameDelay: 500,
  isDemoMode: false,
  pollingEnabled:false,
  appRefreshInterval:1800,
  storeTransactions: ( carts, orders ) => {
    _lastFetchedCartsAndOrders = {
      orders: orders,
      carts: carts ? carts : [],
      updatedDatetime: Date.now()
    }
  },
  getAllTransactions: () => {
    return _lastFetchedCartsAndOrders
  },
  saveConfig: ( config , showAndGo ) => {
    _config = config
    _showAndGo = showAndGo
  },
  getConfig: () => {
    return _config
  },
  isStoreShowAndGo : () => {
    let showAndGoStoreIds = [] 
    _showAndGo.map( ( item ) => {
        if( item.flow === AppData.showAndGoFeature ){
            showAndGoStoreIds = item.showAndGo
        }
        return null
    } )
    return showAndGoStoreIds.includes( config.storeId() ) 
  },
  clearCartsListener : () => {}, 
  clearPaidOrderListener : () => {},
  clearConfirmedOrderListener : () => {},
  addPubSubListerner: ( callBack ) => {
    _pubSubListerners.push( callBack )
  },
  clearPubSubListeners: () => {
    _pubSubListerners = []
  },
  removePubSubListener: ( callBack ) => {
    _pubSubListerners =  _pubSubListerners.filter( ( item ) => {
      return item !== callBack
    } )
  } ,
  getAllPubSubListeners: () => {
    return _pubSubListerners
  },
  addNotification: ( notification ) => {
    _receivedNotifications.push( notification )
  },
  getAllNotifications: () => {
    return _receivedNotifications
  },
  _didLoadData: false,
  didLoadData: () => {
    AppData._didLoadData = true
    if ( AppData._didLoadConfig ) {
      AppData.didLoadDataAndConfig()
    }
  },
  _didLoadConfig: false,
  didLoadConfig: () => {
    AppData._didLoadConfig = true
    if ( AppData._didLoadData ) {
      AppData.didLoadDataAndConfig()
    }
  },
  didLoadDataAndConfig: () => {

  },
  getOrderProjection: () => {
    return {
      'store_id':1,
            'created':1,
            'order_status':1,
            'total':1,
            'user_profile.first_name':1,
            'user_profile.last_name':1,
            'store_details.address':1,
            'user_profile.loyalty_id':1,
            'order_id':1,
            // 'items':1,
            'items.product_id':1,
            'items.price':1,
            'items.name':1,
            'items.quantity':1,
            'items.deposit_amount':1,
            'items.ProductType':1,
            'arbitrationToken':1,
            'customer_id':1,
            'is_order_confirmed':1,
            'transaction_status':1,
            'last_updated': 1,
            'transaction_time': 1,
            'items.localizations':1 
    }
  },
  shouldSoundPlay : ( orderId ) => {
    if( !AppData.ignoredSoundIds.includes( orderId ) ){
      if( AppData.isPlaying === false || orderId === AppData.playingOrderId ){
          return true
      }else{
          AppData.ignoredSoundIds.push( orderId )
          return false
      }
    }else{
      return false
    }   
  },
  orderType:  ( type,store,confirmed ) => {
    if( type === 'order' ){
        if( ( store && confirmed ) ||  ( !store && !confirmed ) ){
            return AppData.paidText
        }
        if( !store && confirmed ){
            return AppData.confirmedText
        }
    }
    else return AppData.shoppingText
  }
}


export default AppData
