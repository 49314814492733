import network from '../common/network'

export const getReports = ( query,stateChange,loadingChange ) => () => {
    network.getReports( 
        ( response ) => {
            stateChange( response )
        },
        ( error ) => {
            console.log( error )
            loadingChange()
        },
        query
    )
}