import React, { Component } from 'react'
import PropTypes from 'prop-types'
import history from '../history'
import get from 'lodash/get'

import '../style/feedback.scss'
import dompurify from 'dompurify'
import { strings } from '../context/LanguageContext/strings'


export default class Terms extends Component {
    constructor( props ){
        super( props )
        this.goBack = this.goBack.bind( this )
     }
     
    goBack = () => {
        history.goBack()
    }

    render() {
    const sanitizer = dompurify.sanitize
    const isLandscape = this.props.screenOrientation === 'Landscape' ? 'landscape' : ''

        return (                              
            <div className={`terms-container ${isLandscape}`}>
                <div className="terms">{get( strings.termsText, 'title', '' )}</div>
                <div className="description"
                dangerouslySetInnerHTML = {{__html: sanitizer( get( strings.termDescription, 'description', '' ) ) }}>
                </div>
                <button className="terms_btn" onClick={this.goBack}>{strings.gotItText}</button>
            </div>
        )
    }
}

Terms.propTypes = {
    screenOrientation: PropTypes.string
}
