import React from 'react'
import '../style/Login.scss'
import { strings } from '../context/LanguageContext/strings'
import mobileCheckout from '../assets/MCO (App Icon at 7MD).png'
import { authClient } from '../common/helper'

const Login = () =>{
    return (
        <div className="login">
            <div className='login-container'>
                <img src={mobileCheckout} alt='Mobile Checkout' className='icon' />
                <div >{strings['showAndGoText']}</div>
            </div>              
          <button className="btn" onClick={()=>authClient.authorize()}>{strings.logIn}</button>
        </div>
    )
}
export default Login