import  React, { Component } from 'react'
import PropTypes from 'prop-types'
import GuidelineResponse from './GuidelineResponse'
import Mixpanel from '../common/Mixpanel'
import uuid from 'uuid'
import DOMPurify from 'dompurify'

import '../style/GuidelineDetail.scss'
import AppData from '../common/AppData'

export default class GuidelineDetail extends Component {
    constructor( props ){
        super( props )
        this.state = {
            guidelineName: ''
        }
    }

    componentDidMount(){
        setTimeout( () => {
            Mixpanel.onTroubleshootingGuideView( this.props.screenOrientation, this.props.titleState, this.props.guideSource, this.state.guidelineName )
        }, AppData.mixpanelEventDelay )
        
    }

    componentWillUnmount(){
        Mixpanel.exitTroubleshootingGuideView( this.props.screenOrientation, this.props.titleState )
        this.props.transformBanner( false )
    }

    titleSet = () => {
        if( !this.props.incomingNotification ){
            return this.props.titleState
        }else{
            return this.props.filteredTitle
        }
    }

    renderDetailData = () => {
        return this.props.guidelinesData.map( ( guideline ) => {
            if( guideline && guideline.name === this.titleSet() ){
                let instruction = {}
                setTimeout( () => {
                    this.setState( { guidelineName: guideline.name} )
                }, AppData.guidelineNameDelay )
                return guideline.steps.map( ( step )=>{
                    instruction.name = step.name
                    instruction.description = step.description
                    instruction.images = step.images
                return (
                        <div key = { instruction.name } className='guide-detail-sub-container'>
                           { instruction.name === 'empty' ? null : <div className='guide-detail-step'>{ instruction.name }</div>  }
                            <div className='guide-detail-instruction'
                                dangerouslySetInnerHTML = {{__html: DOMPurify.sanitize( instruction.description ) }}>
                            </div>
                            { instruction.images.length !== 0 ? 
                            <div className='guide-detail-images'>
                                {instruction.images.map ( ( image ) => {
                                    return(
                                        <div key={uuid.v4()} className='image'>
                                            <img  src={ image.url } alt='img' />
                                        </div> 
                                    )
                                } ) }
                            </div> : null }
                            <div className='guide-image-label'>
                            {instruction.images.map ( ( image ) => {
                                    return(
                                        <div  key={uuid.v4()}className='image-label'>{image.label}</div>
                                    )
                                } ) }
                            </div>
                        </div>
                        )
                } )    
        }  
        return null
    } )
    }

    render(){
        return (
            <div className='guide-detail-container'>
                    <div className='guide-detail-title'> { this.titleSet() }</div>
                    {this.renderDetailData()}
                        <GuidelineResponse guidelineName={this.state.guidelineName} guideSource={this.props.guideSource}/>         
            </div>
        )

}

}

GuidelineDetail.propTypes = {
    titleState : PropTypes.string,
    guidelinesData: PropTypes.array,
    screenOrientation: PropTypes.string,
    incomingNotification: PropTypes.bool,
    singleGuideline: PropTypes.array,
    filteredTitle: PropTypes.string,
    transformBanner: PropTypes.func,
    guideSource: PropTypes.string
}
