import React, { Component, Fragment } from 'react'
import AppData from '../common/AppData'
import showAndGoVideo from '../assets/MCO_Store_App_Training-720p-210518.mp4'
import scanAndGoVideo from '../assets/MCO_Store_App_Training-720p-210518.mp4'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { userLogin, loginNeeded } from '../actions/authActions'
import Mixpanel from '../common/Mixpanel'
import { strings } from '../context/LanguageContext/strings'

class VideoContainer extends Component {

    constructor( props ){
        super( props )
        this.state = {
            play: false,
            totalDuration: '',
            opacity: 1,
            loginRequested: false
        }
    }

    // fetching actual video total duration on component mount
    componentDidMount = () => {
       this.videoDurationOnLoad()
    }

        // video is played
        play = () => {
            this.props.loginNeeded()
            this.setState( { loginRequested: true } )
            Mixpanel.lvtVideoViewed( this.props.screenOrientation )
        }
    
        // vide play function after check
        playVideoWithoutCheck = () => {
            let trailer = document.querySelector( '.video-trailer' )
            let video = document.querySelector( 'video' )
            trailer.classList.toggle( 'active' )
            this.setState( { play: true } )
            this.props.onVideoPlay( true )
            video.play()
            video.currentTime = 0 
        }

    // fetching total video time from actual video attached
    videoDurationOnLoad = () => {
        let vid = document.querySelector( '.video' )
        vid.onloadedmetadata = () => {
            let duration = vid.duration
            let minutes =  Math.floor( duration / 60 ) 
            let seconds = Math.floor( duration - minutes * 60 )
            let time = minutes + ':' + seconds
            this.setState( { totalDuration: time } )
        }    
    }
    
    // video is paused
    pause = () => {
        let trailer = document.querySelector( '.video-trailer' )
        let video = document.querySelector( 'video' )
        trailer.classList.toggle( 'active' )
        this.setState( { play: false } )
        this.props.onVideoPlay( false )
        video.pause()
        video.currentTime = 0
        Mixpanel.lvtVideoClosed( this.props.screenOrientation )
    }

    // checking showAndGo stores
    isShowAndGo = () => {
        return AppData.isStoreShowAndGo()
    }

    //set opacity of play button
    setOpacity = () => {
        this.setState( { opacity: 0.5 } )
        setTimeout( ()=>{
            this.setState( { opacity: 1 } )
        }, 1000 )
    }


    componentDidUpdate( prevProps, prevState ){
        if(  this.state.loginRequested ){
            this.setState( { loginRequested : false} )
            if( this.props.auth.validUserSession ){
                this.playVideoWithoutCheck()
            }
        } 
    }

    render() {
        return (
            <Fragment>
                { !this.state.play ? 
                <div className='video-inside-container'>
                    { this.isShowAndGo() ? <div className="showAndGoImage"></div> : 
                        <div className="scanAndGoImage"></div> }
                    <div className='time-display'>
                        <div className='total-time'>{this.state.totalDuration}</div>
                    </div>
                    <div className='text-container'>{this.isShowAndGo() ? strings['showAndGoText'] : strings['scanAndGoText']}</div>
                    <div className='btn-position' style={{opacity:`${this.state.opacity}`}} 
                        onTouchStart={this.setOpacity} onClick={this.play}>
                    </div> 
                </div>: null  }
                <div className='video-trailer'>
                    <video src={this.isShowAndGo() ? showAndGoVideo : scanAndGoVideo} controls={true} className='video' controlsList='nodownload' />
                    <div className='close-button' onClick={this.pause}>
                        <div className='close-internal'></div>
                    </div>
                    <div className='close-text'>{strings.closeText}</div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth : state.auth
    }
}
export default connect( mapStateToProps, { userLogin, loginNeeded } ) ( VideoContainer )

VideoContainer.propTypes = {
    onVideoPlay: PropTypes.func,
    loginNeeded: PropTypes.func,
    userLogin: PropTypes.func,
    auth: PropTypes.object,
    screenOrientation: PropTypes.string
}
