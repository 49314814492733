/**
Configuration (in db) must follow example (if default missing, first item of languages will be considered as the default language) =>
{
	"app": "7NOW",
	"type": "global",
	"configurations": [{
        "feature": "NOT USED",
        "properties": [{
            "id": "language",
            "default_value": {
                "default": "en",
                "languages": [
                    {"code": "en"},
                    {"code": "ja"},
                    {"code": "vi"},
                    {"code": "he", "rtl": true}
                ]
            }
        }]
    }]
}
 */

import _ from 'lodash'

import retrieveLanguage from './retrieveLanguage'
import stringsDefaultObj from './defaultLanguage.json'
import { REACT_APP_LOCALIZATION_DISABLED_ON_DEFAULT_LANGUAGE } from '../../config/environment'

export const frontDefaultLanguage = stringsDefaultObj.code
const stringsData = stringsDefaultObj.data
const emptyHandler = {
  get: function ( target, name ) {
    return target.hasOwnProperty( name ) ? target[name] : `{${name}}`
  },
}
export const strings = new Proxy( _.clone( stringsData ), emptyHandler )

export let languages = [frontDefaultLanguage]
export let language = frontDefaultLanguage
export let rtl = false
let defaultLanguage = frontDefaultLanguage

export async function setAvailableLanguages( newLanguages ) {
  const tmpDefaultLanguage = newLanguages.default
  const tmpLanguages = newLanguages.languages

  if (
    tmpDefaultLanguage &&
    tmpLanguages.some( ( lang ) => lang.code === tmpDefaultLanguage )
  )
    defaultLanguage = tmpDefaultLanguage
  else defaultLanguage = tmpLanguages[0].code

  languages = tmpLanguages
}

export async function loadLanguage( {
  queryLanguage,
  localStorageLanguage,
  forcedLanguage,
} ) {
  let newLanguage = forcedLanguage
  if ( !newLanguage ) {
    newLanguage = queryLanguage
    if ( !languages.some( ( lang ) => lang.code === queryLanguage ) ) {
      newLanguage = localStorageLanguage
      if ( !languages.some( ( lang ) => lang.code === localStorageLanguage ) )
        newLanguage = defaultLanguage
    }
  }

  let content
  if (
    REACT_APP_LOCALIZATION_DISABLED_ON_DEFAULT_LANGUAGE === '1' &&
    newLanguage === frontDefaultLanguage
  ) {
    content = _.clone( stringsData )
  } else {
    content = await retrieveLanguage( {
      component: 'delivery',
      app: 'lvt',
      platform: 'ui',
      language: newLanguage,
    } )
  }

  Object.assign( strings, content )
  language = newLanguage
  rtl = languages.find( ( l ) => l.code === newLanguage ).rtl || false

  return newLanguage
}