import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import AppAnalytics from '../common/AppAnalytics'

import '../style/Tabs.scss'
export default function TabsChild( props ) {
    const [opacity, setOpacity] = useState( 'none' )
    const {onTabsClick,title_display, title ,iconClass,description,allCartsAndOrders} = props

    const opaqueFunc = () => {
        setOpacity( 0.5 )
        setTimeout( () => {
            setOpacity( 1 ) 
        }, 500 )
    }
    
    let customerCartCreation = 0
    let customerPayed = 0
    let customerPayedAndConfirmed = 0
    let allCartsOrders = props.allCartsAndOrders ? props.allCartsAndOrders.length : 0
    let historyOrderCount = props.historyOrderCount ? props.historyOrderCount : 0
    
    // eslint-disable-next-line no-unused-vars
    let cartAndOrderItems = props.allCartsAndOrders.length < 0 ? null : 
    props.allCartsAndOrders.forEach( ( items ) => {
        customerCartCreation = items.itemType === 'cart' && !items.isOrderConfirmed ? customerCartCreation + items.items.length : customerCartCreation
        customerPayed = items.itemType === 'order' && !items.isOrderConfirmed ? customerPayed + items.items.length : customerPayed
        customerPayedAndConfirmed = items.itemType === 'order' && items.isOrderConfirmed ? customerPayedAndConfirmed + items.items.length : customerPayedAndConfirmed
    } )
    AppAnalytics.captureEvents( allCartsOrders, historyOrderCount,
        customerCartCreation, customerPayed, customerPayedAndConfirmed )
            return (
                <Fragment>
                    <div className="tabs-wrapper" onTouchStart={opaqueFunc} onClick={() => onTabsClick( title ) }>
                        <div className="icon-wrapper" style={{opacity:`${opacity}`}}>
                            <div className={iconClass}></div>
                        </div>
                        <div className="tab-detail" style={{opacity:`${opacity}`}}>
                            <div className={`tab-title ${iconClass}`}>{title_display}</div>
                            <div className={`tab-description ${iconClass}`}>{description}</div>
                        </div>
                        <div className="chevron-icon-wrapper" style={{opacity:`${opacity}`}}>
                        { title === 'liveViewText' && allCartsAndOrders.length > 0 ? <div className='count-wrapper'> <div className="count">{ allCartsAndOrders.length }</div> </div> : null }
                            { title === 'historyText' && historyOrderCount > 0 ? <div className='count-wrapper'><div className="count"> { historyOrderCount }</div> </div> : null }
                        </div>
                    </div>  
                </Fragment> 
            )
}

TabsChild.propTypes = {
    title_display : PropTypes.string,
    title: PropTypes.string,
    iconClass: PropTypes.string,
    description: PropTypes.string,
    onTabsClick: PropTypes.func,
    allCartsAndOrders: PropTypes.array,
    historyOrderCount: PropTypes.number,
    opacity: PropTypes.func,
    liveViewMenuToggle: PropTypes.bool,
    historyMenuToggle: PropTypes.bool,
    helpMenuToggle: PropTypes.bool,
    reportsMenuToggle: PropTypes.bool,
    whatsNewToggle: PropTypes.bool
}
