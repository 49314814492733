import uuid from 'uuid'
import AppData from '../common/AppData'

let logCallback = ( timestamp, level, system, environment, principal, scenario, operation, correlation, trace, message, extra ) => {}

class Logger {
    constructor( system, userName, userIdentifier, environment ){
        if ( !Logger.instance ){
            this.system = system
            this.userName = userName
            this.userIdentifier = userIdentifier
            this.environment = environment
            this.level = this.environment === 'production' ? this.logLevel.error : this.logLevel.verbose
            this.correlation = uuid.v4()
            Logger.instance = this
        }
    
        return Logger.instance
    }     

    logLevel = {
        fatal: {
            name: 'fatal',
            size: 0,
            color: 'red'
        },
        error: {
            name: 'error',
            size: 1
        },
        warning: {
            name: 'warning',
            size: 2
        },
        info: {
            name: 'info',
            size: 3
        },
        debug: {
            name: 'debug',
            size: 4
        },
        verbose: {
            name: 'verbose',
            size: 5
        }
    }

    system = 'NA';
    userName = 'NA';
    userIdentifier = 'NA';
    environment = 'NA';
    correlation = 'NA';
    enabled = true;
    level = this.logLevel.error;

    setLogLevel = ( logLevel ) => {
        this.level = logLevel
    }

    setLogCallback = ( callback ) => {
        logCallback = callback
    }

    log = ( level, principal, scenario, operation, trace, message, extra={} ) => {
        if ( !this.enabled ) {
            // Logger disabled
            return
        }
        let timestamp = Date.now()
        let system = this.system
        let userName = this.userName
        let userIdentifier = this.userIdentifier
        let environment = this.environment
        let correlation = this.correlation
        if ( level.size <= this.level.size ) {
            let decoratedMessage = `timestamp: ${timestamp}, operation: ${operation}, message: ${message}, environment: ${environment}`
            let messageDetails = `system: ${system}, userName: ${userName}, userIdentifier: ${userIdentifier}, principal: ${principal}, scenario: ${scenario}, correlation: ${correlation}, trace: ${trace}, extra: ${JSON.stringify( extra ) }`
            let consoleMessage = `%c${decoratedMessage}\n%c${messageDetails}`
            switch ( level.size ) {
                case 0:
                    console.exception( consoleMessage, 'font-weight: bold', 'font-weight: normal' )
                    break
                case 1:
                    console.error( consoleMessage, 'font-weight: bold', 'font-weight: normal' )
                    break
                case 2:
                    console.warn( consoleMessage, 'font-weight: bold', 'font-weight: normal' )
                    break
                case 3:
                    console.info( consoleMessage, 'font-weight: bold', 'font-weight: normal' )
                    break
                case 4:
                    console.debug( consoleMessage, 'font-weight: bold', 'font-weight: normal' )
                    break
                default:
                    console.log( consoleMessage, 'font-weight: bold', 'font-weight: normal' )
            }
        }
        if ( logCallback instanceof Function ) {
            logCallback( timestamp, level, system, environment, principal, scenario, operation, correlation, trace, message, extra )
        }
    }

    fatal = ( principal, scenario, operation, trace, message, extra={} ) => {
        this.log( this.logLevel.fatal, principal, scenario, operation, trace, message, extra )
    }
    debug = ( principal, scenario, operation, trace, message, extra={} ) => {
        this.log( this.logLevel.debug, principal, scenario, operation, trace, message, extra )
    }
    error = ( principal, scenario, operation, trace, message, extra={} ) => {
        this.log( this.logLevel.error, principal, scenario, operation, trace, message, extra )
    }
    info = ( principal, scenario, operation, trace, message, extra={} ) => {
        this.log( this.logLevel.info, principal, scenario, operation, trace, message, extra )
    }
    verbose = ( principal, scenario, operation, trace, message, extra={} ) => {
        this.log( this.logLevel.verbose, principal, scenario, operation, trace, message, extra )
    }
    warning = ( principal, scenario, operation, trace, message, extra={} ) => {
        this.log( this.logLevel.warning, principal, scenario, operation, trace, message, extra )
    }
}
const logger = new Logger( 'LVT', AppData.storeId, AppData.systemIdentifier, process.env.REACT_APP_ENVIRONMENT )
Object.freeze( logger )
window.lvtLogger = logger
export default logger