import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

import Cart from './Cart'
import ModalDetails from './ModalDetails'
import Tips from './Tips'
import Loader from './Loader'
import { strings } from '../context/LanguageContext/strings'

import Mixpanel from '../common/Mixpanel'

import '../style/CartContainer.scss'
import { connect } from 'react-redux'
import history from '../history'
import { authClient } from '../common/helper'
import { isLoggedIn } from '../actions/authActions'
import store from '../store/store'
import HomeScreen from './HomeScreen'
import config from '../common/config'

class CartContainer extends Component {

	constructor(){
        super()
		this.state = {
            show: false
        }
        this.topScroll = React.createRef()
	}
    
	//lifecycle method runs after the first render
	componentDidMount(){
        let localStorageRefreshToken = localStorage.getItem( "refresh_token" )
        let localStorageIdToken = localStorage.getItem( 'id_token' )
        let storeId = config.storeId()
        if(!storeId){
            authClient.logout( localStorageIdToken )
            localStorage.clear()
            return
        }
        if( !this.props.auth.isLoggedIn && !localStorageRefreshToken ){
            history.push( '/' )
        }
        else if( !this.props.auth.isLoggedIn ){
            this.getRefreshToken()
        }
        else{
            this.props.onLiveView()
            this.props.dataRefresh()
            this.props.initializePolling()
        } 
    }

    getRefreshToken = async () =>{
        let localStorageRefreshToken = localStorage.getItem( "refresh_token" )
        let refreshToken =  await authClient.getRefreshToken( localStorageRefreshToken )
        refreshToken()
            .then( res => {
                localStorage.setItem( 'refresh_token',res.data.refresh_token )
                this.props.isLoggedIn( true )
            } )
            .catch( err => {
                console.log( 'promise rejected',err )  
                history.push( '/' )
            } )
    }
    // Unmounting pubSub listener
    componentWillUnmount(){
        if( this.props.auth.isLoggedIn ){
            Mixpanel.exitLiveView( this.props.screenOrientation )
            this.props.disablePolling()
        }
    }

    //Rendering active carts
    renderCarts = () => {
        const { cardStyle, allTips } = this.props

        return this.props.allCartsAndOrders.map( ( cart, i )=>{
            if( i+1 === this.props.allCartsAndOrders.length ){
                return (
                    <Fragment key={i}>
                        <Cart cardStyle={cardStyle} cart={cart} key={cart._id} handleModalOpen={()=>this.props.handleModalOpen( cart )} /> 
                        <Tips allTips={allTips} />
                    </Fragment>
                )
            }else{
                return (
                    <Cart cardStyle={cardStyle} cart={cart} key={cart._id} handleModalOpen={()=>this.props.handleModalOpen( cart )}/> 
                )
            }
        } )
    }

    componentDidUpdate( prevProps  ){
        if( prevProps.gridStyle !== this.props.gridStyle ){
            this.topScroll.current.scrollTop = this.props.scroll
        }
    }

    render() {
        const { gridStyle , allTips , isOpen   } = this.props
        return (
            <div className={this.props.transformBanner ? 'content-push-container' : 'content-container'} ref={this.topScroll}>
                { store.getState().languageChange.isLoading && <HomeScreen />  }
                {gridStyle === 'grid-container-3'?   
                    <div className="customer-header">
                        <span className="name-tag">{strings.customerText}</span>
                        <span className="note-tag">{strings.noteText}</span>
                        <span className="item-tag">{strings.itemText}</span>
                        <span className="price-tag">{strings.priceTotalText}</span>
                    </div>:null}
                {this.props.isDataAvailable  && !this.props.isLoadingPaidOrders? 
                 this.props.allCartsAndOrders.length > 0 ? 
                    <div className={gridStyle} style={{marginTop:40}}>
                        {this.renderCarts()}
                    </div> : 
                    <Tips allTips={allTips} allCartsAndOrders={this.props.allCartsAndOrders}/>
                :  <div className="loader-postion"><Loader/></div>} 
            <Route path="/app/liveView/details/:id" component={()=> <div className="modal-container">{ isOpen ? <ModalDetails cart={this.props.details} cartCleared={this.props.detailedCartCleared} isOpen={isOpen} allCartsAndOrders={this.props.allCartsAndOrders} isOrderPaidOrConfirmed = {this.props.isOrderPaidOrConfirmed} />: history.push( '/' ) }</div>} />
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        auth : state.auth
    }
}
export default connect( mapStateToProps,{ isLoggedIn } ) ( CartContainer )

CartContainer.propTypes = {
	gridStyle : PropTypes.string,
	cardStyle : PropTypes.string,
    scroll : PropTypes.number,
    allTips : PropTypes.array,
    onLiveView: PropTypes.func,
    screenOrientation: PropTypes.string,
    title: PropTypes.string,
    allCartsAndOrders: PropTypes.array,
    isDataAvailable: PropTypes.bool,
    isLoadingPaidOrders: PropTypes.bool,
    isOrderPaidOrConfirmed: PropTypes.bool,
    handleModalOpen: PropTypes.func,
    details: PropTypes.object,
    dataRefresh: PropTypes.func,
    transformBanner: PropTypes.bool,
    initializePolling: PropTypes.func,
    disablePolling: PropTypes.func,
    auth: PropTypes.object,
    isLoggedIn: PropTypes.func,
    isOpen: PropTypes.bool,
    detailedCartCleared: PropTypes.bool
}
