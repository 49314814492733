import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { LanguageContext } from '../context/LanguageContext'


const TrendContent = ( {title , count, rateOfChange, bottomLabel, iconNeeded, reportDate, singleDayOrder, aspdCount } ) => {
    const { strings } = useContext( LanguageContext )

    const renderTodayOrderCount = () => {
        return (
            <div className="content">
                <div className="count">{count}</div>
                { ( iconNeeded ) ?
                    <div className="icon-wrapper">
                        <div className="icon-percent-container">
                            <div className={rateOfChange > 0? 'icon-up' : 'icon-down'}></div>
                            <div className="count-percent" style={{color: ( rateOfChange <= 0 ) ? '#ec2526': '#107f62'}} >
                                {( rateOfChange < 0 ) ? -1 * rateOfChange : rateOfChange }%
                            </div>
                        </div>
                        <div className="bottom-label" style={{paddingLeft:'0px', marginTop: '-5px', zIndex: '1'}}>{bottomLabel}</div>
                    </div>
                    : null
                }
            </div>
        )
    }

    const renderPastOrderCount = () => {
        return (
            <div className="past-days-content-wrapper">
                <div className="past-days-header-wrapper">
                    <div className="past-days-header">APSD</div>
                    <div className="past-days-header" style={{marginRight: '8px' }}>{strings.priceTotalText}</div>
                </div>
                <div className="past-days-header-wrapper">
                    <div className="count">{aspdCount}</div>
                    <div className="count" style={{marginRight: '8px' }}>{count}</div>
                </div>
            </div>
        )
    }
    return (
        <div className="trends-content-wrapper">
            <div className="header">{title}</div>
            <div className="report-transaction-datetime">{reportDate}</div>
            {  singleDayOrder ? renderTodayOrderCount() : renderPastOrderCount() }
        </div>
    )
}

export default TrendContent

TrendContent.propTypes = {
    title: PropTypes.string,
    count:PropTypes.number,
    rateOfChange: PropTypes.number,
    bottomLabel : PropTypes.string,
    iconNeeded: PropTypes.bool,
    reportDate: PropTypes.string,
    singleDayOrder: PropTypes.bool,
    aspdCount: PropTypes.number
}
